import { useSelector } from 'react-redux';
import { useEffect } from 'preact/hooks';
import { selectPopupConfig } from '../../../../../redux/reducers/popup/popup';

export const usePopupTrigger = ({ setQueue, addToQueue }) => {
    const displayExitPopupRecentlyPlayed = useSelector(state => state?.['exitPopupRecentlyPlayed']?.displayModal);
    const displayExitPopup = useSelector(state => state?.['exitPopup']?.displayModal);
    const isRealityCheck = useSelector(state => state?.['realityCheck']?.displayModal);
    const errorPopup = useSelector(selectPopupConfig);

    useEffect(() => {
        if (errorPopup) {
            if (isRealityCheck) {
                addToQueue({ type: 'reality_check', data: {} });
            } else {
                addToQueue({ type: 'provider_error', data: {} });
            }
        } else {
            setQueue(prev => {
                const popupIndex = prev.findIndex(item => item.type === 'provider_error' || item.type === 'reality_check');

                if (popupIndex !== -1) {
                    const current = [...prev];
                    current.splice(popupIndex, 1);

                    return current;
                }

                return prev;
            });
        }
    }, [errorPopup, isRealityCheck]);

    useEffect(() => {
        if (displayExitPopup) {
            addToQueue({ type: 'exit_popup', data: {} });
        } else if (displayExitPopupRecentlyPlayed) {
            addToQueue({ type: 'exit_popup_recently_played', data: {} });
        } else {
            setQueue(prev => {
                return prev.filter(message => message.type !== 'exit_popup');
            });
        }
    }, [displayExitPopup, displayExitPopupRecentlyPlayed]);
}
