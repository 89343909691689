import { types } from '../../types';

const initialState = {
    clientReady: false,
    handshake: null
};

const gameGameClientStatusReducer = (state = initialState, action) => {
    if (action.type === types.GAME_CLIENT_STATUS) {
        return {
            ...state,
            clientReady: action.payload
        };
    }

    if (action.type === types.GAME_CLIENT_HANDSHAKE) {
        return {
            ...state,
            handshake: action.payload
        };
    }

    return state;
};

export default gameGameClientStatusReducer;
