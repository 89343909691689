import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { isRunningInsideWrapper } from '../wrapper-bridge-mobile';

export const useOrientation = () => {
    const [isPortrait, setIsPortrait] = useState(screen?.orientation?.type?.includes('portrait'));
    const isAndroidWrapper = isRunningInsideWrapper();

    const handleChange = useCallback(
        e => {
            setIsPortrait(
                isAndroidWrapper ? window.outerHeight > window.outerWidth : e.target.type.includes('portrait')
            );
        },
        [isAndroidWrapper]
    );

    useEffect(() => {
        isAndroidWrapper
            ? window.addEventListener('resize', handleChange)
            : screen?.orientation?.addEventListener('change', handleChange);

        return () => {
            isAndroidWrapper
                ? window.removeEventListener('resize', handleChange)
                : screen?.orientation?.removeEventListener('change', handleChange);
        };
    }, [handleChange, isAndroidWrapper]);

    const deviceType = useMemo(() => (isPortrait ? 'portrait' : isAndroidWrapper ? 'landscape' : 'desktop'), [
        isAndroidWrapper,
        isPortrait
    ]);

    return { isPortrait, deviceType };
};
