import { types } from '../../types';

export const initialState = {};

const featureFlags = (state = initialState, action) => {
    const { type, payload } = action;
    if (type === types.SET_FEATURE_FLAGS) {
        return {
            ...state,
            ...payload
        }
    }
    return state;
};

export default featureFlags;
