import axios, { AxiosInstance } from "axios";
import Cookie from "js-cookie";
import jwtDecode from "jwt-decode";

import { ErrorType, Nullable } from "../types";
import { OriginType } from "./types";

export class OptInManager {
  private basePath = "/jackpot-gateway/api/v1";
  private cookieName = "X-Auth-Token";

  intermediateValue: boolean | { [key: string]: boolean } | null = null;

  currentJackpotId: Nullable<string> = null;
  currentOrigin: Nullable<OriginType> = null;

  httpClient: Nullable<AxiosInstance> = null;

  successCallback: ((val: boolean | { [key: string]: boolean }) => void) | undefined = () => 0;
  errorCallback: ((error: ErrorType) => void) | undefined = () => 0;

  constructor (
    endpoint: string,
    origin: OriginType,
    options?: {
     defaultJackpotId?: string;
     callback?: (arg: boolean | { [key: string]: boolean }) => void;
     errorCallback?: (error: ErrorType) => void;
   }) {
    try {
      this.currentJackpotId = options?.defaultJackpotId || null;
      this.currentOrigin = origin || null;
      this.httpClient = axios.create({
        baseURL: endpoint,
      });
      this.successCallback = options?.callback;
      this.errorCallback = options?.errorCallback;
    } catch (e) {
      console.log({ message: `Error when initializing: [error: ${e}]`, reason: "SWJ-001" });
      this?.errorCallback && this.errorCallback({ message: `Error when initializing: [error: ${e}]`, reason: "SWJ-001" });
    }
  }

  getParams() {
    const authToken = Cookie.get(this.cookieName);

    const decodedToken = authToken && (jwtDecode(authToken) as { sub: number });

    if (decodedToken) {
      const userId = decodedToken?.sub;
      return { authToken, userId };
    }

    return null;
  };

  saveAndCallback(
    value: { [key: string]: boolean } | boolean,
  ): { [key: string]: boolean } | boolean {
    console.log({ saveAndCallback: value })
    this.intermediateValue = value;
    this.successCallback && this.successCallback(value);

    return value;
  };

  updateValue(
    optedInList: string[],
    jackpotId: string | null = null,
  ) {
    if (this.currentJackpotId || jackpotId) {
      const optedIn = optedInList.includes(
        this.currentJackpotId || (jackpotId as string),
      );
      return this.saveAndCallback(optedIn);
    }

    const listAsObject = optedInList.reduce(
      (acc, val) => ({ ...acc, [val]: true }),
      {},
    );

    return this.saveAndCallback(listAsObject);
  };

  async checkOptedIn(jackpotId?: string, retry = false): Promise<{ [key: string]: boolean } | boolean> {
    try {
      const result = await (this.httpClient as AxiosInstance).post(`${this.basePath}/opted-in`, {
        ...this.getParams(),
        origin: this.currentOrigin,
      });

      if (result.status >= 200 && result.status < 300) {
        return this.updateValue(
          result.data?.jackpotIds,
          jackpotId || this.currentJackpotId,
        );
      } else {
        if (!retry) {
          return this.checkOptedIn(jackpotId, true);
        }

        this?.errorCallback && this.errorCallback({ message: `Error when checking if opted in: [status: ${result.status} data:${result.data}]`, reason: "SWJ-001" })
        return this.saveAndCallback(this.intermediateValue as { [key: string]: boolean } | boolean);
      }
    } catch (error) {
      if (!retry) {
        return this.checkOptedIn(jackpotId, true);
      }
      this?.errorCallback && this.errorCallback({ message: `Error when checking if opted in: [error: ${error}]`, reason: "SWJ-001" })
      return this.saveAndCallback(this.intermediateValue as { [key: string]: boolean } | boolean);
    }
  }

  async optIn(jackpotId = this.currentJackpotId, retry = false): Promise<boolean> {
    console.log({ this: this, jackpotId, retry })
    try {
      const result = await (this.httpClient as AxiosInstance).post(`${this.basePath}/opt-in`, {
        ...this.getParams(),
        jackpotId,
        origin: this.currentOrigin,
      });

      if (result.status >= 200 && result.status < 300) {
        return this.updateValue(result.data?.jackpotIds, jackpotId) as boolean;
      } else {
        if (!retry) {
          return this.optIn(jackpotId, true);
        }

        this?.errorCallback && this.errorCallback({ message: `Error when opting in: [status: ${result.status} data:${result.data}]`, reason: "SWJ-002" })
        return this.saveAndCallback(this.intermediateValue as { [key: string]: boolean } | boolean) as boolean;
      }
    } catch (error) {
      if (!retry) {
        return this.optIn(jackpotId, true);
      }
      this?.errorCallback && this.errorCallback({ message: `Error when opting in: [error: ${error}]`, reason: "SWJ-002" })
      return this.saveAndCallback(this.intermediateValue as { [key: string]: boolean } | boolean) as boolean;
    }
  };

  async optOut(jackpotId = this.currentJackpotId, retry = false): Promise<boolean> {
    try {
      const result = await (this.httpClient as AxiosInstance).post(`${this.basePath}/opt-out`, {
        ...this.getParams(),
        jackpotId,
        origin: this.currentOrigin,
      });

      if (result.status >= 200 && result.status < 300) {
        return this.updateValue(result.data?.jackpotIds, jackpotId) as boolean;
      } else {
        if (!retry) {
          return this.optOut(jackpotId, true);
        }

        this?.errorCallback && this.errorCallback({ message: `Error when opting out: [status: ${result.status} data:${result.data}]`, reason: "SWJ-003" });
        return this.saveAndCallback(this.intermediateValue as { [key: string]: boolean } | boolean) as boolean;
      }
    } catch (error) {
      if (!retry) {
        return this.optOut(jackpotId, true);
      }
      this?.errorCallback && this.errorCallback({ message: `Error when opting out: [error: ${error}]`, reason: "SWJ-003" });
      return this.saveAndCallback(this.intermediateValue as { [key: string]: boolean } | boolean) as boolean;
    }
  };
}
