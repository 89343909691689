import { useGameClientMessageHandler } from './hooks/useGameClientMessageHandler';
import EmptyGame from '../EmptyGame/EmptyGame';
import { useGameClientInitializer } from './hooks/useGameClientInitializer';

const GameClient = ({ provider }: { provider?: string }) => {
    const { shouldRenderClientIframe, gameUrl, gameName, nativeId } = useGameClientInitializer(provider);

    useGameClientMessageHandler(provider);

    if (nativeId === '-1') {
        return <EmptyGame />;
    }

    return shouldRenderClientIframe ? (
        <iframe style={{ width: '100%', height: '100%' }} src={gameUrl.href} title={gameName} id="casino-game-client" />
    ) : null;
};

export default GameClient;
