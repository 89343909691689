import { locationValidated } from '../../../geo-plc/geo-plc';
import { verifyLocationFailed } from './verify-location-failed';
import { verifyLocationClearError } from './verify-location-clear-error';
import { ddRumAction } from '../../../../common/helpers/datadog-wrapper';
import { ddActionVerifyLocationSucceeded } from '../../../../common/datadog/verify-location/vl-succeeded';
import { ddActionVerifyLocationFailed } from '../../../../common/datadog/verify-location/vl-failed';
import { DD_USE_CASES } from '../../../../common/datadog/enums';

export const GEOCOMPLY_LOCATION_VERIFIED = 'GEOCOMPLY_LOCATION_VERIFIED';

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        details: action.payload,
        verified: action.payload.result,
        loading: false
    };
};

// thunk function
export const locationVerified = ({tokenDetails, geocomplyEnabled}) => async dispatch => {
    try {
        console.warn('LOCATION VERIFIED');

        dispatch({
            type: GEOCOMPLY_LOCATION_VERIFIED,
            payload: tokenDetails
        });

        dispatch(verifyLocationClearError());
        dispatch(locationValidated());
        ddRumAction(ddActionVerifyLocationSucceeded({ tokenDetails, geocomplyEnabled }, DD_USE_CASES.GLOBAL));
    } catch (err) {
        dispatch(verifyLocationFailed({ message: JSON.stringify(err) }));
        ddRumAction(ddActionVerifyLocationFailed({ tokenDetails, geocomplyEnabled, err }, DD_USE_CASES.GLOBAL));
    }
};
