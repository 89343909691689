export const GEOCOMPLY_PLC_LOCATION_VALIDATED = 'GEOCOMPLY_PLC_LOCATION_VALIDATED'

// Action Creators
export const locationValidated = () => dispatch => {
    console.warn('LOCATION VALIDATED')
    dispatch({
        type: GEOCOMPLY_PLC_LOCATION_VALIDATED
    })
}

// Reducer
export const reducer = state => {
    return {
        ...state,
        validated: true
    }
}
