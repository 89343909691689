import {getRegion, removeAWCookies} from './cookies';
import {substituteJsonPlaceholders} from './jsonPlaceholders';
import config from '../../../config/config';

const loginRedirect = (redirectUrl) => {
    // remove AW cookies first
    removeAWCookies()

    // Login token expired. Redirect to login page.
    const variables = {
        region: getRegion()?.toUpperCase(),
        externalReferrerNext: encodeURIComponent(redirectUrl)
    }

    window.location = substituteJsonPlaceholders(config['loginURL'], variables)
}

export {loginRedirect}
