const SoundOff = props => {
    return (
        <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.2917 1.04169L6.25002 5.87502H1.41669V13.125H6.25002L12.2917 17.9584V1.04169Z"
                stroke="#1493FF"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M26.7917 5.875L19.5417 13.125"
                stroke="#1493FF"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M19.5417 5.875L26.7917 13.125"
                stroke="#1493FF"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default SoundOff;
