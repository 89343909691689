import { useCallback } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { MESSAGES } from '../../../common/constants';

import { useOrientation } from '../../../common/hooks/useOrientation';
import { isRunningInsideWrapper, sendWrapperEvent } from '../../../common/wrapper-bridge-mobile';
import { ERROR_CTA_EVENTS } from '../../../game-window/contants/errors';

import { inGameErrorSelector } from '../../../selectors/game-window';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';
import { AMPLITUDE_ERROR_EVENTS, MODULES, getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';

export const GenericCancelButton = ({ buttonMessage, buttonStyle, disableJackpot = false }) => {
    const { deviceType } = useOrientation();
    const { errorType } = useSelector(inGameErrorSelector);

    const { logTrackingComponent } = useLogTracking();

    const handleCancelButton = useCallback(() => {
        const payload = {
            [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_CLICKED,
            [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
            [getAmplitudeKey('ERROR_TYPE')]: errorType,
            [getAmplitudeKey('LINK_TEXT')]: ERROR_CTA_EVENTS.TRY_AGAIN,
            [getAmplitudeKey('LINK_URL')]: undefined
        };
        logTrackingComponent(payload);
        if (disableJackpot) {
            const url = new URL(window.location.href);

            url.searchParams.set('jackpotMode', 'false');

            if (isRunningInsideWrapper()) {
                sendWrapperEvent(MESSAGES.RELOAD_GAME, { jackpotMode: false });
            }

            window.location.search = url.search;
        } else {
            isRunningInsideWrapper() ? sendWrapperEvent(MESSAGES.RELOAD_GAME, '') : window.location.reload();
        }
    }, [errorType, logTrackingComponent, disableJackpot]);

    return (
        <button onClick={handleCancelButton} className={`close-button ${deviceType} ${buttonStyle}`}>
            {buttonMessage}
        </button>
    );
};
