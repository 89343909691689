import { useCallback } from 'preact/hooks';
import { isRunningInsideWrapper, sendWrapperEvent } from '../../../common/wrapper-bridge-mobile';
import { MESSAGES } from '../../../common/constants';
import config from '../../../../config/config';

export const useExit = () => {
    return useCallback(() => {
        if (isRunningInsideWrapper()) {
            sendWrapperEvent(MESSAGES.GAME_LAUNCHER_CLOSE, '');
        } else {
            window.close();
            window.location.href = config.casinoWebUrl;
            //redirect done after closing the window because if the short linked game or bookmarked game is opened the button won't close the window
        }
    }, []);
};