// eslint-disable-next-line no-unused-vars
import { h, Fragment } from 'preact';
import { useTranslation } from 'react-i18next';
import { isMobile, isIOS } from 'react-device-detect';
import { Link } from 'react-router-dom';

import Map from '../../../../assets/icons/Map/map';
import { pathName } from '../../../App';
import { dismissLauncher } from '../../../../common/helpers/redirect-flows';
import config from '../../../../../config/config';

const TRUE_LOCATION_APP_ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.geocomply.plv&hl=en&gl=US';

const GeoValidatorNotInstalled = ({ iosWakeupUrl }) => {
    const { t } = useTranslation();

    const translation = isMobile ? 'GeoguardNotInstalled' : 'PLCNotInstalled';

    const url = () => {
        if (!isMobile) {
            return pathName;
        }
        return isIOS ? iosWakeupUrl : TRUE_LOCATION_APP_ANDROID_URL;
    };

    return (
        <>
            <div className="modal__header">{t(`${translation}.verifyLocation`)} </div>

            <div className="modal__image">
                <Map />
            </div>

            <div className="modal__body">
                <p>{t(`${translation}.allowCasino`)}</p>
                <p>
                    {t(`${translation}.safelyDownload`)}
                    <a
                        className="more-details"
                        href={config?.geoComply?.help}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t(`${translation}.here`)}
                    </a>
                </p>
            </div>
            <div className="modal__footer">
                <div className="modal__buttons">
                    {isMobile ? (
                        <a href={url} className="btn btn__link btn-main" role="button">
                            {t(`${translation}.downloadPlugin`)}
                        </a>
                    ) : (
                        <>
                            <a nohref onClick={() => dismissLauncher()} className="btn btn--transparent" role="button">
                                {t(`${translation}.dismiss`)}
                            </a>
                            <Link to={url} className="btn btn__link btn-main" role="button">
                                {t(`${translation}.downloadPlugin`)}
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default GeoValidatorNotInstalled;
