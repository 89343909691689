import { h } from 'preact'

const Pin = () => (
    <svg className="map-pin-icon"
         width="14"
         height="18"
         viewBox="5 3 14 18">
        <defs>
            <filter
                id="filter-1"
                filterUnits="objectBoundingBox"
            >
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <path
                id="path-4"
                d="M8.5 0c3.314 0 6 2.669 6 5.96a5.905 5.905 0 01-1.009 3.31l-4.597 7.51a.463.463 0 01-.789 0L3.44 9.17a5.926 5.926 0 01-.94-3.21C2.5 2.67 5.186 0 8.5 0zm0 .917c-2.804 0-5.077 2.258-5.077 5.044 0 .887.23 1.74.668 2.506L8.5 15.659l4.225-6.9a4.988 4.988 0 00.847-2.546l.006-.252c0-2.786-2.273-5.044-5.077-5.044zm0 1.834c1.784 0 3.23 1.437 3.23 3.21 0 1.772-1.446 3.21-3.23 3.21S5.27 7.732 5.27 5.96c0-1.773 1.446-3.21 3.23-3.21zm0 .917a2.3 2.3 0 00-2.308 2.293A2.3 2.3 0 008.5 8.253a2.3 2.3 0 002.308-2.292A2.3 2.3 0 008.5 3.668z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-441 -695)">
                <g transform="translate(112 212)">
                    <g filter="url(#filter-1)" transform="translate(333 486)">
                        <g mask="url(#mask-3)">
                            <g>
                                <g
                                    fillRule="evenodd"
                                    strokeWidth="1"
                                >
                                    <g>
                                        <path d="M0 0H17V17H0z" />
                                        <mask id="mask-5" fill="#fff">
                                            <use xlinkHref="#path-4" />
                                        </mask>
                                        <g fill="#FFF" mask="url(#mask-5)">
                                            <g>
                                                <path d="M0 0H17V17H0z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Pin
