export const GEOCOMPLY_PLC_CONNECTED = 'GEOCOMPLY_PLC_CONNECTED'

// Action Creators
export const plcConnected = () => dispatch => {
    console.warn('PLC: CONNECTED')
    dispatch({
        type: GEOCOMPLY_PLC_CONNECTED
    })
}

// Reducer
export const reducer = state => {
    return {
        ...state,
        installed: true,
        connected: true
    }
}
