import axios from 'axios'
import config from '../../../config/config'
import { HEADERS } from '../constants'

export const FabricServicesAxios = () => {
    let axiosConfig = {}

    axiosConfig = {
        ...axiosConfig,
        headers: {
            [HEADERS.CACHE_CONTROL]: 'no-store, no-cache'
        }
    }

    return axios.create(axiosConfig)

    // return instance
}

export const getFabricEndpoint = (service, flow) => {
    return config?.fabric?.endpoints[service][flow]
}

export const getFabricURL = () => {
    return config?.casinoWebUrl
}
