export const GEOCOMPLY_VERIFY_NEW_LOCATION = 'GEOCOMPLY_VERIFY_NEW_LOCATION'

// Reducer
export const reducer = state => {
    return {
        ...state,
        verified: false
    }
}
// Action Creators
export const verifyNewLocation = error => async dispatch => {
    console.warn('VERIFY NEW LOCATION', error)

    dispatch({
        type: GEOCOMPLY_VERIFY_NEW_LOCATION,
        payload: error
    })
}
