import { useSelector } from 'react-redux';
import { useEffect } from 'preact/hooks';
import { isIosWrapper } from '../../wrapper-bridge-mobile';

const useRemoveInitialLoader = () => {
    let userId = useSelector(state => state?.session?.userId);
    const gameWindowUILoading = useSelector(state => state?.['gameWindow']?.['gameWindowUILoading']);
    const gameWindowUI = useSelector(state => state?.['gameWindow']?.['gameWindowUI']);
    const gameWindowUIError = useSelector(state => state?.['gameWindow']?.['gameWindowUIError']);

    useEffect(() => {
        if (!userId) {
            return;
        }

        const initialLoader = document.getElementById('initial-loader-container');
        if (
            (initialLoader && gameWindowUI && !gameWindowUILoading) ||
            (initialLoader && gameWindowUIError) ||
            (initialLoader && isIosWrapper())
        ) {
            initialLoader.remove();
        }
    }, [gameWindowUI, gameWindowUIError, gameWindowUILoading, userId]);
};

export default useRemoveInitialLoader;
