import { types } from '../../types';
import { datadogRum } from '@datadog/browser-rum';
import { FabricServicesAxios, getFabricURL, getFabricEndpoint } from '../../../common/axios/FabricServicesAxios';
import { RECENTLY_GAMES_OPERATION, AGGREGATION_HEADERS, MESSAGES } from '../../../common/constants';
import { FetchType, getRequestUid } from '../../../common/helpers/fetch-helper';
import { fetchEnd, fetchError, fetchStart } from '../../fetch/fetch';
import { getRegion } from '../../../common/helpers/cookies';
import connectionType from '../../../common/helpers/connectionType';
import { isRunningInsideWrapper, sendWrapperEvent } from '../../../common/wrapper-bridge-mobile';
import config from '../../../../config/config';
import { getBrand } from '../../../common/helpers/brand';

const refreshLobbyRecentlyPlayedZone = () => {
    if (isRunningInsideWrapper()) {
        sendWrapperEvent(MESSAGES.REFRESH_RECENTLY_PLAYED, '');
    } else {
        window?.opener?.postMessage?.({ type: MESSAGES.REFRESH_RECENTLY_PLAYED }, config.casinoWebUrl);
    }
};

export const addRecentlyPlayed = (gameId, deviceType, nextGameUrl) => async dispatch => {
    const requestUid = getRequestUid(FetchType.AddRecentlyPlayed);
    const recentlyGamesEndpoint = getFabricEndpoint('userActivity', 'recentlyPlayedGames');
    const operation = RECENTLY_GAMES_OPERATION.ADD;
    const fabricURL = getFabricURL();
    const baseURL = process.env.NODE_CONFIG_ENV === 'local-development' ? `${fabricURL}:4000` : fabricURL;
    const product = process.env.PRODUCT;

    const region = getRegion();
    const brand = getBrand();

    const platform = deviceType === 'desktop' ? 'desktop' : 'mobile';

    const apiUrl = `${recentlyGamesEndpoint}/${operation}?gameCode=${gameId}&product=${product}&gameIdentifierType=id&platform=${platform}`;

    try {
        dispatch(fetchStart(requestUid));
        dispatch({ type: types.ADD_USER_ACTIVITY_REQUEST });

        const response = await FabricServicesAxios().get(apiUrl, {
            baseURL,
            redirect: true,
            headers: {
                [AGGREGATION_HEADERS.X_BRAND]: brand,
                [AGGREGATION_HEADERS.X_REGION_CODE]: region
            },
            withCredentials: true
        });

        const data = response?.data;

        dispatch({
            type: types.ADD_USER_ACTIVITY_RECEIVED,
            payload: data
        });
        dispatch(fetchEnd(requestUid));

        datadogRum.addAction('ADD_RECENTLY_PLAYED_SUCCESS');

        refreshLobbyRecentlyPlayedZone();

        if (nextGameUrl === undefined) {
            datadogRum.addError(
                new Error(error),
                Object.assign(
                    {
                        type: 'FAILED_OPEN_RECENTLY_PLAYED',
                        connectionType: connectionType()
                    },
                    error
                ),
            );
        } else {
            window.open(nextGameUrl, '_self');
        }
    } catch (error) {
        dispatch(fetchError(requestUid));
        dispatch({
            type: types.ADD_USER_ACTIVITY_FAILED,
            payload: error?.name
        });

        datadogRum.addError(
            new Error(error),
            Object.assign(
                {
                    type: 'ADD_RECENTLY_PLAYED_FAILED',
                    connectionType: connectionType()
                },
                error
            ),
        );
    }
};

export default addRecentlyPlayed;
