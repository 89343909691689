// default init file from i18next - documentation
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import languageEN from '../common/locale/en/translate.json'

i18n.use(initReactI18next).init({
    resources: {
        en: languageEN
    },
    fallbackLng: 'en',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',

    interpolation: {
        escapeValue: false
    }
})

export default i18n
