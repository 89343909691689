import { useDispatch } from 'react-redux'
import { useCallback, useLayoutEffect, useState } from 'preact/hooks'

import { registerWrapperGeolocationCallback, registerWrapperGeolocationRenew } from '../../wrapper-bridge-mobile'

const useGeocomplyPlcAndroid = () => {
    const dispatch = useDispatch()
    const [connected, setConnected] = useState(false)

    const geolocationRetrievedHandler = useCallback(
        e => {
            dispatch(registerWrapperGeolocationCallback(e))
        },
        [dispatch]
    )

    const geolocationRenewHandler = useCallback(() => {
        dispatch(registerWrapperGeolocationRenew())
    }, [dispatch])

    useLayoutEffect(() => {
        window.addEventListener('onGeolocationRetrieved', geolocationRetrievedHandler)
        window.addEventListener('onGeolocationRenew', geolocationRenewHandler)

        setConnected(true)

        return () => {
            removeEventListener('onGeolocationRetrieved', geolocationRetrievedHandler)
            removeEventListener('onGeolocationRenew', geolocationRenewHandler)
        }
    }, [geolocationRetrievedHandler, geolocationRenewHandler])

    return { connected }
}

export default useGeocomplyPlcAndroid
