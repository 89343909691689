import { useSelector } from 'react-redux';

import SoundOn from '../../../../assets/icons/GameWindow/SoundOn';
import SoundOff from '../../../../assets/icons/GameWindow/SoundOff';
import { useCallback } from 'preact/hooks';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SoundToggle = ({ toggleAudio, ...other }) => {
    const isAudioEnabled = useSelector(state => state?.gameWindow?.audioEnabled);
    const { logTrackingComponent } = useLogTracking();

    const IconComponent = isAudioEnabled ? SoundOn : SoundOff;

    const handleIconComponent = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Sound Adjusted',
            [getAmplitudeKey('MODULE')]: MODULES.IN_GAME_HEADER,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD
        });
        toggleAudio(!isAudioEnabled);
    }, [logTrackingComponent, isAudioEnabled, toggleAudio]);
    return (
        <IconComponent
            onClick={() => {
                toggleAudio(!isAudioEnabled);
                handleIconComponent();
            }}
            className={isAudioEnabled ? 'audio-on' : 'audio-off'}
            {...other}
        />
    );
};

export default SoundToggle;
