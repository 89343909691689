const MAX_RETRIES = 3

const getCurrentState = config => {
    const currentState = config || {}
    currentState.retryCount = currentState.retryCount || 0
    config = currentState
    return currentState
}

// Check if the connection was not aborted and HTTP status code is 5XX
const isRetryableError = (error, config) => {
    return (
        error.code !== 'ECONNABORTED' &&
        error?.response?.status >= 500 &&
        error?.response?.status <= 599 &&
        !config?.url?.includes('/seamless')
    )
}

export const addRetryInterceptor = axiosInstance => {
    axiosInstance.interceptors.response.use(null, error => {
        const config = error.config

        if (!config) {
            return Promise.reject(error)
        }

        const currentState = getCurrentState(config)
        const shouldRetry = currentState.retryCount < MAX_RETRIES && isRetryableError(error, config)

        if (shouldRetry) {
            currentState.retryCount += 1

            config.transformRequest = [data => data]

            return new Promise.resolve(axiosInstance(config))
        }

        return Promise.reject(error)
    })
}
