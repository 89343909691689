import { fetchLicenseFailed, licenseReceived } from '../geo-license'
import { fetchLicense, GEOCOMPLY_LICENSE_FETCH } from './fetch-license'
import awAxios from '../../../../common/axios/AWAxios'

import { commonHeadersAW } from '../../../../common/commonHeaders'
import { getLicenseUrl } from '../../../../common/helpers/plc-helper'
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper'
import { fetchEnd, fetchError, fetchStart } from '../../../fetch/fetch'

export const fetchNewLicense = () => async (dispatch, getState) => {
    console.info('fetchNewLicense()')
    console.warn('LICENSE UPDATING')
    const requestUid = getRequestUid(FetchType.License)
    const licenseUrl = getLicenseUrl()

    dispatch({ type: GEOCOMPLY_LICENSE_FETCH }) // could be removed I think
    dispatch(fetchStart(requestUid))
    const license = getState().geocomply?.license.license

    console.info('fetchNewLicense() - license', license)

    if (license) {
        try {
            const data = {
                license
            }
            const response = await awAxios.post(licenseUrl, data, { headers: commonHeadersAW })

            console.info('fetchNewLicense()', response?.data)

            const updatedLicense = response?.data?.geocomply_license[0]
            if (updatedLicense) {
                dispatch(licenseReceived(updatedLicense))
            } else {
                dispatch(fetchLicenseFailed({ message: 'UNKNOWN' }))
            }
            dispatch(fetchEnd(requestUid))
        } catch (err) {
            console.error('[FETCH_NEW_LICENSE] fetchNewLicense()', JSON.stringify(err))
            console.warn('LICENSE UPDATED FAILED')

            dispatch(fetchLicenseFailed(err))
            dispatch(fetchError(requestUid))
        }
    } else {
        console.warn('LICENSE MISSING')
        dispatch(fetchLicense())
    }
}
