const getQueryParameter = (param, queryString = '') => {
    if (queryString === '' && window !== undefined) {
        queryString = window.location.search;
    }

    const returnParam = decodeURIComponent(
        queryString.replace(
            new RegExp(
                `^(?:.*[&\\?]${encodeURIComponent(param).replace(/[\\.\\+\\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`,
                'i'
            ),
            '$1'
        )
    );

    return returnParam ? returnParam : null;
};

export { getQueryParameter };
