const SHOWOFF_ELEMENTS = {
    MENU_BUTTON: 'menu_button',
    DASHBOARD_BUTTON: 'Dashboard',
    MY_ACCOUNT_CARD: 'account',
    RECENTLY_PLAYED_CARD: 'recently_played_games',
    GAME_HELP_BUTTON: 'Game Help',
    TIMERS: 'timers',
    EXIT_BUTTON: 'exit_button'
} as const;

const FEATURE_PROGRESS = { FULL: 'full', NOTICED: 'noticed' } as const;

type FeatureProgressType = typeof FEATURE_PROGRESS[keyof typeof FEATURE_PROGRESS];

const FOCUS_TYPE = { OVERLAY: 'overlay', BOX: 'box', NOTHING: 'nothing' } as const;

type FocusType = typeof FOCUS_TYPE[keyof typeof FOCUS_TYPE];

const DISMISS_TYPE = { TIMEOUT: 'timeout', ACTION: 'action' } as const;

type DismissType = typeof DISMISS_TYPE[keyof typeof DISMISS_TYPE];

const ACTION_TYPE = { SWIPE_TO: 'swipe_to' } as const;

type ActionType = typeof ACTION_TYPE[keyof typeof ACTION_TYPE];

interface StepType {
    attr: ShowoffElementType;
    focusType: FocusType;
    bypassCondition?: (elem: HTMLElement) => boolean;
    dismissType?: DismissType;
    order?: number;
}

type CurrentSelectedType = Omit<StepType, 'attr'> & { element: HTMLElement };

interface IShowoffItemInitial {
    feature?: boolean;
    step?: boolean;
    focusType?: FocusType;
    bypassCondition?: (elem: HTMLElement) => boolean;
    attributeName: ShowoffElementType;
    children?: ShowoffStructure;
    order?: number;
    dismissType?: DismissType;
    triggerAction?: ActionType;
}

interface IContextShowoff {
    name?: string;
    feature?: IShowoffItemFormatted;
    currentStep?: { name: ShowoffElementType } & CurrentSelectedType;
}

type ShowoffStructure = {
    [key in keyof typeof SHOWOFF_ELEMENTS]?: IShowoffItemInitial;
};

interface IShowoffItemFormatted {
    steps: StepType[];
    notice?: boolean;
    show?: boolean;
    name: ShowoffElementType;
    order?: number;
    dismissType?: DismissType;
}

type ShowoffElementType = typeof SHOWOFF_ELEMENTS[keyof typeof SHOWOFF_ELEMENTS];

// Message Overlay types

const possiblePositions = ['top', 'right', 'bottom', 'left'] as const;

type PositionType = typeof possiblePositions[number];

interface IMessageOverlay {
    elemRef: { element: HTMLElement; focusType: FocusType };
    title?: string;
    caption?: string;
    action?: () => void;
    actionDismiss?: () => void;
}

export {
    IShowoffItemFormatted,
    IShowoffItemInitial,
    ShowoffStructure,
    ShowoffElementType,
    DISMISS_TYPE,
    SHOWOFF_ELEMENTS,
    StepType,
    FOCUS_TYPE,
    DismissType,
    FocusType,
    FeatureProgressType,
    FEATURE_PROGRESS,
    CurrentSelectedType,
    possiblePositions,
    PositionType,
    IMessageOverlay,
    ActionType,
    ACTION_TYPE,
    IContextShowoff
};
