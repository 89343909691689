import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IVerifyLocationFailed {
    tokenDetails?: Record<string, unknown>;
    decodedToken?: Record<string, unknown>;
    err?: Record<string, unknown>;
    response?: Record<string, unknown>;
}

export const ddActionVerifyLocationFailed = (
    { ...context }: IVerifyLocationFailed,
    use_case: IddUseCases
): IddRumAction => ({
    name: '[VERIFY_LOCATION] verify location failed',
    description: 'Verify location failed',
    context,
    use_case
});
