import { useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { getAWCookies } from '../../helpers/cookies';

import { fetchLicense } from '../../../redux/geo-comply/license/geo-license';
import { scheduleFetchLicense } from '../../../redux/geo-comply/license/actions/schedule-fetch-license';
import { scheduleIpCheck } from '../../../redux/geo-comply/license/actions/schedule-ip-check';
import { regionSelector, rgiSelector } from '../../../selectors/gameContext';
import { RGIS } from '../../constants';

const useGeocomplyLicenseAndroid = () => {
    const license = useSelector(state => state?.geocomply?.license?.license);
    const expiry_date = useSelector(state => state?.geocomply?.license?.expiry_date);

    const licenseState = useSelector(state => state?.geocomply?.license?.state);

    const locationDetails = useSelector(state => state?.geocomply?.location?.details);
    const geoPacketValidated = useSelector(state => state?.plc?.validated);
    const rgi = useSelector(rgiSelector);

    const region = useSelector(regionSelector);
    const { authToken } = getAWCookies();

    const dispatch = useDispatch();

    useEffect(() => {
        if ((!license || licenseState?.toLowerCase() !== region) && authToken && rgi === RGIS.INCENTIVE_GAMES) {
            // get license if we don't have it cached or is from a wrong region
            dispatch(fetchLicense());
        }
    }, [license, authToken, licenseState, region, dispatch, rgi]);

    useEffect(() => {
        if (expiry_date) {
            // schedule to get a new license before it expires
            dispatch(scheduleFetchLicense());
        }
    }, [dispatch, expiry_date]);

    useEffect(() => {
        if (locationDetails && geoPacketValidated) {
            // schedule IP check
            dispatch(scheduleIpCheck());
        }
    }, [locationDetails, geoPacketValidated, dispatch]);
};

export default useGeocomplyLicenseAndroid;
