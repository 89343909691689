import { DI_SERVICE } from '../constants'
import { register } from '../utils'

import { fetchLocation } from '../../redux/geo-plc/actions/fetch-location'

const registerFetchLocation = () => register(
    DI_SERVICE.fetchLocation,
    [DI_SERVICE.APP_PLATFORM]
)(fetchLocation)

export default registerFetchLocation
