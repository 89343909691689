import { h } from 'preact';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WarningIcon from '../../../assets/icons/GameWindow/WarningIcon';
import XButton from '../../../assets/icons/GameWindow/XButton';
import { hideExitPopupRecentlyPlayed } from '../../../redux/actions/exit-popup/exit-popup-recently-played';

import { useCallback, useEffect } from 'preact/hooks';
import { useOrientation } from '../../../common/hooks/useOrientation';
import addRecentlyPlayed from '../../../redux/actions/recently-played/add-recently-played';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';

require(`./styles/${process.env.APPLICATION}/style.scss`);
//to add parametr gameUrl
const ExitPopupRecentlyPlayed = () => {
    const dispatch = useDispatch();
    const { deviceType } = useOrientation();
    const nextGameUrl = useSelector(state => state?.exitPopupRecentlyPlayed?.nextGameUrl);
    const nextGameId = useSelector(state => state?.exitPopupRecentlyPlayed?.nextGameId);
    const { logTrackingComponent } = useLogTracking();

    const { t } = useTranslation();
    const exitGameMessage = `${t('gameWindow.exitPopupRecentlyPlayed.message')}`;
    const exitButton = `${t('gameWindow.exitPopupRecentlyPlayed.buttonExit')}`;
    const cancelButton = `${t('gameWindow.exitPopupRecentlyPlayed.buttonCancel')}`;
    const displayExitPopup = useSelector(state => state?.exitPopupRecentlyPlayed?.displayModal);

    const sendGtmMessage = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exit Modal Viewed',
            [getAmplitudeKey('MODULE')]: MODULES.RECENTLY_PLAYED,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD
        });
    }, [logTrackingComponent]);

    useEffect(() => {
        if (displayExitPopup) {
            sendGtmMessage();
        }
    }, [displayExitPopup, sendGtmMessage]);


    const handleExitIcon = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exit Modal Closed',
            [getAmplitudeKey('LINK_URL')]: undefined,
            [getAmplitudeKey('LINK_TEXT')]: 'exit_modal',
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
            [getAmplitudeKey('MODULE')]: MODULES.RECENTLY_PLAYED
        });
        dispatch(hideExitPopupRecentlyPlayed());
    }, [dispatch, logTrackingComponent]);

    const handleExitButton = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game CTA Clicked',
            [getAmplitudeKey('LINK_URL')]: nextGameUrl ? nextGameUrl : undefined,
            [getAmplitudeKey('LINK_TEXT')]: 'launch_game',
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
            [getAmplitudeKey('MODULE')]: MODULES.RECENTLY_PLAYED
        });
        dispatch(hideExitPopupRecentlyPlayed());
        dispatch(addRecentlyPlayed(nextGameId, deviceType, nextGameUrl));
    }, [logTrackingComponent, nextGameUrl, dispatch, nextGameId, deviceType]);

    const handleCancelButton = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game CTA Clicked',
            [getAmplitudeKey('LINK_URL')]: undefined,
            [getAmplitudeKey('LINK_TEXT')]: 'cancel',
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
            [getAmplitudeKey('MODULE')]: MODULES.RECENTLY_PLAYED
        });
        dispatch(hideExitPopupRecentlyPlayed());
    }, [dispatch, logTrackingComponent]);

    return displayExitPopup ? (
        <div className="games-modal general exit-popup">
            <div className="games-container container exit-popup">
                <button className="exit-icon">
                    <div onClick={handleExitIcon}>
                        <XButton color={'gray'} size={'25'} strokeWidth={'2.59'} />
                    </div>
                </button>
                <div className="exit-body">
                    <div className="exit-header">
                        <WarningIcon />
                        <p className="exit-title recently-played">{exitGameMessage}</p>
                    </div>
                </div>
                <div className="exit-footer">
                    <button className="exit-button primary" onClick={handleExitButton}>
                        {exitButton}
                    </button>
                    <button className="exit-button second" onClick={handleCancelButton}>
                        {cancelButton}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default ExitPopupRecentlyPlayed;
