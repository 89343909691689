export const GEOCOMPLY_PLC_MOBILE_ERROR = 'GEOCOMPLY_PLC_MOBILE_ERROR'

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload
    }
}

// Action Creators
export const mobileError = ({ androidWakeupUrl, iosWakeupUrl }) => {
    return { type: GEOCOMPLY_PLC_MOBILE_ERROR, payload: { mobileError: true, androidWakeupUrl, iosWakeupUrl } }
}
