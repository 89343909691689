import { h, Fragment} from 'preact';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../../common/helpers/format-number';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
import { depositLinkSelector, sessionTimeSelector, showDepositButtonSelector } from '../../../../selectors/game-window';
import { sendDepositMessage } from '../../../../common/helpers/send-deposit-message';

import { useCallback, useContext } from 'preact/hooks';
import { GameWindowContext } from '../../GameWindow';
import { SHOWOFF_ELEMENTS } from '../../../../common/hooks/use-showoff/useShowoff';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';

const DetailRow = ({ deviceType, title, value, id }) => {
    return (
        <div
            style={{
                display: deviceType === 'portrait' ? 'block' : 'flex',
                textAlign: 'center',
                justifyContent: deviceType === 'portrait' ? 'center' : null,
                flex: deviceType === 'portrait' ? 1 : null
            }}
        >
            <div className="content-row-title" id={`game-window-my-account-${id}-title`}>
                {title}
            </div>
            <div className="content-row-value" id={`game-window-my-account-${id}-value`}>
                {value}
            </div>
        </div>
    );
};

const MyAccount = ({ width }) => {
    const { deviceType } = useContext(GameWindowContext);
    const { t } = useTranslation();
    const sessionTime = useSelector(sessionTimeSelector);
    const { logTrackingComponent } = useLogTracking();

    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const isMenuOpen = useSelector(state => state?.displayMenu?.displayMenu);
    const username = useSelector(state => state?.userContextReducer?.userContextData?.accountSettings?.username);

    const lastLoginDate = useSelector(state => {
        const lastLogin = state?.userContextReducer?.userContextData?.accountSettings?.lastLoginDate;

        if (!lastLogin) {
            return state?.userContextReducer?.userContextData?.accountSettings?.sessionStartTime;
        }

        return lastLogin;
    });

    const formattedLoginDate = lastLoginDate => {
        return dayjs(lastLoginDate).format(t('timerFormat.lastLogin'));
    };

    const playableBalance = useSelector(state => state?.balance?.playableBalance);
    const casinoDeposits = useSelector(state => state?.balance?.casinoDeposits);
    const casinoBonuses = useSelector(state => state?.balance?.casinoBonuses);
    const sharedWinnings = useSelector(state => state?.balance?.sharedWinnings);
    const showDepositButton = useSelector(showDepositButtonSelector);
    const depositLink = useSelector(depositLinkSelector);
    const colonOrNull = deviceType === 'portrait' ? '' : ':\u00A0';

    const title = username || 'username';
    const content = isMenuOpen && (
        <Fragment>
            <div className="content-row">
                <DetailRow
                    deviceType={deviceType}
                    title={`Last Login${colonOrNull}`}
                    value={formattedLoginDate(lastLoginDate)}
                    id={'last-login'}
                />
                <DetailRow
                    deviceType={deviceType}
                    title={`Session Time${colonOrNull}`}
                    value={sessionTime}
                    id={'session-time'}
                />
            </div>
            <div className="content-row">
                <DetailRow
                    deviceType={deviceType}
                    title={`Playable balance${colonOrNull}`}
                    value={`$${formatNumber(playableBalance)}`}
                    id={'playable-balance'}
                />
                <DetailRow
                    deviceType={deviceType}
                    title={`Deposits${colonOrNull}`}
                    value={`$${formatNumber(casinoDeposits)}`}
                    id={'deposits'}
                />
            </div>
            <div className="content-row">
                <DetailRow
                    deviceType={deviceType}
                    title={`Casino Bonuses${colonOrNull}`}
                    value={`$${formatNumber(casinoBonuses)}`}
                    id={'casino-bonuses'}
                />
                <DetailRow
                    deviceType={deviceType}
                    title={`Shared Winnings${colonOrNull}`}
                    value={`$${formatNumber(sharedWinnings)}`}
                    id={'shared-winnings'}
                />
            </div>
        </Fragment>
    );

    const handleDepositButton = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game CTA Clicked',
            [getAmplitudeKey('MODULE')]: MODULES.GW,
            [getAmplitudeKey('LINK_URL')]: depositLink,
            [getAmplitudeKey('LINK_TEXT')]: 'deposit',
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD
        });
        sendDepositMessage();
    }, [depositLink, logTrackingComponent]);

    return (
        <div
            className={`card-wrapper ${deviceType !== 'desktop' ? 'mobile' : ''}`}
            style={{ width: deviceType === 'portrait' ? width : '', flex: 1 }}
            data-showoff={SHOWOFF_ELEMENTS.MY_ACCOUNT_CARD}
            data-showoff-badge={'false'}
        >
            <div className={`card-title ${deviceType} content-row-value`}>{title} </div>
            <div className={`card-content ${deviceType} my-account-content`}>{content} </div>

            {showDepositButton && depositLink && (
                <a href={depositLink} rel="noreferrer" target="_blank" onClick={handleDepositButton}>
                    <div className={`deposit-button ${deviceType !== 'desktop' ? 'mobile' : ''}`}>Deposit</div>
                </a>
            )}
        </div>
    );
};

export default MyAccount;
