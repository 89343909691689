import { getRegion } from './cookies';
import { isMobile } from 'react-device-detect';

export const getRecentlyPlayedUrl = item => {
    const region = getRegion();
    const brand = process.env.BRAND;

    const providerRef = isMobile ? item?.providerRefMobile : item?.providerRefDesktop;

    if (item.gameUid) {
        return `${window.origin}/${item.gameUid}`;
    }

    if (item?.rgi === 'GAN' || item?.rgi === 'FGP') {
        return `${window.origin}/?providerName=${item?.providerName}&region=${region}&brand=${brand}&gameName=${item?.displayName}&rgi=${item?.rgi}&ganId=${item?.ganId}&providerRef=${providerRef}&gameId=${item?.gameId}&switchedToPopup=true&recentlyPlayed=true`;
    }
};
