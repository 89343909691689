import { useEffect, useState } from 'preact/hooks';
import { OptInManager } from '@fanduel/casino-swj-consumer';
import { getFgpEndpoint } from '../../../../../../common/helpers/get-fgp-endpoint';
import { datadogLogs } from '@datadog/browser-logs';
import { ERROR_TYPE } from '../../../../../../game-window/contants/errors';
import { setErrorMessageDisplayed, setInGameError } from '../../../../../../redux/actions/game-window';
import { useDispatch } from 'react-redux';

export const useOptinManager = jackpotId => {
    const dispatch = useDispatch();

    const [optedIn, setOptedIn] = useState<boolean | -2>(-2); // -2: not visible, true/false

    const OptinManagerInstance = new OptInManager(getFgpEndpoint(), 'game_window', {
        defaultJackpotId: jackpotId,
        callback: setOptedIn as (optedIn: boolean) => void,
        errorCallback: error => {
            dispatch(setErrorMessageDisplayed(true));
            dispatch(setInGameError({
                errorCode: error.reason,
                errorMessage: error.message,
                errorType: ERROR_TYPE.SWJ_SERVICE_ERROR,
            }));
        }
    });

    const initializeOptIn = async () => {
        try {
            datadogLogs.logger.info('[MDS][OPTIN]jackpots_fetch_optin_status_initiated', {});
            await OptinManagerInstance.checkOptedIn();
            datadogLogs.logger.info('[MDS][OPTIN]jackpots_fetch_optin_status_resolved', {});
        } catch (error) {
            console.log(error);
            datadogLogs.logger.error(
                '[MDS][OPTIN]jackpots_fetch_optin_status_failed',
                Object.assign(
                    {
                        type: '[MDS][OPTIN]jackpots_fetch_optin_status_failed'
                    },
                    error
                )
            );
        }
    };

    const handleOptInToggle = async () => {
        try {
            datadogLogs.logger.info(
                `[MDS][OPTIN]${
                    optedIn ? 'jackpots_set_optout_status_initiated' : 'jackpots_set_optin_status_initiated'
                }`,
                {}
            );
            optedIn ? await OptinManagerInstance.optOut() : await OptinManagerInstance.optIn();
            datadogLogs.logger.info(
                `[MDS][OPTIN]${optedIn ? 'jackpots_set_optout_status_resolved' : 'jackpots_set_optin_status_resolved'}`,
                {}
            );
        } catch (error) {
            console.log(error);
            datadogLogs.logger.error(
                `[MDS][OPTIN]${optedIn ? 'jackpots_set_optout_status_failed' : 'jackpots_set_optin_status_failed'}`,
                Object.assign(
                    {
                        type: `[MDS][OPTIN]${
                            optedIn ? 'jackpots_set_optout_status_failed' : 'jackpots_set_optin_status_failed'
                        }`
                    },
                    error
                )
            );
        }
    };

    useEffect(() => {
        initializeOptIn();
    }, []);

    return { optedIn, setOptedIn, handleOptInToggle };
};
