import { PopupAction } from "../../reducers/popup/types";
import { dismissPopup } from "./popup";
import { closeRealityCheckPopup, redirectRealityCheckUrl } from "../../../components/modal/reality-check/reality-check-web";

export type ActionMapper = {
  [key in PopupAction]: (data?: string) => void;
};

export const popupActions: ActionMapper = {
  DISMISS_POPUP: dismissPopup,
  DISMISS_REALITY_CHECK: closeRealityCheckPopup,
  REDIRECT_REALITY_CHECK_URL: redirectRealityCheckUrl,
}
