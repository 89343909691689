import { isRunningInsideWrapper, sendWrapperEvent } from '../wrapper-bridge-mobile';
import { MESSAGES } from '../constants';
import { PLATFORM } from '../constants/game-constants';
import { getAppPlatform } from './app-platform';

export const openExternalWebview = webUrl => {
    if (getAppPlatform() === PLATFORM.Xsell && isRunningInsideWrapper()) {
        sendWrapperEvent(MESSAGES.OPEN_EXTERNAL_WEBVIEW, webUrl);
    }
};
