import { useSelector } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'

import config from '../../../../config/config'
import { isLoggedIn } from '../../helpers'
import { substituteJsonPlaceholders } from '../../helpers/jsonPlaceholders'
import { getRegion, getUserId } from '../../helpers/cookies'

const useAuthenticated = () => {
    let userId = useSelector(state => state?.session?.userId)
    const sessionId = useSelector(state => state?.session?.sessionId)
    const playerGuid = useSelector(state => state?.GANLogin?.ganToken?.playerGuid)

    if (!userId || userId === true){
        userId = getUserId()
    }

    const loggedIn = isLoggedIn()
    const variables = {
        region: getRegion()?.toUpperCase(),
        externalReferrerNext: encodeURIComponent(window.location.search)
    }

    const loginURL = substituteJsonPlaceholders(config['loginURL'], variables)

    if (loggedIn) {
        datadogRum.setUser({
            id: userId,//externalPlayerRef
            sessionId: sessionId || 'undefined',
            playerGuid: playerGuid || 'undefined'
        })
    } else {
        datadogRum.addAction('[A&W] REDIRECTED_TO_LOGIN')
        window.location = loginURL
    }
}

export default useAuthenticated
