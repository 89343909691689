import config from '../../config/config'
import { AW_COOKIE } from './constants'
import { getAWCookies } from './helpers/cookies'

const { AUTH_TOKEN } = AW_COOKIE
const { authToken } = getAWCookies()

export const commonHeadersAW = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${config.clientID}`,
    [AUTH_TOKEN]: authToken,
    'Cache-Control': 'no-store, no-cache'
}