import { Action } from '../../redux/reducers/popup/types';
import { ActionButton } from './ActionButton';

type Props = {
    primaryAction?: Action;
    dismissAction?: Action;
};

export const PopupActionContainer = ({ dismissAction, primaryAction }: Props) => {
    return (
        <div>
            <div className="main-button dismiss-button">
                {dismissAction && <ActionButton action={dismissAction} type="dismiss" />}
            </div>
            <div className="secondary-button">
                {primaryAction && <ActionButton action={primaryAction} type="primary" />}
            </div>
        </div>
    );
};
