/**
 * Not all browsers are compatible with the network interface, and in those cases it will return 'unknown'
 * more details: https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
 */
const connectionType = () => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    return connection?.effectiveType || 'unknown'
}

export default connectionType
