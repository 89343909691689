import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'preact/hooks';
import jwtDecode from 'jwt-decode';
import {
    locationVerified,
    setGeocomplyRetrying,
    verifyLocationFailed
} from '../../../redux/geo-comply/location/geo-location';
import { GEOCOMPLY_ERROR_TYPES } from '../../constants';
import { plcError, plcInstalled } from '../../../redux/geo-plc/geo-plc';
import { ddRumAction } from '../../helpers/datadog-wrapper';
import { ddActionVerifyLocationFailed } from '../../datadog/verify-location/vl-failed';
import { ddActionVerifyLocationInProgress } from '../../datadog/verify-location/vl-in-progress';
import { DD_USE_CASES } from '../../datadog/enums';
import { getAppPlatform } from '../../helpers/app-platform';
import { PLATFORM } from '../../constants/game-constants';
import { getGeocomplyIpCookie, getSessionId, getUserId } from '../../helpers/cookies';
import { setGameContext } from '../../../redux/actions/gameContext';
import { experimentsSelector } from '../../../selectors/amplitude-experiments';

//temp until aw-geocomply import with new instance is fixed => rm issues on ios
const LOCATION_IN_PROGRESS = 'aw-geolocation/location-in-progress';
const GEOLOCATION_PASSED = 'aw-geolocation/geolocation-passed';
const GEOLOCATION_REJECTED = 'aw-geolocation/geolocation-rejected';
const LOCATION_FAILURE = 'aw-geolocation/location-failure';

export const useGeocomplyCallback = () => {
    const dispatch = useDispatch();
    const amplitudeExperiments = useSelector(experimentsSelector);
    const geocomplyEnabled = amplitudeExperiments?.geocomplyEnabled === 'on';

    const handleGeolocationInProgress = useCallback(payload => {
        ddRumAction(ddActionVerifyLocationInProgress({ payload: {...payload, geocomplyEnabled} }, DD_USE_CASES.GLOBAL));
    }, [geocomplyEnabled]);

    const handleGeolocationPassed = useCallback(
        payload => {
            if (getAppPlatform() === PLATFORM.Xsell) {
                dispatch(
                    locationVerified({
                        tokenDetails: {
                            result: true,
                            verified: true,
                            ip_address: getGeocomplyIpCookie() ?? '10.123.0.55',
                            session_id: getSessionId(),
                            user_id: getUserId(),
                        },
                        geocomplyEnabled
                    })
                );
            } else {
                const decodedToken = jwtDecode(payload.jwt);

                if (decodedToken.result) {
                    dispatch(setGeocomplyRetrying(false));
                    dispatch(
                        locationVerified({
                            tokenDetails: { ...decodedToken },
                            geocomplyEnabled
                        })
                    );
                }
            }
            dispatch(setGameContext({ gameLoaded: true }));
        },
        [dispatch, geocomplyEnabled]
    );

    const handleGeolocationRejected = useCallback(
        payload => {
            ddRumAction(ddActionVerifyLocationFailed({ payload: { ...payload, geocomplyEnabled } }, DD_USE_CASES.GLOBAL));
            dispatch(setGeocomplyRetrying(false));
            dispatch(
                verifyLocationFailed({
                    type: GEOCOMPLY_ERROR_TYPES.VALIDATION,
                    message: payload.rejection.message,
                    troubleshooter: payload.rejection.troubleshooters
                })
            );
        },
        [dispatch, geocomplyEnabled]
    );

    const handleGeolocationFailure = useCallback(
        payload => {
            ddRumAction(ddActionVerifyLocationFailed({ payload: { ...payload, geocomplyEnabled } }, DD_USE_CASES.GLOBAL));

            const { error } = payload;

            const errorCode = error.errorCode;

            dispatch(setGeocomplyRetrying(false));
            switch (errorCode) {
                case 600:
                    dispatch(plcError({ errorCode, message: 'Unexpected error occurs.', error }));
                    break;
                case 601:
                    dispatch(
                        plcError({
                            errorCode,
                            message:
                                'Signature verification of GeoComply Browser Plugin support binaries fails. That means the binaries were tampered.',
                            error
                        })
                    );
                    break;
                case 602:
                    dispatch(plcError({ errorCode, message: 'The network connection is not available.', error }));
                    break;
                case 603:
                    dispatch(plcError({ errorCode, message: 'The server communication failed.', error }));
                    dispatch(verifyLocationFailed({ type: GEOCOMPLY_ERROR_TYPES.GENERAL, message: '' }));
                    break;
                case 604:
                    dispatch(
                        plcError({
                            errorCode,
                            message:
                                'The app client account is suspended by administrator at GeoComply Back Office site.',
                            error
                        })
                    );
                    break;
                case 605:
                    dispatch(
                        plcError({
                            errorCode,
                            message:
                                'The geolocation solution is disabled for app client account by administrator at GeoComply Back Office site.',
                            error
                        })
                    );
                    break;
                case 612:
                case 613:
                    dispatch(plcInstalled(false));
                    dispatch(verifyLocationFailed({ type: GEOCOMPLY_ERROR_TYPES.GENERAL, message: '' }));
                    break;
                case 708:
                    dispatch(
                        verifyLocationFailed({
                            type: GEOCOMPLY_ERROR_TYPES.GENERAL,
                            message: 'Failed to validate geopacket'
                        })
                    );
                    break;
                default:
                    dispatch(plcError({ errorCode, message: `UNHANDLED [${errorCode}]`, error }));
            }
        },
        [dispatch, geocomplyEnabled]
    );

    const geolocationCallback = useCallback(
        action => {
            const actionMapper = {
                [LOCATION_IN_PROGRESS]: handleGeolocationInProgress,
                [GEOLOCATION_PASSED]: handleGeolocationPassed,
                [GEOLOCATION_REJECTED]: handleGeolocationRejected,
                [LOCATION_FAILURE]: handleGeolocationFailure
            };

            actionMapper[action.type]?.(action.payload);
        },
        [handleGeolocationInProgress, handleGeolocationFailure, handleGeolocationPassed, handleGeolocationRejected]
    );

    return {
        geolocationCallback,
        handleGeolocationFailure,
        handleGeolocationInProgress,
        handleGeolocationPassed,
        handleGeolocationRejected
    };
};
