import { useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { verifyLocation } from '../../../redux/geo-comply/location/actions/verify-location';

import { getRegion } from '../../helpers/cookies';
import config from '../../../../config/config';
import { rgiSelector } from '../../../selectors/gameContext';
import { RGIS } from '../../constants';

const useGeolocationVerifyAndroid = () => {
    const geoPacket = useSelector(state => state?.plc?.geoPacket);
    const geoPacketValidated = useSelector(state => state?.plc?.validated);
    const rgi = useSelector(rgiSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (geoPacket && !geoPacketValidated && rgi === RGIS.INCENTIVE_GAMES) {
            console.info('------- useGeolocationVerify()');
            // verify location if it wasn't
            dispatch(verifyLocation(getRegion()?.toLowerCase(), config?.geoComply?.product, geoPacket));
        }
    }, [dispatch, geoPacket, geoPacketValidated, rgi]);
};

export default useGeolocationVerifyAndroid;
