import { h, Fragment } from 'preact'
import { useSelector } from 'react-redux'
import { useInject } from 'inversify-hooks'

import { DI_SERVICE } from '../../dependency-injection/constants'


require(`./styles/${process.env.APPLICATION}/style.scss`)

const Modal = () => {
    const [RealityCheck] = useInject(DI_SERVICE.realityCheck)
    const [GeoComply] = useInject(DI_SERVICE.geocomplyModal)
    const loggedIn = useSelector(state => state?.session?.userId)

    return (
        <Fragment>
            {loggedIn && <GeoComply />}
            <RealityCheck />
        </Fragment>
    )
}

export default Modal
