// eslint-disable-next-line no-unused-vars
import { h, Fragment } from 'preact'
import { useTranslation } from 'react-i18next'
import Map from '../../../../assets/icons/Map/map'

const CheckingLocation = () => {
    const { t } = useTranslation()
    
    return (
        <Fragment>
            <div className="modal__image">
                <Map />
            </div>
            <div className="modal__header">{t('geocomply.checkingLocation.title')}</div>

            <div className="modal__body">
                <p>{t('geocomply.checkingLocation.description')}</p>
            </div>
        </Fragment>
    )
}

export default CheckingLocation
