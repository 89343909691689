import { register } from '../utils'
import { DI_SERVICE } from '../constants'

import useGeocomplyLicense from '../../common/hooks/use-geocomply-license'

const registerUseGeocomplyLicense = () => register(
    DI_SERVICE.useGeocomplyLicense,
    [DI_SERVICE.APP_PLATFORM]
)(useGeocomplyLicense)

export default registerUseGeocomplyLicense
