import { h } from 'preact'

const Checked = () => (
    <svg className="checked-icon"
         width="18"
         height="18"
         viewBox="4 3 18 18">
        <defs>
            <filter
                id="filter-1"
                filterUnits="objectBoundingBox"
            >
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
            <path
                id="path-4"
                d="M8.5 0a8.5 8.5 0 10.001 17.001A8.5 8.5 0 008.5 0zm0 16C4.365 16 1 12.635 1 8.5S4.365 1 8.5 1 16 4.365 16 8.5 12.635 16 8.5 16zm3.616-10.82l-4.649 5.58-2.613-2.613a.5.5 0 00-.707.707l3 3A.497.497 0 007.5 12h.022a.5.5 0 00.362-.179l5-6a.5.5 0 00-.768-.641z"
            />
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-441 -695)">
                <g transform="translate(112 212)">
                    <g filter="url(#filter-1)" transform="translate(333 486)">
                        <g mask="url(#mask-3)">
                            <g>
                                <g
                                    fillRule="evenodd"
                                    strokeWidth="1"
                                >
                                    <g>
                                        <path d="M0 0H17V17H0z" />
                                        <mask id="mask-5" fill="#fff">
                                            <use xlinkHref="#path-4" />
                                        </mask>
                                        <g fill="#FFF" mask="url(#mask-5)">
                                            <g>
                                                <path d="M0 0H17V17H0z" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Checked
