const GeneralErrorIcon = ({ size }) => {
    return (
        <svg width={`${size}`} height={`${size}`} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5882 26.8235C22.5882 27.6032 23.2203 28.2353 24 28.2353C24.7797 28.2353 25.4118 27.6032 25.4118 26.8235V12.7059C25.4118 11.9262 24.7797 11.2941 24 11.2941C23.2203 11.2941 22.5882 11.9262 22.5882 12.7059V26.8235Z"
                fill="#BF2D2D"
            />
            <path
                d="M22.5882 35.2941C22.5882 36.0738 23.2203 36.7059 24 36.7059C24.7797 36.7059 25.4118 36.0738 25.4118 35.2941V32.4706C25.4118 31.6909 24.7797 31.0588 24 31.0588C23.2203 31.0588 22.5882 31.6909 22.5882 32.4706V35.2941Z"
                fill="#BF2D2D"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.826993 25.9965C-0.275664 24.8939 -0.275664 23.1061 0.826993 22.0035L22.0035 0.826993C23.1061 -0.275664 24.8939 -0.275664 25.9965 0.826993L47.173 22.0035C48.2757 23.1061 48.2757 24.8939 47.173 25.9965L25.9965 47.173C24.8939 48.2757 23.1061 48.2757 22.0035 47.173L0.826993 25.9965ZM24 45.1765L2.82353 24L24 2.82353L45.1765 24L24 45.1765Z"
                fill="#BF2D2D"
            />
        </svg>
    );
};

export default GeneralErrorIcon;
