import { types } from '../../types';

export const showExitPopup = (redirectMsg = '') => dispatch => {
    dispatch({
        type: types.SHOW_EXIT_POPUP,
        redirectMsg
    });
};

export const hideExitPopup = () => dispatch => {
    dispatch({
        type: types.HIDE_EXIT_POPUP
    });
};
