const LoadingThemes = [
    "Default",
    "Willy Wonka full, coming soon",
    "Willy Wonka full, available now",
    "Willy Wonka bottom, coming soon",
    "Willy Wonka bottom, available now",
] as const;

export const LOADING_STATES = {
    VERIFYING_LOCATION: 'verifying-location',
    FETCHING_GAME: 'fetching-game',
    LOADING_GAME: 'loading-game'
} as const;

export type LOADING_THEMES = typeof LoadingThemes[number];

export type LoadingState = 'verifying-location' | 'fetching-game' | 'loading-game';

export type LoadingTextMap = {
    [key in LoadingState]: string;
};

export interface GenericLoadingScreenProps {
    ready: boolean;
    loadingText: string;
    counter: number;
    theme: any
}

export interface CustomLoadingScreenProps {
    loadingText: string;
    counter: number;
}
