import { createReducer } from '../core/reducer-factory'

import { GEOCOMPLY_PLC_CONNECT_FAILED, reducer as plcInstalledReducer } from './actions/plc-installed'
import { GEOCOMPLY_PLC_ERROR, reducer as plcErrorReducer } from './actions/plc-error'
import { GEOCOMPLY_PLC_CLEAR_ERROR, reducer as plcClearErrorReducer } from './actions/plc-clear-error'
import { GEOCOMPLY_PLC_CONNECTED, reducer as plcConnectedReducer } from './actions/plc-connected'
import { GEOCOMPLY_PLC_LOCATION_FETCH, reducer as fetchLocationReducer } from './actions/fetch-location'
import { GEOCOMPLY_PLC_LOCATION_RECEIVED, reducer as plcLocationReducer } from './actions/location-received'
import { GEOCOMPLY_PLC_LOCATION_VALIDATED, reducer as plcLocationValidatedReducer } from './actions/location-validated'
import { GEOCOMPLY_PLC_LOCATION_CLEAR, reducer as plcLocationClearReducer } from './actions/plc-location-clear'
import { GEOCOMPLY_PLC_MOBILE_ERROR, reducer as mobileErrorReducer } from './actions/mobile-error'

export const initialState = {
    connected: false,
    installed: null,
    geoPacket: undefined,
    error: null,
    mobileError: false,
    androidWakeupUrl: '',
    iosWakeupUrl: ''
}

const actionHandlers = {
    [GEOCOMPLY_PLC_CONNECT_FAILED]: plcInstalledReducer,
    [GEOCOMPLY_PLC_ERROR]: plcErrorReducer,
    [GEOCOMPLY_PLC_CLEAR_ERROR]: plcClearErrorReducer,
    [GEOCOMPLY_PLC_CONNECTED]: plcConnectedReducer,
    [GEOCOMPLY_PLC_LOCATION_RECEIVED]: plcLocationReducer,
    [GEOCOMPLY_PLC_LOCATION_FETCH]: fetchLocationReducer,
    [GEOCOMPLY_PLC_LOCATION_VALIDATED]: plcLocationValidatedReducer,
    [GEOCOMPLY_PLC_LOCATION_CLEAR]: plcLocationClearReducer,
    [GEOCOMPLY_PLC_MOBILE_ERROR]: mobileErrorReducer,

    __default__: state => state
}

export { plcInstalled } from './actions/plc-installed'
export { plcConnected } from './actions/plc-connected'
export { plcError } from './actions/plc-error'
export { plcClearError } from './actions/plc-clear-error'
export { locationReceived } from './actions/location-received'
export { fetchLocation } from './actions/fetch-location'
export { locationValidated } from './actions/location-validated'
export { plcLocationClear } from './actions/plc-location-clear'
export { mobileError } from './actions/mobile-error'

export const reducer = createReducer(actionHandlers, initialState)
