import { dismissLauncher } from '../../../../common/helpers/redirect-flows';
import { showPopup } from '../../../../redux/reducers/popup/popup';
import { POPUP_DISMISS_ACTIONS } from '../../../../redux/reducers/popup/types';
import { Dispatch } from 'redux';
import { GameClientErrors, GameClientErrorType, GAME_CLIENT_ERRORS } from './types';

const GAME_CLIENT_ERRORS_MESSAGE_MAPPER: GameClientErrors = {
  [GAME_CLIENT_ERRORS.USER_IS_NOT_ELIGIBLE]: {
    errorTitle: "You can't play right now",
    errorMessage: `Follow these tips and try again: \n
      1. Make sure you're in a state that allows online casino \n
      2.Confirm you've finished signing up \n
      3. See if you've been excluded or suspended`,
    actionTitle: 'Exit Game'
  },
  [GAME_CLIENT_ERRORS.NO_VALID_GEO_APPROVAL]: {
    errorTitle: 'Location not found',
    errorMessage: 'Sorry, we checked a few times. Go home and try another game.',
    actionTitle: 'Leave to go home'
  },
  [GAME_CLIENT_ERRORS.GENERIC_ERROR]: {
    errorTitle: 'Something went wrong',
    errorMessage: 'Failed to launch casino game. ',
    actionTitle: 'Leave to go home'
  }
};

export const gameClientErrorsHandler = (
  dispatch: Dispatch,
  errorCode: GameClientErrorType,
) => {
  if (
    errorCode === GAME_CLIENT_ERRORS.USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS
  ) {
    dismissLauncher(
      GAME_CLIENT_ERRORS.USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS,
    );
  } else {
    dispatch(
      showPopup({
        title: GAME_CLIENT_ERRORS_MESSAGE_MAPPER[errorCode].errorTitle,
        icon: true,
        buttons: {
          dismissAction: {
            title: GAME_CLIENT_ERRORS_MESSAGE_MAPPER[errorCode].actionTitle ,
            handler: POPUP_DISMISS_ACTIONS.DISMISS_POPUP,
          },
        },
        description: GAME_CLIENT_ERRORS_MESSAGE_MAPPER[errorCode].errorMessage,
      }),
    );
  }
};

