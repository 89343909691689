import { h } from 'preact';

const ArrowRight = props => {
    return (
        <svg width="13px" height="24px" fill="none" {...props}>
            <path
                d="M.396.98a1.324 1.324 0 0 0 0 1.87l9.652 9.652-9.652 9.651a1.324 1.324 0 0 0 1.872 1.872l10.588-10.589a1.33 1.33 0 0 0 0-1.874L2.268.974A1.33 1.33 0 0 0 .396.979Z"
                fill="#fff"
            />
        </svg>
    );
};

export { ArrowRight };
