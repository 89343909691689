const FanduelGeneralErrorIcon = ({ size }) => {
    return (
        <svg
            width={`${size}`}
            height={`${size - 5}`}
            viewBox="0 0 56 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="7" width="37.5" height="37.5" rx="18.75" fill="url(#paint0_linear_434_15489)" />
            <g filter="url(#filter0_d_434_15489)">
                <g clip-path="url(#clip0_434_15489)">
                    <path
                        d="M13.5297 23.6925C14.7731 23.1121 16.0834 22.7593 17.3714 22.6054C17.5117 22.5862 17.569 22.6824 17.5531 22.7946C17.3905 23.7887 17.2789 24.7988 17.2375 25.8185C17.2279 25.9339 17.1642 26.0109 17.0366 26.0365C16.3225 26.1776 15.6243 26.3989 14.856 26.7516C15.5478 27.9605 16.3831 29.0668 17.3044 29.9935C17.4096 30.1058 17.4734 30.2212 17.5021 30.4104C17.7412 31.9624 18.1588 33.5272 18.7168 35.0087C18.7518 35.0921 18.7518 35.1498 18.7008 35.1979C18.6466 35.246 18.5669 35.246 18.4904 35.1979C12.9208 31.7828 9.55739 25.6614 9.5 19.2449C9.5 19.0909 9.5542 18.9755 9.68172 18.9018C12.6403 17.2343 15.9686 16.4326 19.3097 16.5032C19.4596 16.5096 19.5106 16.6379 19.4532 16.7597C18.9909 17.7217 18.6084 18.7029 18.3055 19.681C18.2704 19.7964 18.2003 19.8541 18.0759 19.8605C16.2906 19.9535 14.5499 20.3576 12.9559 21.0245C13.0707 21.9384 13.2683 22.8299 13.5266 23.6925H13.5297ZM19.6987 19.8702C19.5871 19.8605 19.5106 19.7579 19.5457 19.6489C19.8963 18.626 20.298 17.6576 20.7571 16.7597C20.8113 16.6539 20.9006 16.609 21.0249 16.625C23.5403 16.9104 25.9218 17.7057 28.0451 18.8985C28.1758 18.9723 28.2332 19.0877 28.2268 19.2417C28.1822 25.3182 25.1567 31.1351 20.0621 34.6496C19.9665 34.7169 19.8421 34.6784 19.7975 34.5662C19.4277 33.5657 19.1248 32.5524 18.908 31.5712C18.8793 31.4397 18.908 31.3403 19.0101 31.2634C22.0929 28.8199 24.2545 25.1739 24.7741 21.0245C23.2024 20.3736 21.5031 19.9664 19.6987 19.8702V19.8702Z"
                        fill="white"
                    />
                </g>
            </g>
            <path
                d="M34.9697 2.22034C35.2638 1.92655 35.7362 1.92655 36.0303 2.22034L44.7749 10.9549C45.075 11.2547 45.075 11.7453 44.7749 12.0451L36.0303 20.7797C35.7362 21.0734 35.2638 21.0734 34.9697 20.7797L26.2251 12.0451C25.925 11.7453 25.925 11.2547 26.2251 10.9549L34.9697 2.22034Z"
                fill="url(#paint1_linear_434_15489)"
            />
            <path
                d="M34.9696 2.89705C35.2637 2.60327 35.7362 2.60327 36.0303 2.89705L44.0976 10.9551C44.3977 11.2549 44.3977 11.7455 44.0976 12.0453L36.0303 20.1034C35.7362 20.3971 35.2637 20.3971 34.9696 20.1034L26.9023 12.0453C26.6022 11.7455 26.6022 11.2549 26.9023 10.9551L34.9696 2.89705Z"
                fill="white"
            />
            <path
                d="M34.9696 3.57345C35.2637 3.27967 35.7361 3.27967 36.0302 3.57345L43.4202 10.955C43.7204 11.2548 43.7204 11.7454 43.4202 12.0452L36.0302 19.4267C35.7361 19.7205 35.2637 19.7205 34.9696 19.4267L27.5796 12.0452C27.2794 11.7454 27.2794 11.2548 27.5796 10.955L34.9696 3.57345Z"
                fill="url(#paint2_linear_434_15489)"
            />
            <path
                d="M36.093 12.3776L36.3045 7.55536H34.6864L34.9085 12.3776H36.093ZM35.5007 14.8102C35.9661 14.8102 36.3574 14.4137 36.3574 13.9529C36.3574 13.4814 35.9661 13.0849 35.5007 13.0849C35.0354 13.0849 34.6547 13.4814 34.6547 13.9529C34.6547 14.4137 35.0354 14.8102 35.5007 14.8102Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_434_15489"
                    x="4.715"
                    y="14.1075"
                    width="28.3"
                    height="28.3"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.3925" />
                    <feGaussianBlur stdDeviation="2.3925" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_434_15489" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_434_15489" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_434_15489"
                    x1="37.5"
                    y1="43.1607"
                    x2="-3.27747"
                    y2="11.6826"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#005FC8" />
                    <stop offset="1" stop-color="#610F91" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_434_15489"
                    x1="35.5"
                    y1="2"
                    x2="35.5"
                    y2="21"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#BF2D2D" />
                    <stop offset="1" stop-color="#AB0B0B" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_434_15489"
                    x1="35.5"
                    y1="2"
                    x2="35.5"
                    y2="21"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#BF2D2D" />
                    <stop offset="1" stop-color="#A80B0B" />
                </linearGradient>
                <clipPath id="clip0_434_15489">
                    <rect width="18.73" height="18.73" fill="white" transform="translate(9.5 16.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FanduelGeneralErrorIcon;
