import { locationDetailsClear } from '../../geo-comply/location/actions/location-details-clear'

export const GEOCOMPLY_PLC_LOCATION_CLEAR = 'GEOCOMPLY_PLC_LOCATION_CLEAR'

// Reducer
export const reducer = (state) => {
    return {
        ...state,
        geoPacket: null,
        validated: false
    }
}

// Action Creators
export const plcLocationClear = () => dispatch => {
    console.warn('PLC LOCATION CLEAR')

    dispatch({ type: GEOCOMPLY_PLC_LOCATION_CLEAR })
    dispatch(locationDetailsClear())
}
