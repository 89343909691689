export const GEOCOMPLY_LOCATION_RETRY = 'GEOCOMPLY_LOCATION_RETRY';

export const reducer = (state, action) => ({
    ...state,
    retrying: action.payload
});

export const setGeocomplyRetrying = retrying => ({
    type: GEOCOMPLY_LOCATION_RETRY,
    payload: retrying
});
