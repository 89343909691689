
import { getRegion } from '../../../common/helpers/cookies';
import { sendWrapperEvent } from '../../../common/wrapper-bridge-mobile';

const UNDEFINED_EVENT = 'UNDEFINED_EVENT';
const APPTENTIVE_EVENT = 'APPTENTIVE_EVENT';

const apptentiveDefaultData = {
    state: getRegion()?.toUpperCase()
}

const sendToApptentiveAndroid = (event = UNDEFINED_EVENT, data = {}) => {
    try {
        sendWrapperEvent(APPTENTIVE_EVENT, JSON.stringify({
                data_type: event,
                data: {
                    ...apptentiveDefaultData,
                    ...data
                }
            })
        )
    } catch (err) {
        return false;
    }
}

export default sendToApptentiveAndroid