import { DI_SERVICE } from '../constants'
import { register } from '../utils'

import { sendToApptentive } from '../../redux/actions/send-apptentive';

const registerSendToApptentive = () => register(
    DI_SERVICE.sendToApptentive,
    [DI_SERVICE.APP_PLATFORM]
)(sendToApptentive)

export default registerSendToApptentive