import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IGetGameWindowReceived {
    data?: Record<string, unknown>;
}

export const ddActionGetGameWindowReceived = (
    { ...context }: IGetGameWindowReceived,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'GET_GAME_WINDOW_RECEIVED',
    description: 'Game window received',
    context,
    use_case
});
