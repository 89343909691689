import { DI_SERVICE } from '../constants'
import { register } from '../utils'

import useGeolocationTrigger from '../../common/hooks/use-geolocation-trigger'

const registerUseGeolocationTrigger = () => register(
    DI_SERVICE.useGeolocationTrigger,
    [DI_SERVICE.APP_PLATFORM]
)(useGeolocationTrigger)

export default registerUseGeolocationTrigger
