import { useCallback } from 'preact/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEnd, fetchStart } from '../../redux/fetch/fetch'
import { FetchType } from '../helpers/fetch-helper'
import getFetchUid from './useFetchUid'

/**
 * Get the loading status, i.e. a boolean indicating if at least one request is pending
 *
 * @see useLoad
 *
 * @example
 *
 * import { useLoading } from 'hooks/useLoading';
 *
 * const MyComponent = () => {
 *      const loading = useLoading();
 *      return loading ? <Spinner /> : <RealContent>;
 * }
 */

/**
 * Update the loading count, which starts or stops the loading indicator.
 *
 * To be used to show the loading indicator.
 *
 * @return {Object} startLoading and stopLoading callbacks
 *
 * @example
 * import { useUpdateLoading } from 'hooks/useUpdateLoading';
 * import { fetchType } from 'hooks/fetch-helper';
 *
 * const MyComponent = () => {
 *      const { startLoading, stopLoading } = useLoading();
 *      const requestUid = getRequestUid(FetchType.License)
 *      useEffect(() => {
 *          startLoading(requestUid);
 *          fetch('http://my.domain.api/foo')
 *              .finally(() => stopLoading(requestUid));
 *      }, []);
 *      return <span>Foo</span>;
 * }
 */
const useLoading = fetchType => {
    const requests = useSelector(state => state.fetch.requests)
    const lastRequest = requests[requests.length - 1]

    const dispatch = useDispatch()

    const fetchUid = getFetchUid(fetchType || FetchType.Unknown)

    const startLoading = useCallback(() => {
        fetchType && dispatch(fetchStart(fetchUid))
    }, [dispatch, fetchType])

    const stopLoading = useCallback(() => {
        fetchType && dispatch(fetchEnd(fetchUid))
    }, [dispatch, fetchType])

    return { startLoading, stopLoading, loading: requests.length > 0, lastRequest }
}

export default useLoading
