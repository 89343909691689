export const GEOCOMPLY_LICENSE_RECEIVED = 'GEOCOMPLY_LICENSE_RECEIVED'

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        ...action.payload,
        loading: false
    }
}

// Action Creators
export const licenseReceived = license => dispatch => {
    console.warn('LICENSE RECEIVED')

    dispatch({
        type: GEOCOMPLY_LICENSE_RECEIVED,
        payload: license
    })
}
