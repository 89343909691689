export const DI_SERVICE = {
    APP_PLATFORM: 'appPlatform',
    getCurrentSessionDetails: 'getCurrentSessionDetails',
    getUserDetails: 'getUserDetails',
    fetchLocation: 'fetchLocation',
    useGeocomplyLicense: 'useGeocomplyLicense',
    useGeocomplyPlc: 'useGeocomplyPlc',
    useGeolocationTrigger: 'useGeolocationTrigger',
    useGeolocationVerify: 'useGeolocationVerify',
    useVerifyLocation: 'useVerifyLocation',
    realityCheck: 'RealityCheck',
    geocomplyModal: 'GeoComply',
    windowTitle: 'WindowTitle',
    sendToApptentive: 'sendToApptentive',
    balanceData: 'balanceData',
    trendingGames: 'sendTrendingGames',
    getServerTime: 'getServerTime',
    geocomplyFlow: 'geocomplyFlow'
};
