import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IVerifyLocationInProgress {
    response: Record<string, unknown>;
    decodedToken: Record<string, unknown>;
}

export const ddActionVerifyLocationInProgress = (
    { ...context }: IVerifyLocationInProgress,
    use_case: IddUseCases
): IddRumAction => ({
    name: '[VERIFY_LOCATION] verifying location...',
    description: 'Verify location in progress',
    context,
    use_case
});
