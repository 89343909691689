import { DI_SERVICE } from '../constants'
import { register } from '../utils'

import GeoComplyModal from '../../components/modal/geo-comply'

const registerGeoComplyModal = () => register(
    DI_SERVICE.geocomplyModal,
    [DI_SERVICE.APP_PLATFORM]
)(GeoComplyModal)

export default registerGeoComplyModal
