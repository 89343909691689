import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';
import { IRmWebRedirectContext } from './types';

export const ddActionRmWebRedirectParentWindow = (
    { ...context }: IRmWebRedirectContext,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'RM_WEB_REDIRECT_PARENT_WINDOW',
    description: 'RM redirect: done in a window after closing launcher window',
    context,
    use_case
});
