import { types } from '../../types';

export const showExitPopupRecentlyPlayed = () => dispatch => {
    dispatch({
        type: types.SHOW_EXIT_POPUP_RECENTLY_PLAYED
    });
};

export const hideExitPopupRecentlyPlayed = () => dispatch => {
    dispatch({
        type: types.HIDE_EXIT_POPUP_RECENTLY_PLAYED
    });
};

export const setNextGameURL = nextGameUrl => ({
    type: types.SET_NEXT_GAME_URL,
    payload: nextGameUrl
});

export const setNextGameId = nextGameId => ({
    type: types.SET_NEXT_GAME_ID,
    payload: nextGameId
});
