/* eslint-disable no-console */
import { types } from '../../types'

export const setRegionValid = () => dispatch => {
    dispatch({ type: types.REGION_VALIDATION_SUCCESS })
}

export const setRegionInvalid = () => dispatch => {
    dispatch({ type: types.REGION_VALIDATION_FAILED })
}
