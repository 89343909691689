export const GEOCOMPLY_LOCATION_VERIFY_CLEAR_ERROR = 'GEOCOMPLY_LOCATION_VERIFY_CLEAR_ERROR'

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        error: action.payload,
        loading: false
    }
}
// Action Creators
export const verifyLocationClearError = () => async dispatch => {
    dispatch({
        type: GEOCOMPLY_LOCATION_VERIFY_CLEAR_ERROR,
        payload: null
    })
}
