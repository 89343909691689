const ExitIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 26 23" fill="none">
            <path
                d="M25.6489 12.2094C25.7103 12.1525 25.7721 12.0687 25.8064 11.9792C25.8979 11.8033 25.8979 11.5769 25.8066 11.4008C25.7713 11.3082 25.7068 11.2238 25.6231 11.1463L21.5139 7.34162C21.2091 7.05941 20.6985 7.05936 20.3938 7.34162C20.0835 7.62893 20.0835 8.11767 20.3939 8.40487L23.0875 10.8989H11.2331C10.7849 10.8989 10.4404 11.2343 10.4404 11.6421C10.4404 12.053 10.8138 12.3853 11.2331 12.3853H23.1172L20.3937 14.9509C20.3936 14.951 20.3934 14.9512 20.3933 14.9513C20.0834 15.2387 20.0835 15.7271 20.3938 16.0141C20.5453 16.1545 20.7553 16.2376 20.9667 16.2376C21.178 16.2376 21.388 16.1544 21.5396 16.0141L21.5396 16.0141L25.6489 12.2094Z"
                fill="white"
                stroke="white"
                stroke-width="0.25"
            />
            <path
                d="M20.7729 18.2891L20.7713 18.2875H20.7209C20.2128 18.2875 19.8401 18.6954 19.8401 19.1683V20.1858C19.8401 20.5497 19.5505 20.8166 19.2094 20.8166L3.04647 20.8168C2.68265 20.8168 2.41571 20.5272 2.41571 20.1861V3.29642C2.41571 2.9326 2.70537 2.66565 3.04647 2.66565H19.2094C19.5732 2.66565 19.8401 2.95531 19.8401 3.29642V4.31397C19.8401 4.82209 20.248 5.19474 20.7209 5.19474C21.1969 5.19474 21.6017 4.79 21.6017 4.31397V3.29642C21.6017 1.94722 20.4981 0.875 19.1802 0.875H3.04642C1.69722 0.875 0.625 1.97852 0.625 3.29642V20.2151C0.625 21.5643 1.72852 22.6365 3.04642 22.6365H19.2093C20.5585 22.6365 21.6307 21.533 21.6307 20.2151V19.1976C21.6307 18.71 21.2535 18.3157 20.7729 18.2891Z"
                fill="white"
                stroke="white"
                stroke-width="0.25"
            />
        </svg>
    );
};

export default ExitIcon;
