import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IIpChanged {
    new_ip: string;
    license_ip: string;
    details: Record<string, unknown>;
    connectionType: string;
}

export const ddActionIpChanged = ({ ...context }: IIpChanged, use_case: IddUseCases): IddRumAction => ({
    name: 'IP_CHANGED',
    description: "Client ip's changed",
    context,
    use_case
});
