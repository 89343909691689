const Messages = [
    'reality_check',
    'exit_popup',
    'exit_popup_recently_played',
    'jackpot_win',
    'swj_error',
    'provider_error',
    'unknown'
] as const;

type MessageTypes = typeof Messages[number];

type PrioritiesType = MessageTypes[];

type JackpotWinType = {
    id: string;
    name: string;
    type: string;
    amount: number;
}

interface IGameWindowContext {
    isPortrait: boolean;
    dismissErrorMessage: () => void;
    exitGame: () => void;
    disableGameWindow: boolean;
    chooseDialog: () => void;
}

const queueOrder: PrioritiesType = [
    'reality_check',
    'exit_popup',
    'exit_popup_recently_played',
    'jackpot_win',
    'swj_error',
    'provider_error',
    'unknown'
];

export { IGameWindowContext, queueOrder, JackpotWinType, MessageTypes, Messages, PrioritiesType };
