import { DI_SERVICE } from '../constants'
import { register } from '../utils'

import useGeocomplyPlc from '../../common/hooks/use-geocomply-plc'

const registerUseGeocomplyPlc = () => register(
    DI_SERVICE.useGeocomplyPlc,
    [DI_SERVICE.APP_PLATFORM]
)(useGeocomplyPlc)

export default registerUseGeocomplyPlc
