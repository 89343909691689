import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IRmLocationVerified {
    locationVerified: Record<string, unknown>;
}

export const ddActionRmLocationVerified = (
    { ...context }: IRmLocationVerified,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'RM_LOCATION_VERIFIED',
    description: 'RM location verified',
    context,
    use_case
});
