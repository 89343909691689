import { useEffect } from 'preact/hooks';
import { MDS } from '@fanduel/casino-swj-consumer';
import { getMDSEndpoint } from '../../../../../../common/helpers/get-mds-endpoint';
import { datadogLogs } from '@datadog/browser-logs';
import { setJackpotWin } from '../../../../../../redux/reducers/site-wide-jackpot/site-wide-jackpot';
import { useDispatch, useSelector } from 'react-redux';
import { swjTiersSelector } from '../../../../../../selectors/site-wide-jackpot';
import { ERROR_TYPE } from '../../../../../../game-window/contants/errors';
import { setErrorMessageDisplayed, setInGameError } from '../../../../../../redux/actions/game-window';

export const useMDS = (jackpotId, setOptedIn) => {
    const dispatch = useDispatch();
    const swjTiers = useSelector(swjTiersSelector);
    const userId = useSelector(state => state?.['session']?.userId);

    const { initSocket } = MDS(getMDSEndpoint(), message => {
        const {
            data: {
                addedMessage: { type, subtype, payload }
            }
        } = message;

        try {
            const jsonPayload = JSON.parse(payload);

            if (type === 'JACKPOT' && subtype === 'WIN') {
                const tier = swjTiers[jsonPayload.jackpotTierId];

                datadogLogs.logger.info('[MDS][FEED]jackpots_messages_listener_receive', {
                    payload: jsonPayload
                });

                dispatch(
                    setJackpotWin({
                        id: jsonPayload.jackpotTierId,
                        amount: jsonPayload.amount,
                        shortName: tier.shortName,
                        name: tier.name,
                        win_particles_animation: tier?.win_particles_animation?.url,
                        win_spin_animation: tier?.win_spin_animation?.url,
                    })
                );
            }

            if (type === 'JACKPOT' && subtype === 'OPT_IN_STATUS') {
                const { optedIn, jackpotId: optedJackpot } = jsonPayload;

                if (optedJackpot === jackpotId) {
                    setOptedIn(optedIn);
                }
            }

            if (type === 'ERROR') {
                dispatch(setErrorMessageDisplayed(true));
                dispatch(
                    setInGameError({
                        errorCode: jsonPayload.errorCode,
                        errorMessage: jsonPayload.message,
                        errorType: ERROR_TYPE.SWJ_SERVICE_ERROR
                    })
                );
            }
        } catch (e) {
            console.warn('Error parsing MDS message', e);
        }
    });

    const initializeMDS = () => {
        try {
            datadogLogs.logger.info('[MDS][FEED]jackpots_messages_listener_initiated', {
            });
            userId && initSocket(userId);
            datadogLogs.logger.info('[MDS][FEED]jackpots_messages_listener_completed', {
            });
        } catch (error) {
            datadogLogs.logger.error('[MDS][FEED]jackpots_messages_failed_to_init_appsync_client',
                Object.assign(
                    {
                        type: '[MDS][FEED]jackpots_messages_failed_to_init_appsync_client',
                    },
                    error
                )
            );
            dispatch(setErrorMessageDisplayed(true));
            dispatch(setInGameError({
                errorCode: 'SWJ-201',
                errorMessage: error?.message,
                errorType: ERROR_TYPE.SWJ_SERVICE_ERROR,
            }));
        }
    }

    useEffect(() => {
        if (userId) {
            initializeMDS();
        }
    }, [userId]);
};
