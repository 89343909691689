import { isIosWrapper, isRunningInsideWrapper } from '../wrapper-bridge-mobile'
import { PLATFORM } from '../constants/game-constants'
import { PRODUCTS } from '../constants'
import { isMobile } from 'react-device-detect'

export const getProduct = () => process.env.PRODUCT

export const getAppPlatform = () => {
    if (process.env.PRODUCT === PRODUCTS.XSELL) {
        return PLATFORM.Xsell
    } else if (isIosWrapper()) {
        return PLATFORM.Ios
    } else if (isRunningInsideWrapper()) {
        return PLATFORM.Android
    }

    return PLATFORM.Desktop
}

export const getSitePlatformAmplitude = () => {
    if (isMobile) {
        if (isIosWrapper()) {
            return PLATFORM.Ios.toLowerCase()
        } else if (window?.AndroidWrapper?.isWrapper?.()) {
            return PLATFORM.Android.toLowerCase()
        }

        return PLATFORM.MobileWeb.toLowerCase()
    }

    return PLATFORM.Desktop.toLowerCase()
}

export const getSiteVersionAmplitude = () => {
    if (isMobile) {
        if (isRunningInsideWrapper()) {
            return PLATFORM.Native.toLowerCase()
        }

        return PLATFORM.MobileWeb.toLowerCase()
    }

    return PLATFORM.Desktop.toLowerCase()
}
