import { h } from 'preact';
import ArrowRight from '../../../../../../assets/icons/GameWindow/ArrowRight';
require(`./styles/${process.env.APPLICATION}/style.scss`);

interface CardProps {
    title: string;
    action: () => void;
    deviceType: string;
    url: string;
}

const Card = ({ action, title, deviceType, url }: CardProps) => {
    return (
        <a className={`card-container ${deviceType}`} onClick={action} rel="noreferrer" target="_blank" href={url}>
            <div className={`card-content ${deviceType}`}>
                <p>{title}</p>
                <ArrowRight mobile={deviceType === 'portrait' || deviceType === 'landscape'} />
            </div>
        </a>
    );
};

export default Card;
