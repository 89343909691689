import axios from 'axios'
import config from '../../../config/config'
import { HEADERS } from '../constants'
import sessionExpirationRequestInterceptor from './interceptors/sessionExpirationRequestInterceptor'

const baseURL = config.baseURL

const AWAxios = axios.create({
    baseURL,
    useCredentials: true,
    headers: {
        [HEADERS.CACHE_CONTROL]: 'no-store, no-cache'
    }
})

sessionExpirationRequestInterceptor(AWAxios)

export default AWAxios
