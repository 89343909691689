export const GEOCOMPLY_PLC_LOCATION_FETCH = 'GEOCOMPLY_PLC_LOCATION_FETCH'

export { default as fetchLocation} from './fetch-location-factory'

export const reducer = (state, action) => {
    return {
        ...state,
        geoPacket: action.payload,
        loading: true
    }
}
