export const GEOCOMPLY_PLC_CLEAR_ERROR = 'GEOCOMPLY_PLC_CLEAR_ERROR'

// Action Creators
export const plcClearError = () => dispatch => {
    dispatch({
        type: GEOCOMPLY_PLC_CLEAR_ERROR,
        payload: null
    })
}

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        error: action.payload,
        loading: false
    }
}
