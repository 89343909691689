import { h } from 'preact'

const PointsLoader = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        height={30}
        viewBox="0 0 128 32"
        {...props}
    >
        <circle fill="#2989de" r={11} transform="translate(16 16)">
            <animateTransform
                attributeName="transform"
                type="scale"
                additive="sum"
                values="1;1.42;1;1;1;1;1;1;1;1"
                dur="1350ms"
                repeatCount="indefinite"
            />
        </circle>
        <circle fill="#2989de" r={11} transform="translate(64 16)">
            <animateTransform
                attributeName="transform"
                type="scale"
                additive="sum"
                values="1;1;1;1;1.42;1;1;1;1;1"
                dur="1350ms"
                repeatCount="indefinite"
            />
        </circle>
        <circle fill="#2989de" r={11} transform="translate(112 16)">
            <animateTransform
                attributeName="transform"
                type="scale"
                additive="sum"
                values="1;1;1;1;1;1;1;1.42;1;1"
                dur="1350ms"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
)

export default PointsLoader
