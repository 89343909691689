import { types } from '../../types'

export const initialState = {
    isValid: false,
}

const regionValidatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REGION_VALIDATION_SUCCESS:
            return {
                ...state,
                isValid: true
            }
        case types.REGION_VALIDATION_FAILED:
            return {
                ...state,
                isValid: false
            }
        default: {
            return state
        }
    }
}

export default regionValidatorReducer
