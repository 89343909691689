import { types } from '../../types';

const initialState = {
    experimentsLoaded: false,
    experiments: {
        swjEnabled: false,
        geocomplyEnabled: null
    }
};

const ExperimentsMap = new Map([
    ['site-wide-jackpots', 'swjEnabled'],
    ['geocomply_enable', 'geocomplyEnabled']
]);

const mapExperiments = fetchedExperiment => {
    if (typeof fetchedExperiment !== 'object') {
        return initialState.experiments;
    }
    const mappedExperiments = Object?.keys(fetchedExperiment)?.reduce(
        (acc, val) => {
            if (ExperimentsMap.has(val)) {
                return {
                    ...acc,
                    [ExperimentsMap.get(val)]: fetchedExperiment[val].value
                };
            }
            return acc;
        },
        { ...initialState.experiments }
    );
    return {
        ...mappedExperiments,
        geocomplyEnabled: mappedExperiments.geocomplyEnabled ?? 'off'
    };
};

export const amplitudeExperimentsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_AMPLITUDE_EXPERIMENTS:
            return {
                ...state,
                experiments: mapExperiments(payload)
            };
        case types.SET_AMPLITUDE_EXPERIMENTS_LOADED:
            return {
                ...state,
                experimentsLoaded: true
            };
        default:
            return state;
    }
};
