import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IGameLaunchInit {
    RGI: string;
    brand: string;
    platform: string;
    gameProvider: string;
    gameName: string;
    gameId: string;
    providerRef: string;
}

export const ddActionGameLaunchInit = ({ ...context }: IGameLaunchInit, use_case: IddUseCases): IddRumAction => ({
    name: 'GAME_LAUNCH_INIT',
    description: 'Any game launch',
    context,
    use_case
});
