import { PLATFORM } from '../../constants/game-constants'

import useGeocomplyPlcXsell from './use-geocomply-plc-xsell'
import useGeocomplyPlcAndroid from './use-geocomply-plc-android'
import VoidService from '../../helpers/void-service'

export const useGeocomplyPlcByPlatformMap = {
    [PLATFORM.Desktop]: VoidService,
    [PLATFORM.Android]: useGeocomplyPlcAndroid,
    [PLATFORM.Xsell]: useGeocomplyPlcXsell,
    [PLATFORM.Ios]: useGeocomplyPlcXsell
}

/**
 * useGeocomplyPlc factory by platform context
 *
 * @param {string} platform
 * @returns {function} current useGeocomplyPlc hook
 */
export default function useGeocomplyPlcFactory(platform) {
    return useGeocomplyPlcByPlatformMap[platform]
}
