import { h } from 'preact'
import { useTranslation } from 'react-i18next'

import { useAuthenticated } from '../../common/hooks'
import { getRegion } from '../../common/helpers/cookies'
import Map from '../../assets/icons/Map/map'
import DownloadPluginSteps from '../../components/DownloadPluginSteps/DownloadPluginSteps'

require(`./styles/${process.env.APPLICATION}/style.scss`)

const DownloadPlugin = () => {
    const { t } = useTranslation()
    const region = getRegion()?.toLowerCase()
    useAuthenticated()

    const regulator = t(`regulators.${region}`)
    const regionText = t(`regions.${region}`)

    const confirmDownload = t('downloadPlugin.confirmLocation', {
        regulator,
        region: regionText
    })

    //todo confirmLocation should have dynamic region?
    return (
        <div className="download-plugin-wrapper">
            <div className="download-plugin-content">
                <Map />
                <div className="download-plugin-instructions">
                    <h1 className="title">{t('downloadPlugin.instructionTitle')}</h1>
                    <p>{confirmDownload}</p>
                    <p>{t('downloadPlugin.pluginInstall')}</p>
                    <p>{t('downloadPlugin.noNeedConnected')}</p>
                </div>
                <DownloadPluginSteps />
            </div>
        </div>
    )
}

export default DownloadPlugin
