import { h } from 'preact'
import { useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux'
import { types } from '../../../redux/types'
import IncentiveGames from '../providers/IncentiveGames/incentive-games'
import { ddRumAction } from '../../../common/helpers/datadog-wrapper';
import { ddActionRmLocationVerified } from '../../../common/datadog/reward-machine/rm-location-verified';
import { DD_USE_CASES } from '../../../common/datadog/enums';

const GameLauncher = () => {
    const dispatch = useDispatch()

    const locationVerified = useSelector(state => state?.geocomply?.location?.verified)

    useEffect(() => {
        if (locationVerified) {
            ddRumAction(ddActionRmLocationVerified({ locationVerified }, DD_USE_CASES.RM_GAME))
            dispatch({
                type: types.GAME_LAUNCHER_RECEIVED,
                payload: {data: {data: true}}
            })
        }
    }, [locationVerified])

    return locationVerified && (
        <div>
            <IncentiveGames />
        </div>
    )
}

export default GameLauncher
