export const GAME_CLIENT_ERRORS = {
    USER_IS_NOT_ELIGIBLE: 'USER_IS_NOT_ELIGIBLE',
    NO_VALID_GEO_APPROVAL: 'NO_VALID_GEO_APPROVAL',
    USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS:
      'USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS',
    GENERIC_ERROR: 'GENERIC_ERROR',
} as const;
  
export type GameClientErrorType = 
    typeof GAME_CLIENT_ERRORS[keyof typeof GAME_CLIENT_ERRORS];
  
interface ErrorMessages {
    errorTitle: string;
    errorMessage: string;
    actionTitle: string;
}
  
export type GameClientErrors = {
    [key in Exclude<GameClientErrorType,'USER_HAS_NOT_ACCEPTED_TERMS_AND_CONDITIONS'>]: ErrorMessages;
};