import { useContext, useEffect, useMemo, useState} from 'preact/hooks';
import { Dashboard } from './Dashboard';
import { getIconSize } from '../../../../../common/helpers/get-icon-size';
import GameHelp from '../../GameHelp/GameHelp';
import MenuTitle from './MenuTitle';
import { GameWindowContext } from '../../../GameWindow';

export const MenuContent = ({ dashboardCardWidth, menuItems, setActive, activeItem }) => {
    const [initialized, setInitialized] = useState(false);

    const { toggleHelp, togglePaytable, deviceType } = useContext(GameWindowContext);

    const menuContentMapper = useMemo(
        () => ({
            dashboard: <Dashboard dashboardCardWidth={dashboardCardWidth} />,
            game_help: <GameHelp toggleHelp={toggleHelp} togglePaytable={togglePaytable} />,
            promotions: 'Promotions content',
            notifications: 'Notifications content'
        }),
        [dashboardCardWidth, toggleHelp, togglePaytable]
    );
    const menuContent = useMemo(
        () =>
            menuItems
                .map(item => {
                    const mappedComponent = item?.type in menuContentMapper ? menuContentMapper[item.type] : undefined;
                    if (mappedComponent) {
                        return {
                            title: item.title,
                            icon: item.type,
                            content: mappedComponent
                        };
                    }
                })
                .filter(item => !!item),

        [menuItems, menuContentMapper]
    );

    useEffect(() => {
        if (menuContent && !initialized) {
            setActive(menuContent[0]);
        }
        setInitialized(true);
    }, [initialized, menuContent, setActive]);

    return menuContent.map(item => {
        return (
            <div
                className="nav-item-container"
                onClick={() => {
                    setActive(item);
                }}
                key={item.title}
            >
                <MenuTitle
                    highlight={activeItem.title === item.title}
                    title={item.title}
                    icon={item.icon}
                    size={getIconSize(deviceType)}
                />
            </div>
        );
    });
};
