import '../../styles/default.scss';

type ProgressBarProps = {
    progress: string;
    loadingText: string;
};

export const ProgressBar = ({ progress, loadingText }: ProgressBarProps) => {
    return (
        <div className="progress-container">
            <div className="progress-bar">
                <div className="progress-bar-fill" style={{ width: `${progress}%` }}>
                    <div className="percentage">{progress}%</div>
                </div>
            </div>
            <p className="loading-text">{loadingText}</p>
        </div>
    );
};
