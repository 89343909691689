const XButton = ({color, size, strokeWidth}) => {
    const stroke = {
        gray: "#1F375B",
        white: "#fff"
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
            <path
                d="M18.75 6.25L6.25 18.75"
                stroke={stroke[color]}
                stroke-width={strokeWidth}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.25 6.25L18.75 18.75"
                stroke={stroke[color]}
                stroke-width={strokeWidth}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default XButton;
