import React from 'react';
import { PositionType } from '../helpers/showoff.types';

const Overlay = (props: { position: PositionType; style: React.CSSProperties }) => {
    const { position } = props;

    return <div className={`showoff-overlay ${position}`} {...props} />;
};

export const CommonOverlay = ({ offsetTop, offsetLeft, height, width }) => {
    return (
        <>
            <Overlay
                position={'top'}
                style={{
                    height: offsetTop >= 0 ? `${offsetTop}px` : ''
                }}
            />
            <Overlay
                position={'right'}
                style={{
                    top: offsetTop >= 0 ? `${offsetTop}px` : '',
                    height: height ? `${height}px` : '',
                    left: offsetLeft >= 0 && width ? `${offsetLeft + width}px` : ''
                }}
            />
            <Overlay
                position={'bottom'}
                style={{
                    top: offsetTop >= 0 && height ? `${offsetTop + height}px` : ''
                }}
            />
            <Overlay
                position={'left'}
                style={{
                    top: offsetTop >= 0 ? `${offsetTop}px` : '',
                    height: height ? `${height}px` : '',
                    width: offsetLeft >= 0 ? `${offsetLeft}px` : ''
                }}
            />
        </>
    );
};
