import { fetchConnectionInfo } from '../geo-license'

let updateIpCheckTimerId = null


const setFetchConnectionTimer = (dispatch, time) => {
    return setTimeout(() => {
        clearTimeout(updateIpCheckTimerId)

        //we need a new license (not renewed) since it's a different IP
        dispatch(fetchConnectionInfo())
    }, time)
}
export const scheduleIpCheck = () => async (dispatch) => {
    console.warn('scheduleIpCheck()')

    if (navigator.connection) {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection

        connection.addEventListener('change', () => {
            // since a network change triggers the listener multiple times (major speed changes also trigger it) and
            // because we need to make the call after the network is on and stable again, we add a 10 sec buffer
            clearTimeout(updateIpCheckTimerId)
            updateIpCheckTimerId = setFetchConnectionTimer(dispatch, 10000)
        })
    } else {
        clearTimeout(updateIpCheckTimerId)
        updateIpCheckTimerId = setFetchConnectionTimer(dispatch, 30000)
    }
}
