import { h } from 'preact';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Clock from '../../assets/icons/GameWindow/Clock';
import { currentTimeSelector, gameTimeSelector, sessionTimeSelector } from '../../selectors/game-window';
import { SHOWOFF_ELEMENTS } from '../../common/hooks/use-showoff/useShowoff';
import LogoIcon from '../../assets/icons/GameWindow/LogoIcon';

interface TimersProps {
    isPortrait: boolean;
    menu: boolean;
    isLandscape: boolean;
}

require(`./styles/${process.env.APPLICATION}/style.scss`);

function Timers({ isPortrait, menu, isLandscape }: TimersProps) {
    const { t } = useTranslation();

    const gameTime = useSelector(gameTimeSelector);
    const currentTime = useSelector(currentTimeSelector);
    const sessionTime = useSelector(sessionTimeSelector);

    return (
        <div className={`timer-container ${menu ? 'menu' : ''} ${isLandscape ? 'landscape': 'portrait'}`} data-showoff={SHOWOFF_ELEMENTS.TIMERS}>
        {menu ? (
            <div className="icon-container">
                <Clock />
            </div>
        ) : (
            !isPortrait &&
                <div className="icon-container">
                    <LogoIcon />
                </div>
        )}
            <div className={`timer-column ${isPortrait ? 'portrait' : ''}`}>
                <div className="timer-row">
                    <span className="timer-label">{t('gameWindow.current')}</span>
                    <span className="timer-value">{currentTime}</span>
                </div>
                <div className="timer-row">
                    <span className="timer-label">{t('gameWindow.session')}</span>
                    <span className="timer-value">{sessionTime}</span>
                </div>
                <div className="timer-row">
                    <span className="timer-label">{t('gameWindow.game')}</span>
                    <span className="timer-value">{gameTime}</span>
                </div>
            </div>
        </div>
    );
}

export default Timers;
