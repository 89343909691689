import { useCallback, useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { showExitPopup } from '../../redux/actions/exit-popup';

export const useNativeBack = () => {
    const dispatch = useDispatch();

    const backHandler = useCallback(() => dispatch(showExitPopup()), [dispatch]);

    useEffect(() => {
        window.addEventListener('onBackPressed', backHandler);

        return () => window.removeEventListener('onBackPressed', backHandler);
    }, [backHandler]);
};
