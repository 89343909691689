const SoundOn = props => {
    return (
        <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.2917 1.04169L6.25002 5.87502H1.41669V13.125H6.25002L12.2917 17.9584V1.04169Z"
                stroke="white"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M22.0429 0.957092C24.3082 3.22305 25.5807 6.29594 25.5807 9.50001C25.5807 12.7041 24.3082 15.777 22.0429 18.0429M17.7775 5.22251C18.9101 6.35549 19.5464 7.89193 19.5464 9.49397C19.5464 11.096 18.9101 12.6324 17.7775 13.7654"
                stroke="white"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default SoundOn;
