import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IVerifyLocationSucceded {
    tokenDetails: Record<string, unknown>;
}

export const ddActionVerifyLocationSucceeded = (
    { ...context }: IVerifyLocationSucceded,
    use_case: IddUseCases
): IddRumAction => ({
    name: '[VERIFY_LOCATION] verify location succeeded',
    description: 'Verify location succedded',
    context,
    use_case
});
