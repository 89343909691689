import { SocialProofConsumer } from "./social-proof-consumer";
import {
  WinFrequencyOptions,
  WinFrequencyParams,
  WinFrequencyResponse,
  WinFrequencyTimeInterval,
} from "./types";

/**
 * Enables the consumption of the win frequency though a polling mechanism.
 */
export class WinFrequency extends SocialProofConsumer<WinFrequencyResponse> {
  private timeInterval: WinFrequencyTimeInterval;

  protected readonly endpoint = "/jackpot/api/v1/winFrequency";
  constructor({
    host,
    region,
    jackpotIds,
    interval,
    timeInterval,
    callback,
    error,
  }: WinFrequencyOptions) {
    super({ host, region, jackpotIds, interval, callback, error });
    this.timeInterval = timeInterval;
  }

  protected get startDate(): string {
    if (this.timeInterval === "last24h") {
      return new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();
    }
    return this.timeInterval.startDate;
  }

  protected get endDate(): string {
    if (this.timeInterval === "last24h") {
      return new Date().toISOString();
    }
    return this.timeInterval.endDate;
  }

  protected getParams(): WinFrequencyParams {
    return {
      jackpotIds: this.jackpotIds.join(","),
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
}
