export const GEOCOMPLY_PLC_CONNECT_FAILED = 'GEOCOMPLY_PLC_CONNECT_FAILED'

// Action Creators
export const plcInstalled = installed => ({
    type: GEOCOMPLY_PLC_CONNECT_FAILED,
    payload: installed
})

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        installed: action.payload,
        loading: false
    }
}
