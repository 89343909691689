import { useEffect, useState, useLayoutEffect, useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { locationVerified } from '../../../redux/geo-comply/location/actions/location-verified';
import { getGeocomplyIpCookie, getSessionId, getUserId, getWrapperCookies } from '../../helpers/cookies';
import { experimentsSelector } from '../../../selectors/amplitude-experiments';

const useGeolocationVerifyXsell = () => {
    const dispatch = useDispatch();
    const geoPacketValidated = useSelector(state => state?.plc?.validated);
    const [geocomplyIpAddress, setGeocomplyIpAddress] = useState(getGeocomplyIpCookie());
    const [sessionId, setSessionId] = useState(Number(getSessionId()));
    const [userId, setUserId] = useState(getUserId());
    const { isNewQuickLaunchFlow } = getWrapperCookies();
    const amplitudeExperiments = useSelector(experimentsSelector);

    useEffect(() => {
        const interval = setInterval(() => {
            setGeocomplyIpAddress(getGeocomplyIpCookie())
            setSessionId(Number(getSessionId()))
            setUserId(getUserId())
        }, 1000)
        return () => clearInterval(interval)
    }, []);

    const handleGeoValidationDone = useCallback(() => {
        dispatch(
            locationVerified({
                tokenDetails: {
                    result: true,
                    verified: true,
                    ip_address: geocomplyIpAddress ? geocomplyIpAddress : '10.123.0.55', // TODO: remove hardcoded ip,
                    session_id: sessionId,
                    user_id: userId,
                },
                geocomplyEnabled: amplitudeExperiments?.geocomplyEnabled === 'on'
            })
        );
    }, [dispatch, sessionId, userId, geocomplyIpAddress]);

    useLayoutEffect(() => {
        if (!geoPacketValidated) {
            if (isNewQuickLaunchFlow === 'true') {
                window.addEventListener('sportsbookGeoValidationDone', handleGeoValidationDone);
            } else {
                handleGeoValidationDone();
            }
        }

        return () => window.removeEventListener('sportsbookGeoValidationDone', handleGeoValidationDone);
    }, [handleGeoValidationDone, isNewQuickLaunchFlow, geoPacketValidated]);
};

export default useGeolocationVerifyXsell;
