import { DI_SERVICE } from '../constants'
import { register } from '../utils'

import useGeolocationVerify from '../../common/hooks/use-geolocation-verify'

const registerUseGeolocationVerify = () => register(
    DI_SERVICE.useGeolocationVerify,
    [DI_SERVICE.APP_PLATFORM]
)(useGeolocationVerify)

export default registerUseGeolocationVerify
