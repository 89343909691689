import { useDispatch, useSelector } from 'react-redux';
import { closeMenu, openMenu } from '../../../../../redux/actions/display-menu';
import XButton from '../../../../../assets/icons/GameWindow/XButton';
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { SHOWOFF_ELEMENTS } from '../../../../../common/hooks/use-showoff/useShowoff';
import { useLogTracking } from '../../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../../common/hooks/use-user-tracking/types';
import * as amplitude from '@amplitude/analytics-browser';

const MenuButton = ({ isPortrait }) => {
    const dispatch = useDispatch();
    const { logTrackingComponent } = useLogTracking();

    const isMenuOpen = useSelector(state => state?.displayMenu?.displayMenu);
    const isSpinInProgress = useSelector(state => state?.gameWindow?.spinInProgress);
    const gameLoaded = useSelector(state => state?.gameWindow?.gameLoaded);

    const canOpen = useMemo(() => !isSpinInProgress && gameLoaded, [gameLoaded, isSpinInProgress])

    const handleOpenMenu = useCallback(() => {
        if (canOpen) {
            dispatch(openMenu());
        }
    }, [canOpen, dispatch]);

    const size = isPortrait ? '34' : '44';

    useEffect(() => {
        if (gameLoaded) {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: 'Game Loaded',
                [getAmplitudeKey('MODULE')]: MODULES.IN_GAME,
                [getAmplitudeKey('ZONE_NAME')]: ZONES.IN_GAME,
            });

            amplitude.remove('addGLToEventType');
        }
    }, [gameLoaded, logTrackingComponent]);

    return (
        <div className={`menu-button ${canOpen ? 'pointer' : ''}`}>
            {isMenuOpen ? (
                <div className="close-icon" onClick={() => dispatch(closeMenu())}>
                    <XButton color="white" size={size} strokeWidth="2" />
                </div>
            ) : (
                <div
                    className={`open-icon ${isPortrait && 'portrait'} ${!canOpen && 'spin'}`}
                    onClick={handleOpenMenu}
                    data-showoff={SHOWOFF_ELEMENTS.MENU_BUTTON}
                    data-showoff-notification={'false'}
                />
            )}
        </div>
    );
};

export default MenuButton;
