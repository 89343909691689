export const MenuContentWrapper = ({ children, className }) => ( // carouselHeight, deviceType TODO: TBD if needed with carousel
    <div
        className={className}
        style={{
            display: 'flex',
            width: '100%',
            height: '100%'
            // height: deviceType === 'portrait' ? `${carouselHeight || 0}px` : '100%' TODO: TBD if needed with carousel
        }}
    >
        {children}
    </div>
);
