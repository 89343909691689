import { h } from 'preact'

require(`./styles/${process.env.APPLICATION}/style.scss`)

export function ModalWrapper(props) {
    return (
        <div class="modal-container">
            <div> </div>
            <div class="modal">
                <div class="modal__content">{props.children}</div>
            </div>
            {props.bottom}
        </div>
    )
}

export default ModalWrapper
