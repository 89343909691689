import { createReducer } from '../core/reducer-factory'

import { fetchStart, FETCH_START, reducer as fetchStartReducer } from './actions/fetch-start'
import { fetchEnd, FETCH_END, reducer as fetchEndReducer } from './actions/fetch-end'
import { fetchCancel, FETCH_CANCEL, reducer as fetchCancelReducer } from './actions/fetch-cancel'
import { fetchError, FETCH_ERROR, reducer as fetchErrorReducer } from './actions/fetch-error'

export const initialState = {
    requests: []
}

const actionHandlers = {
    [FETCH_START]: fetchStartReducer,
    [FETCH_END]: fetchEndReducer,
    [FETCH_CANCEL]: fetchCancelReducer,
    [FETCH_ERROR]: fetchErrorReducer,

    __default__: state => state
}

export { fetchStart, fetchEnd, fetchError, fetchCancel }

export const reducer = createReducer(actionHandlers, initialState)
