export const GAME_CLIENT_MESSAGES = {
    GAME_CLIENT_HANDSHAKE: 'GC/INITIALIZE_HANDSHAKE',
    GAME_LOADED: 'GC/SET_GAME_LOADED',
    SPIN_IN_PROGRESS: 'GC/SET_SPIN_IN_PROGRESS',
    AUDIO_ENABLED: 'GC/SET_AUDIO_ENABLED',
    GAME_LOAD_PROGRESS: 'GC/SET_GAME_LOAD_PROGRESS',
    GAME_LOAD_STARTED: 'GC/SET_GAMELOAD_STARTED',
    PROVIDER_ERROR: 'GC/SET_PROVIDER_ERROR',
    GAME_RESET: 'GC/RESET_GAME',
    CLOSE_GAME_LAUNCHER: 'GC/CLOSE_GAME_LAUNCHER'
};

type ToggleAudio = {
    type: 'GL/TOGGLE_AUDIO';
    payload: boolean;
};

type ReloadBalance = {
    type: 'GL/RELOAD_BALANCE';
    payload: string;
};

type DismissError = {
    type: 'GL/DISMISS_ERROR';
};

type TogglePaytable = {
    type: 'GL/TOGGLE_PAYTABLE';
};

type ToggleHelp = {
    type: 'GL/TOGGLE_HELP';
};

type ChooseDialog = {
    type: 'GL/FREE_SPINS_DIALOG';
    payload: number;
};

type AutoSpinStop = {
    type: 'GL/AUTO_SPIN_STOP';
};

type AutoSpinResume = {
    type: 'GL/AUTO_SPIN_RESUME';
};

export type ProxyMessage =
    | ToggleAudio
    | ReloadBalance
    | DismissError
    | TogglePaytable
    | ToggleHelp
    | ChooseDialog
    | AutoSpinStop
    | AutoSpinResume;
