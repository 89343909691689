import { useMemo } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { rgiSelector } from '../../../selectors/gameContext';
import { RGIS } from '../../constants';
import VoidService from '../../helpers/void-service';
import { useQueryParameterValidationGAN } from './use-query-parameter-validation-gan';
import { useQueryParameterValidationFGP } from './use-query-parameter-validation-fgp';

type RgiHookMap = {
    [rgi in keyof typeof RGIS[number]]: () => void;
};

export const useQueryParameterValidation = (gameUid?: string) => {
    const hookMapper: RgiHookMap = useMemo(
        () => ({
            [RGIS.GAN]: useQueryParameterValidationGAN,
            [RGIS.INCENTIVE_GAMES]: useQueryParameterValidationGAN,
            [RGIS.FGP]: useQueryParameterValidationFGP
        }),
        []
    );

    const rgi = useSelector(rgiSelector);

    if (gameUid) {
        return () => true;
    }

    return rgi in hookMapper ? hookMapper[rgi] : VoidService;
};
