import { useDispatch, useSelector } from 'react-redux';
import { CustomLoadingScreenProps } from '../types';
import { useEffect } from 'preact/hooks';
import { types } from '../../../../redux/types';
import { ProgressBar } from './progress/ProgressBar';
import { h } from 'preact';
import ExitButton from '../../../GameWindow/components/ExitButton/ExitButton';
import { ZONES } from '../../../../common/hooks/use-user-tracking/types';
import { isGameWindowDisabled } from '../../../../common/wrapper-bridge-mobile';
import { themeDataSelector } from '../../../../selectors/game-window';

export const CustomLoadingScreen = ({ counter, loadingText }: CustomLoadingScreenProps) => {
    const dispatch = useDispatch();

    const gameLoaded = useSelector(state => state?.['gameWindow']?.gameLoaded);
    const themeData = useSelector(themeDataSelector);

    useEffect(() => {
        if (gameLoaded) {
            dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
        }
    }, [dispatch, gameLoaded]);

    return (
        <div style={{ display: 'block' }}>
            <div
                className={`loading-screen willy_wonka ${themeData?.is_bottom_banner ? 'bottom' : 'full'}`}
                style={{ "background-image": `url(${themeData?.background_landscape.url})` }}
            >
                {!themeData?.is_bottom_banner && !isGameWindowDisabled() && (
                    <ExitButton
                        direction={'row'}
                        size={'2'}
                        style={{ position: 'absolute', top: 20, right: 20 }}
                        zoneName={ZONES.IN_GAME}
                    />
                )}
                <div className="center-piece">
                    {!themeData?.is_bottom_banner && <ProgressBar progress={`${counter}`} loadingText={loadingText} />}
                    <div className="willy-wonka-title">
                        <img src={themeData?.icon.url} />
                    </div>
                </div>
                <div className="oompa-loompa-container">
                    <img src={themeData?.oompa_loompa.url} />
                </div>
            </div>
        </div>
    );
};
