import { useCallback } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { useOrientation } from '../../../common/hooks/useOrientation';
import { ERROR_CTA_EVENTS } from '../../../game-window/contants/errors';
import { inGameErrorSelector } from '../../../selectors/game-window';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';
import { AMPLITUDE_ERROR_EVENTS, MODULES, ZONES, getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';

export const GenericExitButton = ({ buttonMessage, exitGame, buttonStyle }) => {
    const { deviceType } = useOrientation();
    const { errorType } = useSelector(inGameErrorSelector);
    const { logTrackingComponent } = useLogTracking();

    const handleExitButton = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_CLICKED,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
            [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
            [getAmplitudeKey('ERROR_TYPE')]: errorType,
            [getAmplitudeKey('LINK_TEXT')]: ERROR_CTA_EVENTS.EXIT_TOLOBBY,
            [getAmplitudeKey('LINK_URL')]: undefined
        });
        exitGame();
    }, [errorType, logTrackingComponent, exitGame]);

    return (
        <button onClick={handleExitButton} className={`exit-button ${deviceType} ${buttonStyle}`}>
            {buttonMessage}
        </button>
    );
};
