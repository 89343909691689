import { PLATFORM } from '../../../../common/constants/game-constants';
import AsyncVoidService from '../../../../common/helpers/async-void-service';
import getCurrentSessionDetailsWeb from './get-current-session-details-web';
import getCurrentSessionDetailsXsell from './get-current-session-details-xsell';

export const sessionDetailsByPlatformMap = {
    [PLATFORM.Desktop]: getCurrentSessionDetailsWeb,
    [PLATFORM.Android]: getCurrentSessionDetailsWeb,
    [PLATFORM.Xsell]: getCurrentSessionDetailsXsell,
    [PLATFORM.Ios]: AsyncVoidService
};

/**
 * get current session details by platform context
 *
 * @param {string} platform
 * @returns {(function(): function(*): Promise<void>)} get current session details action creator
 */
export default function getCurrentSessionDetailsFactory(platform) {
    return sessionDetailsByPlatformMap[platform];
}
