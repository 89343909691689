import { h } from 'preact'

const Info = () => (
    <svg className="info-icon"
         xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         viewBox="0 0 16 16"
    >
        <g fill="none" stroke="none" strokeWidth="1">
            <g fill="#FFF" transform="translate(-304 -8)">
                <g transform="translate(304 8)">
                    <g>
                        <path d="M8.5 6v6h-1V6h1zm0-2v1h-1V4h1z" />
                        <path d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 1a7 7 0 100 14A7 7 0 008 1z" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Info
