import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../../common/helpers/format-number';
import { showDepositButtonSelector, showWalletSelector, depositLinkSelector } from '../../../../selectors/game-window';
import { AddFundsButton } from '../AddFundsButton/AddFundsButton';
import { ADD_FUNDS_ICON_SIZES } from '../../../../common/constants';

const WalletBalance = ({ deviceType }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const fundsData = useSelector(state =>
        state.balance?.loadingGetBalance ? 'Loading...' : state.balance?.playableBalance
    );
    const showDepositButton = useSelector(showDepositButtonSelector);
    const depositLink = useSelector(depositLinkSelector);
    const showWallet = useSelector(showWalletSelector);
    const { t } = useTranslation();

    const depositString = `${t('gameWindow.deposit')}`;
    const addFundsEnabled = showDepositButton && depositLink;

    return (
        <div className="wallet-balance">
            {showWallet && (
                <div className="wallet-balance-container">
                    <span>{`$${formatNumber(fundsData)}`}</span>
                    {showDepositButton && depositLink && (
                        <div className="wallet-container menu-button">
                            <span className={deviceType}>{depositString}</span>
                        </div>
                    )}
                </div>
            )}
            { addFundsEnabled && (
                <AddFundsButton
                    size={ADD_FUNDS_ICON_SIZES.HEADER}
                    deviceType={deviceType}
                    depositLink={depositLink}
                    clickedLocation="header"
                    sendDDEvent={false}
                />
            )}
        </div>
    );
};

export default WalletBalance;
