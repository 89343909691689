import { createReducer } from '../../core/reducer-factory'
import { GEOCOMPLY_LICENSE_FETCH, reducer as licenseFetchReducer } from './actions/fetch-license'
import { GEOCOMPLY_LICENSE_FETCH_FAILED, reducer as licenseFetchFailedReducer } from './actions/fetch-license-failed'
import { GEOCOMPLY_LICENSE_RECEIVED, reducer as licenseReceivedReducer } from './actions/license-received'
import { GEOCOMPLY_LICENSE_CLEAR, reducer as licenseClearReducer } from './actions/license-clear'

export const initialState = {
    loading: false,
    error: null
}

const actionHandlers = {
    [GEOCOMPLY_LICENSE_FETCH]: licenseFetchReducer,
    [GEOCOMPLY_LICENSE_RECEIVED]: licenseReceivedReducer,
    [GEOCOMPLY_LICENSE_FETCH_FAILED]: licenseFetchFailedReducer,
    [GEOCOMPLY_LICENSE_CLEAR]: licenseClearReducer,

    __default__: state => state
}

export { fetchLicense } from './actions/fetch-license'
export { fetchNewLicense } from './actions/fetch-new-license'
export { fetchLicenseFailed } from './actions/fetch-license-failed'
export { licenseReceived } from './actions/license-received'
export { licenseClear } from './actions/license-clear'
export { fetchConnectionInfo } from './actions/fetch-connection-info'

export const reducer = createReducer(actionHandlers, initialState)
