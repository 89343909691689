import { h } from 'preact'
import { useTranslation } from 'react-i18next'

import { getRegion } from '../../../../common/helpers/cookies'

require(`./styles/${process.env.APPLICATION}/style.scss`);

const FooterNote = () => {
    const region = getRegion()
    const { t } = useTranslation()

    return <div class="footer-note">
        <p>{t('geocomply.footerNote1')}{t(`regions.${region}`)}.</p>
        <p>{t('geocomply.footerNote2')}</p>
    </div>
}

export default FooterNote
