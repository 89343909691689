const ArrowLeftErrorIcon = () => {
    return (
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2597 20.7285C11.6956 20.2927 11.6956 19.5841 11.2597 19.1482L3.10987 10.9983L11.2597 2.84843C11.6956 2.41254 11.6956 1.70396 11.2597 1.26807C10.8239 0.832192 10.1153 0.832192 9.67939 1.26807L0.738218 10.2092C0.519159 10.4283 0.411865 10.7144 0.411865 11.0005C0.411865 11.2867 0.521395 11.5728 0.738218 11.7918L9.67939 20.733C10.1153 21.1644 10.8216 21.1644 11.2597 20.7285Z"
                fill="#445058"
            />
        </svg>
    );
};

export default ArrowLeftErrorIcon;
