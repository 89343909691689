import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

export const ddActionSendTrendingGames = (
    { ...context }: Record<string, unknown>,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'SEND_TRENDING_GAMES',
    description: 'Send trending games',
    context,
    use_case
});
