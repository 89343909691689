import { types } from '../../types'

export const initialState = {
    loadingGetBalance: false,
    errorGetBalance: null,
    playableBalance: '',
    casinoDeposits: '',
    casinoBonuses: '',
    sharedWinnings: ''
}

const balanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_BALANCE_REQUEST:
            return {
                ...state,
                loadingGetBalance: true
            }
        case types.GET_USER_BALANCE_RECEIVED:
            return {
                ...state,
                playableBalance: action.payload?.playableBalance,
                casinoDeposits: action.payload?.casinoDeposits,
                casinoBonuses: action.payload?.casinoBonuses,
                sharedWinnings: action.payload?.sharedWinnings,
                loadingGetBalance: false
            }
        case types.GET_USER_BALANCE_FAILED:
            return {
                ...state,
                errorGetBalance: action.payload,
                loadingGetBalance: false
            }
        default: {
            return state
        }
    }
}
export default balanceReducer
