import remove from 'lodash/remove'
export const FETCH_END = 'FETCH_END'

// Action Creators
export const fetchEnd = uid => ({
    type: FETCH_END,
    payload: uid
})

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        requests: remove(state.requests, item => item.key !== action.payload.key)
    }
}
