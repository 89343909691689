import { plcLocationClear } from '../../../geo-plc/actions/plc-location-clear'

export const GEOCOMPLY_LICENSE_CLEAR = 'GEOCOMPLY_LICENSE_CLEAR'

// Reducer
export const reducer = (state) => {
    return {
        ...state,
        license: null,
        loading: false
    }
}

// Action Creators
export const licenseClear = () => dispatch => {
    console.warn('LICENSE CLEARED')

    dispatch({ type: GEOCOMPLY_LICENSE_CLEAR })
    dispatch(plcLocationClear())
}
