import remove from 'lodash/remove'
export const FETCH_CANCEL = 'FETCH_CANCEL'

// Action Creators
export const fetchCancel = uid => ({
    type: FETCH_CANCEL,
    payload: uid
})

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        requests: remove(state.requests, item => item.key !== action.payload.key)
    }
}
