import round from 'lodash/round'
import moment from 'moment'
import { getLocationFetchBufferTime } from '../../../common/helpers/plc-helper'

import { diContainer } from '../../../dependency-injection/utils'
import { DI_SERVICE } from '../../../dependency-injection/constants'

export const GEOCOMPLY_PLC_SCHEDULE_LOCATION_FETCH = 'GEOCOMPLY_PLC_SCHEDULE_LOCATION_FETCH'

let geoLocationTimerId = null

export const scheduleFetchLocation = token => async (dispatch, getState) => {
    const geolocateIn = token.geolocate_in

    if (geolocateIn >= 30) {
        const fetchLocation = diContainer.get(DI_SERVICE.fetchLocation)
        const state = getState()
        const license = state?.geocomply?.license.license
        const sessionId = state?.session?.sessionId
        const userId = state?.session?.userId

        const expires = moment(token.expires)
        const dateTimeNow = moment.utc()
        const bufferTime = getLocationFetchBufferTime(geolocateIn)

        const scheduleTime = expires.add(-bufferTime, 'seconds').toDate()
        const scheduledIn = scheduleTime - dateTimeNow

        console.warn(`SCHEDULED CHECK LOCATION AT ${scheduleTime} - IN ${round(scheduledIn / 1000 / 60, 1)} minutes`)
        /* eslint-disable */
        clearTimeout(geoLocationTimerId)
        geoLocationTimerId = setTimeout(() => {
            clearTimeout(geoLocationTimerId)
            dispatch(fetchLocation(license, sessionId, userId))
        }, scheduledIn)
    }
}
