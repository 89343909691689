import { createReducer } from '../../core/reducer-factory';

import { GEOCOMPLY_LOCATION_VERIFY, reducer as geoLocationSubmitFetchReducer } from './actions/verify-location';
import { GEOCOMPLY_LOCATION_VERIFIED, reducer as locationVerifiedReducer } from './actions/location-verified';
import { GEOCOMPLY_VERIFY_NEW_LOCATION, reducer as verifyNewLocationReducer } from './actions/verify-new-location';
import {
    GEOCOMPLY_LOCATION_VERIFY_FAILED,
    reducer as geoLocationSubmitFailedReducer
} from './actions/verify-location-failed';
import {
    GEOCOMPLY_LOCATION_VERIFY_CLEAR_ERROR,
    reducer as locationVerifiedClearErrorReducer
} from './actions/verify-location-clear-error';
import {
    GEOCOMPLY_LOCATION_DETAILS_CLEAR,
    reducer as locationDetailsClearReducer
} from './actions/location-details-clear';

import { GEOCOMPLY_LOCATION_RETRY, reducer as locationRetryingReducer } from './actions/location-retrying';

export const initialState = {
    loading: false,
    verified: null,
    details: null,
    error: null,
    retrying: false
};

const actionHandlers = {
    [GEOCOMPLY_LOCATION_VERIFY]: geoLocationSubmitFetchReducer,
    [GEOCOMPLY_LOCATION_VERIFIED]: locationVerifiedReducer,
    [GEOCOMPLY_LOCATION_VERIFY_FAILED]: geoLocationSubmitFailedReducer,
    [GEOCOMPLY_LOCATION_VERIFY_CLEAR_ERROR]: locationVerifiedClearErrorReducer,
    [GEOCOMPLY_VERIFY_NEW_LOCATION]: verifyNewLocationReducer,
    [GEOCOMPLY_LOCATION_DETAILS_CLEAR]: locationDetailsClearReducer,
    [GEOCOMPLY_LOCATION_RETRY]: locationRetryingReducer,
    __default__: state => state
};

export { verifyLocation } from './actions/verify-location';
export { locationVerified } from './actions/location-verified';
export { verifyLocationFailed } from './actions/verify-location-failed';
export { verifyLocationClearError } from './actions/verify-location-clear-error';
export { verifyNewLocation } from './actions/verify-new-location';
export { locationDetailsClear } from './actions/location-details-clear';
export { setGeocomplyRetrying } from './actions/location-retrying';

export const reducer = createReducer(actionHandlers, initialState);
