import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IRmGameMount {
    gameURL: string;
}

export const ddActionRmGameMount = ({ ...context }: IRmGameMount, use_case: IddUseCases): IddRumAction => ({
    name: 'RM_GAME_MOUNT',
    description: 'RM game loaded from casino perspective',
    context,
    use_case
});
