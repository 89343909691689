export const setGameClientStatus = (payload: boolean): SetGameClientStatus => ({
    payload,
    type: 'SET_GAME_CLIENT_STATUS'
});

export const setGameClientHandshake = (payload: boolean): SetGameClientHandshake => ({
    payload,
    type: 'SET_GAME_CLIENT_HANDSHAKE'
});

interface SetGameClientStatus {
    type: 'SET_GAME_CLIENT_STATUS';
    payload: boolean;
}

interface SetGameClientHandshake {
    type: 'SET_GAME_CLIENT_HANDSHAKE';
    payload: boolean;
}
