// eslint-disable-next-line no-unused-vars
import { h, Fragment } from 'preact'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { dismissLauncher } from '../../../../common/helpers/redirect-flows'
import MapStop from '../../../../assets/icons/Map/map-stop'
import config from '../../../../../config/config'
import { isMobile } from 'react-device-detect'

const NotAllowed = () => {
    const { t } = useTranslation()
    const verified = useSelector(state => state?.geocomply?.location?.verified)

    const contactSupport = () => {
        if (isMobile) {
            window.location = config?.geoComply?.help
        } else {
            window.open(config?.geoComply?.help)
        }
    }

    return verified === false ? (
        <Fragment>
            <div className="modal__image">
                <MapStop />
            </div>

            <div className="modal__header">{t('geocomply.notAllowed.title')}</div>

            <div className="modal__body">
                <p>{t('geocomply.notAllowed.description')}</p>
            </div>
            <div className="modal__footer">
                <div className="modal__buttons">
                    <span className="btn btn--transparent" role="button" onClick={() => dismissLauncher()}>
                        {t('PLCNotInstalled.dismiss')}
                    </span>
                    <span className="btn btn__link btn--blue" role="button" onClick={() => contactSupport()}>
                        {t('geocomply.notAllowed.contactSupport')}
                    </span>
                </div>
            </div>
        </Fragment>
    ) : null
}

export default NotAllowed
