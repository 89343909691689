import { FanduelLogo } from './svgs/FanduelLogo';
import { MohegansunLogo } from './svgs/MohegansunLogo';

export const DefaultLogo = () => {
    if (process.env.BRAND === 'fanduel') {
        return <FanduelLogo />;
    }

    if (process.env.BRAND === 'mohegansun') {
        return <MohegansunLogo />;
    }
}
