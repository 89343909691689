import { h } from 'preact';
import Helmet from 'preact-helmet';
import { renderGtmNoScript, renderGtmScript } from './renderGtm';

const WindowTitleXsell = () => {

    const script = [
        { innerHTML: renderGtmScript() }
    ].map(scriptElem => ({
        type: 'text/javascript',
        ...scriptElem
    }));

    return <Helmet script={script} noscript={[{ innerHTML: renderGtmNoScript() }]} />;
};

export default WindowTitleXsell;
