import axios, { AxiosInstance } from "axios";

import { SocialProofOptions } from "./types";

export abstract class SocialProofConsumer<T> {
  protected jackpotIds: string[];
  protected abstract endpoint: string;

  private successCallback: (data: T | undefined) => void;
  private errorCallback: ((error: unknown) => void) | undefined;
  private consecutiveErrors = 0;
  private readonly maximumConsecutiveErrors = 5;
  private httpClient: AxiosInstance;
  private intervalRef: NodeJS.Timeout | undefined;
  private interval: number;

  constructor({
    host,
    region,
    jackpotIds,
    interval,
    callback,
    error,
  }: SocialProofOptions<T>) {
    this.httpClient = axios.create({
      baseURL: host,
      headers: {
        "x-region-code": region,
      },
    });

    this.jackpotIds = jackpotIds;
    this.successCallback = callback;
    this.errorCallback = error;
    this.interval = interval || 60 * 1000; // Defaults to 1 minute
  }

  async connect(): Promise<void> {
    this.successCallback(await this.getData());
    this.intervalRef = setInterval(async () => {
      this.successCallback(await this.getData());
    }, this.interval);
  }

  disconnect(): void {
    clearInterval(this.intervalRef);
  }

  protected async getData(): Promise<T | undefined> {
    try {
      const res = await this.httpClient.get<T>(this.endpoint, {
        params: this.getParams(),
      });
      this.consecutiveErrors = 0;
      return res.data;
    } catch (e) {
      this.consecutiveErrors++;
      if (this.consecutiveErrors >= this.maximumConsecutiveErrors) {
        this.disconnect();
      }

      if (this.errorCallback) {
        this.errorCallback(e);
      } else {
        throw e;
      }
    }
  }

  protected abstract getParams(): Record<string, string>;

  /*
    addJackpot(jackpotId: string): void
    addJackpot(jackpotIds: string[]): void
    addJackpot(jackpotIdOrIds: string | string[]): void {
        if (Array.isArray(jackpotIdOrIds)) {
            this.jackpotIds = [...this.jackpotIds, ...jackpotIdOrIds];
        } else {
            this.jackpotIds = [...this.jackpotIds, jackpotIdOrIds];
        }
    }

    removeJackpot(jackpotId: string): void
    removeJackpot(jackpotIds: string[]): void
    removeJackpot(jackpotIdOrIds: string | string[]): void {
        if (Array.isArray(jackpotIdOrIds)) {
            this.jackpotIds = this.jackpotIds.filter(id => !jackpotIdOrIds.includes(id));
        } else {
            this.jackpotIds = this.jackpotIds.filter(id => id !== jackpotIdOrIds);
        }
    }
     */
}
