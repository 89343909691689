import { useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useInject } from 'inversify-hooks';
import { scheduleFetchLocation } from '../../../redux/geo-plc/actions/schedule-fetch-location';
import { DI_SERVICE } from '../../../dependency-injection/constants';
import { rgiSelector } from '../../../selectors/gameContext';
import { RGIS } from '../../constants';

const useGeolocationTriggerAndroid = ({ connected }) => {
    const [fetchLocation] = useInject(DI_SERVICE.fetchLocation);
    const license = useSelector(state => state?.geocomply?.license.license);
    const sessionId = useSelector(state => state?.session?.sessionId);
    const userId = useSelector(state => state?.session?.userId);
    const geoPacket = useSelector(state => state?.plc?.geoPacket);
    const rgi = useSelector(rgiSelector);

    const locationDetails = useSelector(state => state?.geocomply?.location?.details);

    const dispatch = useDispatch();

    useEffect(() => {
        /* eslint-disable */
        // get location if we don't have it cached
        if (
            connected &&
            license &&
            sessionId &&
            userId &&
            !geoPacket &&
            !locationDetails &&
            rgi === RGIS.INCENTIVE_GAMES
        ) {
            dispatch(fetchLocation(license, sessionId, userId));
        }
    }, [connected, license, sessionId, userId, geoPacket, locationDetails, rgi]);

    useEffect(() => {
        // schedule to get a new location before previous expires
        if (connected && license && sessionId && userId && locationDetails && rgi === RGIS.INCENTIVE_GAMES) {
            dispatch(scheduleFetchLocation(locationDetails));
        }
    }, [connected, license, sessionId, userId, locationDetails, rgi]);
};

export default useGeolocationTriggerAndroid;
