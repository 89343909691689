import { showPopup } from '../../reducers/popup/popup'
import { POPUP_DISMISS_ACTIONS } from '../../reducers/popup/types'

export const showGeneralError = (data = null) => dispatch => {
    dispatch(showPopup({
        icon: true,
        title: data ?? 'An error has occurred. Please try again!',
        description: '',
        buttons: {
            dismissAction: {
                title: data?.includes('CODE_414') ? 'Check out new T&C\'s' : 'Close',
                handler: POPUP_DISMISS_ACTIONS.DISMISS_POPUP,
                handlerParams: data
            }
        }
    }))
}
