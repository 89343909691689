import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IRmCustomerPackageReceived {
    data: Record<string, unknown>;
}

export const ddActionRmCustomerPackageReceived = (
    { ...context }: IRmCustomerPackageReceived,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'RM_CUSTOMER_PACKAGE_RECEIVED',
    description: 'RM customer package received from customer-data',
    context,
    use_case
});
