import { useEffect, useMemo, useContext, useState, useCallback, useRef, useLayoutEffect} from 'preact/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.min.css';

import { dashboardItemsSelector } from '../../../../../selectors/game-window/content';

import MyAccount from '../../MyAccount/MyAccount';
import RecentlyPlayed from '../../RecentlyPlayed/RecentlyPlayed';
import { NavigationButton } from './NavigationButton';
import { GameWindowContext } from '../../../GameWindow';
import { dashboardSlides } from '../constants';
import { createRef } from 'preact';
import { useLogTracking } from '../../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../../common/hooks/use-user-tracking/types';

const { ACCOUNT, RECENTLY_PLAYED } = dashboardSlides;

export const Dashboard = ({ dashboardCardWidth }) => {
    const { logTrackingComponent } = useLogTracking();
    const sendGtmMessage = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Zone Viewed',
            [getAmplitudeKey('MODULE')]: MODULES.IN_GAME,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD
        });
    }, [logTrackingComponent]);

    const slidesGap = 40;

    const { deviceType, setShowoffParams } = useContext(GameWindowContext);
    const dashboardItems = useSelector(dashboardItemsSelector);

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [sliderOffset, setSliderOffset] = useState(0);
    const [cardMaxWidth, setCardMaxWidth] = useState(500);
    const [navButtons, setNavButtons] = useState(1);
    const refs = useRef([createRef(), createRef()]);

    const handleResize = useCallback(() => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }, []);

    const checkNavButtonsVisibility = useCallback(() => {
        const first = document.querySelector('.swiper-slide:first-of-type');
        const last = document.querySelector('.swiper-slide:last-of-type');

        if (first.className.includes('active')) {
            setNavButtons(1);
        } else if (last.className.includes('active')) {
            setNavButtons(-1);
        } else {
            setNavButtons(0);
        }
    }, []);

    const dashboardItemsMapper = useMemo(
        () => ({
            [ACCOUNT]: <MyAccount width={dashboardCardWidth} />,
            [RECENTLY_PLAYED]: <RecentlyPlayed width={dashboardCardWidth} recalculateSize={handleResize} />
        }),
        [dashboardCardWidth]
    );

    useEffect(() => {
        if (dashboardItems && refs && setShowoffParams) {
            setShowoffParams(prev => ({
                ...prev,
                dashboardItems: dashboardItems.map((item, index) => ({ ...item, ref: refs.current[index] }))
            }));
        }
    }, [dashboardItems, setShowoffParams]);

    useEffect(() => {
        sendGtmMessage();
        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    useLayoutEffect(() => {
        setTimeout(() => {
            const sliderWrapper = document.querySelector('.swiper-wrapper');
            const swiperSlides = Array.from(document.querySelectorAll('.dashboard-wrapper .card-wrapper'));

            const sliderWrapperSize = () => {
                return deviceType === 'portrait' ? (sliderWrapper?.clientHeight) : (sliderWrapper?.clientWidth);

            }

            setCardMaxWidth(sliderWrapperSize());

            const totalScrollSize =
                swiperSlides.reduce((acc, val) => {
                    return acc + (deviceType === 'portrait' ? val.scrollHeight : val.scrollWidth);
                }, 0) +
                (swiperSlides.length - 1) * slidesGap;

            const isOverflowing =
                deviceType === 'portrait'
                    ? totalScrollSize > sliderWrapper?.clientHeight
                    : totalScrollSize > sliderWrapper?.clientWidth;

            setSliderOffset(isOverflowing ? sliderWrapperSize() / 2 - slidesGap : 0);
        }, 500);
    }, [deviceType, dimensions]);

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                zIndex: !!sliderOffset && deviceType === 'desktop' ? '' : 10
            }}
        >
            <NavigationButton
                direction={'prev'}
                visible={navButtons !== 1 && !!sliderOffset && deviceType === 'desktop'}
            />
            <Swiper
                slidesPerView={'auto'}
                slidesOffsetAfter={deviceType === 'desktop' ? sliderOffset : 20}
                spaceBetween={slidesGap}
                onTransitionEnd={({ isEnd, isBeginning }) => {
                    if (!(isEnd || isBeginning)) {
                        checkNavButtonsVisibility();
                    }
                }}
                observeParents
                onAfterInit={swiper => {
                    setShowoffParams && setShowoffParams(prev => ({ ...prev, swiperInstance: swiper }));
                }}
                mousewheel
                updateOnWindowResize
                onResize={checkNavButtonsVisibility}
                onReachEnd={() => setNavButtons(-1)}
                onReachBeginning={() => setNavButtons(1)}
                direction={deviceType === 'portrait' ? 'vertical' : 'horizontal'}
                modules={[Navigation, Mousewheel]}
                navigation={{
                    nextEl: '.carousel-button.next',
                    prevEl: '.carousel-button.prev'
                }}
                style={{ zIndex: 0, width: '100%' }}
            >
                {dashboardItems.map((item, index) => {
                    const content =
                        item.type in dashboardItemsMapper && item.show ? dashboardItemsMapper[item.type] : undefined;
                    return (
                        content && (
                            <SwiperSlide
                                key={item.type}
                                ref={refs.current[index]}
                                style={{
                                    maxWidth:
                                        deviceType === 'landscape' && item.type === RECENTLY_PLAYED
                                            ? '60%'
                                            : cardMaxWidth
                                }}
                            >
                                {content}
                            </SwiperSlide>
                        )
                    );
                })}
            </Swiper>
            <NavigationButton
                direction={'next'}
                visible={navButtons !== -1 && !!sliderOffset && deviceType === 'desktop'}
            />
        </div>
    );
};
