const PlusIcon = ({ isMobile, size, className }) => {
    return isMobile ? (
        <svg width={size} height={size} className={className} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path  className="fill-space"
                d="M0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5Z"
            />
            <path className="icon-space"
                d="M17 10.9118H12.2647V6.17647C12.2647 5.80306 11.9616 5.5 11.5882 5.5C11.2148 5.5 10.9118 5.80306 10.9118 6.17647V10.9118H6.17647C5.80306 10.9118 5.5 11.2148 5.5 11.5882C5.5 11.9616 5.80306 12.2647 6.17647 12.2647H10.9118V17C10.9118 17.3734 11.2148 17.6765 11.5882 17.6765C11.9616 17.6765 12.2647 17.3734 12.2647 17V12.2647H17C17.3734 12.2647 17.6765 11.9616 17.6765 11.5882C17.6765 11.2148 17.3734 10.9118 17 10.9118Z"
            />
        </svg>
    ) : (
        <svg width={size} height={size} className={className} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4024_42922)">
                <path className="fill-space"
                    d="M0 12.615C0 5.64933 5.64933 0 12.615 0C19.5807 0 25.23 5.64933 25.23 12.615C25.23 19.5807 19.5807 25.23 12.615 25.23C5.64933 25.23 0 19.5807 0 12.615Z"
                />
                <path className="icon-space"
                    d="M18.6483 11.9677H13.4487V6.77913C13.4487 6.37326 13.1196 6.0332 12.7028 6.0332C12.2859 6.0332 11.9568 6.36229 11.9568 6.77913V11.9787H6.77919C6.37332 11.9787 6.03326 12.3078 6.03326 12.7246C6.03326 13.1415 6.36235 13.4706 6.77919 13.4706H11.9788V18.6701C11.9788 19.076 12.3079 19.4161 12.7247 19.4161C13.1415 19.4161 13.4706 19.087 13.4706 18.6701V13.4706H18.6702C19.0761 13.4706 19.4161 13.1415 19.4161 12.7246C19.4161 12.3078 19.087 11.9787 18.6702 11.9787L18.6483 11.9677Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_4024_42922" className="icon-space" >
                    <rect width="25.23" height="25.23" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PlusIcon;
