const ArrowRight = ({ mobile }) =>
    mobile ? (
        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.204781 0.397972C-0.0682602 0.670972 -0.0682602 1.11477 0.204781 1.38777L5.30995 6.49217L0.204781 11.5966C-0.0682602 11.8696 -0.0682602 12.3134 0.204781 12.5864C0.477822 12.8594 0.921688 12.8594 1.19473 12.5864L6.79557 6.98637C6.93279 6.84917 7 6.66997 7 6.49077C7 6.31157 6.93139 6.13237 6.79557 5.99517L1.19473 0.395172C0.921688 0.124972 0.479222 0.124972 0.204781 0.397972Z"
                fill="white"
            />
        </svg>
    ) : (
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.606354 0.707962C0.242299 1.07196 0.242299 1.6637 0.606354 2.0277L7.41324 8.83356L0.606354 15.6394C0.242299 16.0034 0.242299 16.5952 0.606354 16.9592C0.970408 17.3232 1.56223 17.3232 1.92629 16.9592L9.39407 9.4925C9.57703 9.30956 9.66665 9.07063 9.66665 8.8317C9.66665 8.59276 9.57517 8.35383 9.39407 8.1709L1.92629 0.704229C1.56223 0.343962 0.972275 0.343962 0.606354 0.707962Z"
                fill="white"
            />
        </svg>
    );

export default ArrowRight;
