import { locationRequestUid } from '../../../../common/helpers/plc-helper'
import { requestWrapperGeoLocation } from '../../../../common/wrapper-bridge-mobile'
import { fetchStart } from '../../../fetch/fetch'
import { GEOCOMPLY_PLC_LOCATION_FETCH } from './index'

const fetchLocationAndroid = (license, sessionId, userId) => async dispatch => {
    console.info(`fetchLocation("${!!license}","${sessionId}","${userId}",)`)
    console.warn('FETCH LOCATION')
    dispatch(fetchStart(locationRequestUid))
    dispatch({ type: GEOCOMPLY_PLC_LOCATION_FETCH })
    requestWrapperGeoLocation(license, userId, sessionId)
}

export default fetchLocationAndroid
