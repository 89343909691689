import config from '../../../config/config'
import { getRegion } from './cookies'
import { getUserOS } from './platform'
import { FetchType, getRequestUid } from './fetch-helper'

export const locationRequestUid = getRequestUid(FetchType.Location)

export const getLicenseUrl = () => {
    const region = getRegion()?.toLowerCase()
    return `${config.geoComply.endPoints.license}/${region}/${config.geoComply.product}`
}

export const generateDownloadUrl = (userId, installerKey, version, userOS, isProduction) => {
    //TODO: this needs to be handled better by using "process.env.NODE_CONFIG_ENV"
    const prefix = isProduction ? 'https://' : 'https://stg-'
    const topLevelDomain = isProduction ? 'com' : 'net'

    const theOS = getUserOS()
    let downloadUrl = `${prefix}ums.geocomply.${topLevelDomain}/installer/url?id=${installerKey}&version=${version}`

    if (theOS) {
        downloadUrl += `&os=${theOS}`
    }

    if (userId) {
        downloadUrl += `&user_id=${userId}`
    }

    return downloadUrl
}
/**
 * For more details check "geocomply renew schedule interval calculation" section from /docs/geocomply.md
 */
export const getLocationFetchBufferTime = geolocateIn => {
    let bufferTime = 0
    const t = geolocateIn
    if (geolocateIn >= 1001) {
        bufferTime = 120
    } else if (geolocateIn < 100) {
        bufferTime = t * 0.3
    } else {
        bufferTime = t - (0.9 * t - 20.0)
    }

    return bufferTime
}
