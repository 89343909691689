require('reflect-metadata')

import { container } from 'inversify-hooks'
import { helpers as vanillaJsHelpers } from 'inversify-vanillajs-helpers'

const register = vanillaJsHelpers.register(container)
const registerConstantValue = vanillaJsHelpers.registerConstantValue(container)

export {
    container as diContainer,
    register,
    registerConstantValue
}
