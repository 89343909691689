
export const GEOCOMPLY_LOCATION_DETAILS_CLEAR = 'GEOCOMPLY_LOCATION_DETAILS_CLEAR'

// Reducer
export const reducer = (state) => {
    return {
        ...state,
        details: null,
        loading: false
    }
}

// Action Creators
export const locationDetailsClear = () => async dispatch => {
    console.warn('LOCATION DETAILS CLEAR')

    dispatch({type: GEOCOMPLY_LOCATION_DETAILS_CLEAR})
}
