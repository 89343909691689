import axios, { AxiosInstance } from "axios";

import { ErrorType } from "../types";
import { JackpotFeedInit, JackpotFeedResponse } from "./types";

export class JackpotFeed {
  httpClient: AxiosInstance | null = null;
  jackpotFeedData: JackpotFeedResponse[] = [];
  errorDisplayed = false;

  host = "";
  provider = "";
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  successCallback: (arg: JackpotFeedResponse[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  errorCallback: (error: ErrorType) => void = () => {};
  persistValue = false;
  timeout = 5000;

  async init({
    host,
    provider,
    successCallback,
    errorCallback,
    persistValue,
    timeout,
  }: JackpotFeedInit) {
    this.host = host;
    this.provider = provider;
    this.successCallback = successCallback;
    this.errorCallback = errorCallback;
    this.persistValue = persistValue;
    this.timeout = timeout;

    this.httpClient = axios.create({
      baseURL: host,
    });

    const interval = setInterval(async () => {
      this.successCallback(await this.getJackpotFeed());
    }, this.timeout);

    if (!this.persistValue) {
      clearInterval(interval);
    }

    const result = await this.getJackpotFeed();
    this.successCallback(result);

    return result;
  }

  resetSuccessCallback(callback: (arg: JackpotFeedResponse[]) => void) {
    this.successCallback = callback;
  }

  private async getJackpotFeed(): Promise<JackpotFeedResponse[]> {
    try {
      const res = await (this.httpClient as AxiosInstance).get("/jackpot/api/v2/jackpots", {
        headers: {
          "x-provider-id": this.provider
        }
      });

      if (res.status >= 200 && res.status < 300) {
        this.errorDisplayed = false;
        this.jackpotFeedData = res.data as JackpotFeedResponse[];
        return this.jackpotFeedData;
      } else {
        this.handleError();
      }
    } catch (e) {
      this.handleError();
    }

    if (this.jackpotFeedData.length > 1) {
      const firstData = this.jackpotFeedData[0] as JackpotFeedResponse;
      const nowTime = new Date();
      const fetchedTime = new Date(firstData.prevTime);

      const diff = (nowTime.getTime() - fetchedTime.getTime()) / 1000;

      if (diff < 30) {
        return this.jackpotFeedData;
      }
    }

    return [];
  };

  private handleError() {
    if (!this.errorDisplayed && !!this.errorCallback) {
      if (this.jackpotFeedData.length > 0) {
        const firstData = this.jackpotFeedData[0] as JackpotFeedResponse;
        const nowTime = new Date();
        const fetchedTime = new Date(firstData.prevTime);

        const diff = (nowTime.getTime() - fetchedTime.getTime()) / 1000;
        console.log({ diff });
        if (diff > 30) {
          this.displayError();
        }
      } else {
        this.displayError(true);
      }
    }
  }

  private displayError(errorAtInit = false) {
    this.errorCallback({
      reason: errorAtInit ? "SWJ-101" : "SWJ-102",
      message: "Failed to fetch jackpot feed",
    });
    this.errorDisplayed = true;
  }

}
