import { types } from '../../types'

export const initialState = {
    userContextData: null,
    loading: false,
    error: null
}

const userContextReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_CONTEXT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.GET_USER_CONTEXT_RECEIVED:
            return {
                ...state,
                userContextData: action.payload,
                loading: false,
                error: false
            }
        case types.GET_USER_CONTEXT__FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: {
            return state
        }
    }
}

export default userContextReducer
