import { AW_COOKIE } from '../constants';

/**
 * @param  {Record<string, unknown>} object
 */
export const removeConfidentialDataFromObject = object => {
    if (object === undefined) {
        return {};
    }

    const data = { ...object };

    const blacklist = [
        'email',
        'token',
        'firstName',
        'lastName',
        AW_COOKIE.LOGIN_TOKEN,
        'authToken',
        'Authorization',
        AW_COOKIE.AUTH_TOKEN
    ];

    for (const key of blacklist) {
        if (data[key] !== undefined) {
            data[key] = 'private';
        }
    }

    return data;
};
