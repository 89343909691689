import { h, Fragment } from 'preact';
import { useCallback } from 'preact/hooks';
import { openExternalWebview } from '../../../common/helpers/open-external-webview';
import { useOrientation } from '../../../common/hooks/useOrientation';
import { inGameErrorSelector } from '../../../selectors/game-window';
import { useSelector } from 'react-redux';
import { getErrorType, ERROR_CTA_EVENTS } from '../../../game-window/contants/errors';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';
import { AMPLITUDE_ERROR_EVENTS, MODULES, ZONES, getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';
import { isRunningInsideWrapper } from '../../../common/wrapper-bridge-mobile';

export const ContactSupportButton = ({ exitGame, supportUrl, buttonMessage, buttonStyle}) => {
    const { deviceType } = useOrientation();
    const { errorCode } = useSelector(inGameErrorSelector);
    const { errorType } = getErrorType(errorCode);
    const { logTrackingComponent } = useLogTracking();

    const handleContactSupportButton = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_CLICKED,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
            [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
            [getAmplitudeKey('ERROR_TYPE')]: errorType,
            [getAmplitudeKey('LINK_TEXT')]: ERROR_CTA_EVENTS.LEAVE,
            [getAmplitudeKey('LINK_URL')]: supportUrl
        });
        if (isRunningInsideWrapper()) {
            exitGame(supportUrl);
            openExternalWebview(supportUrl);
        } else {
            window.location.replace(supportUrl);
        }
    }, [errorType, logTrackingComponent, exitGame, supportUrl]);

    return (
        <Fragment>
            {supportUrl && (
                <button className={`support-button ${deviceType} ${buttonStyle}`} onClick={handleContactSupportButton}>
                    {buttonMessage}
                </button>
            )}
        </Fragment>
    );
};
