import { getAppPlatform } from '../../common/helpers/app-platform'
import { registerConstantValue } from '../utils'
import { DI_SERVICE } from '../constants'

const registerAppPlatform = () => registerConstantValue(
    DI_SERVICE.APP_PLATFORM,
    getAppPlatform()
)

export default registerAppPlatform
