import { types } from '../../types';

const initialState = {
    audioEnabled: true,
    inGameBalance: 0,
    win: 0,
    stake: 0,
    spinInProgress: false,
    audioSettingsReceived: false,
    gameWindowUI: undefined,
    gameWindowUILoading: false,
    gameWindowUIError: undefined,
    sessionTime: '00:00:00',
    gameTime: '00:00:00',
    currentTime: '00:00:00 A',
    licenseCardVisible: false,
    errorMessageDisplayed: false,
    inGameError: { errorMessage: undefined, errorCode: undefined },
    gameLoaded: false,
    isRewardsMachine: undefined,
    gameInfo: undefined,
    gameInfoLoading: false,
    gameInfoError: undefined,
    isUniversalShortLink: false,
    loadingPercent: undefined,
    loadingScreenVisible: true,
    closeGameLauncher: false,
    gameWindowExpansionState: false,
    gameExpansionToggleDisplayState: false,
    gameWindowExpansionDisabled: false,
};

const gameWindowReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.GET_GAME_WINDOW_REQUEST:
            return {
                ...state,
                gameWindowUILoading: true
            };
        case types.GET_GAME_WINDOW_RECEIVED:
            return {
                ...state,
                gameWindowUILoading: false,
                gameWindowUI: payload
            };
        case types.GET_GAME_WINDOW_FAILED:
            return {
                ...state,
                gameWindowUILoading: false,
                gameWindowUIError: payload
            };
        case types.SET_IN_GAME_BALANCE:
            return {
                ...state,
                inGameBalance: payload
            };
        case types.SET_AUDIO_ENABLED:
            return {
                ...state,
                audioEnabled: payload
            };
        case types.SET_STAKE:
            return {
                ...state,
                stake: payload
            };
        case types.SET_WIN:
            return {
                ...state,
                win: payload
            };
        case types.SET_SPIN_IN_PROGRESS:
            return {
                ...state,
                spinInProgress: payload
            };
        case types.SET_AUDIO_SETTINGS_RECEIVED:
            return {
                ...state,
                audioSettingsReceived: payload
            };
        case types.SET_CURRENT_TIME:
            return {
                ...state,
                currentTime: payload
            };
        case types.SET_SESSION_TIME:
            return {
                ...state,
                sessionTime: payload
            };
        case types.SET_GAME_TIME:
            return {
                ...state,
                gameTime: payload
            };
        case types.SET_LICENSE_CARD_VISIBLE:
            return {
                ...state,
                licenseCardVisible: payload
            };
        case types.SET_ERROR_MESSAGE_DISPLAYED:
            return {
                ...state,
                errorMessageDisplayed: payload
            };
        case types.SET_IN_GAME_ERROR:
            return {
                ...state,
                inGameError: payload
            };
        case types.GAME_LOAD_COMPLETE:
            return {
                ...state,
                gameLoaded: payload
            };
        case types.SET_IS_REWARDS_MACHINE:
            return {
                ...state,
                isRewardsMachine: true
            };
        case types.SET_IS_UNIVERSAL_SHORT_LINK: {
            return {
                ...state,
                isUniversalShortLink: true
            };
        }
        case types.SET_LOADING_PERCENT: {
            return {
                ...state,
                loadingPercent: payload
            };
        }
        case types.SET_LOADING_SCREEN_VISIBILITY: {
            return {
                ...state,
                loadingScreenVisible: payload
            };
        }
        case types.CLOSE_GAME_LAUNCHER: {
            return {
                ...state,
                closeGameLauncher: payload
            }
        }
        case types.GAME_WINDOW_EXPANSION_STATE: {
            return {
                ...state,
                gameWindowExpansionState: payload
            }
        }
        case types.GAME_EXPANSION_TOGGLE_DISPLAY_STATE: {
            return {
                ...state,
                gameExpansionToggleDisplayState: payload
            }
        }
        case types.GAME_WINDOW_EXPANSION_DISABLED: {
            return {
                ...state,
                gameWindowExpansionDisabled: payload
            }
        }
        default:
            return state;
    }
};

export default gameWindowReducer;
