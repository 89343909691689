const MaintenanceIllustration = ({ size }) => {
    return (
        <svg width="324" height="225" viewBox="0 0 324 225" fill="none" xmlns="http://www.w3.org/2000/svg" class="maintenance-illustration">
            <path
                d="M322.525 221.624H1.60852C0.996301 221.624 0.5 222.12 0.5 222.733C0.5 223.345 0.996301 223.841 1.60852 223.841H322.525C323.137 223.841 323.634 223.345 323.634 222.733C323.634 222.12 323.137 221.624 322.525 221.624Z"
                fill="#EFF3F7"
            />
            <mask
                id="mask0_4548_10689"
                style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="11"
                y="20"
                width="296"
                height="204"
            >
                <path
                    d="M77.8394 69.4371C49.4809 80.8083 42.5326 45.4383 19.9925 90.8483C-2.54767 136.258 27.8805 176.799 32.405 190.929C35.4213 200.349 33.9132 211.336 27.8805 223.892H289.799C305.32 207.283 310.107 186.665 304.159 162.036C295.236 125.093 275.129 124.122 257.668 90.8483C240.206 57.5748 265.723 33.2465 230.802 22.2555C195.881 11.2644 151.63 55.0919 124.511 48.9383C97.3909 42.7847 106.198 58.0658 77.8394 69.4371Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_4548_10689)">
                <path d="M357.623 -1.13794H-10.406V223.892H357.623V-1.13794Z" fill="#EFF3F7" />
            </g>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M125.388 61.1238C125.388 60.1033 124.552 59.2762 123.522 59.2762H103.42C102.39 59.2762 101.554 60.1033 101.554 61.1238C101.554 62.1442 102.39 62.9713 103.42 62.9713H106.369C107.4 62.9713 108.235 63.7985 108.235 64.8188C108.235 65.8392 107.4 66.6664 106.369 66.6664H103.42C102.39 66.6664 101.554 67.4935 101.554 68.5139C101.554 69.5343 102.39 70.3614 103.42 70.3614H123.522C124.552 70.3614 125.388 69.5343 125.388 68.5139C125.388 67.4935 124.552 66.6664 123.522 66.6664H120.573C119.542 66.6664 118.707 65.8392 118.707 64.8188C118.707 63.7985 119.542 62.9713 120.573 62.9713H123.522C124.552 62.9713 125.388 62.1442 125.388 61.1238Z"
                fill="white"
            />
            <path
                d="M263.398 124.125H222.383C219.934 124.125 217.949 126.11 217.949 128.559C217.949 131.008 219.934 132.993 222.383 132.993H263.398C265.847 132.993 267.832 131.008 267.832 128.559C267.832 126.11 265.847 124.125 263.398 124.125Z"
                fill="white"
            />
            <path
                d="M245.108 110.823H235.131C232.682 110.823 230.697 112.808 230.697 115.257C230.697 117.705 232.682 119.691 235.131 119.691H245.108C247.557 119.691 249.542 117.705 249.542 115.257C249.542 112.808 247.557 110.823 245.108 110.823Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M217.949 52.5328C217.949 51.0532 216.744 49.8539 215.258 49.8539H180.179C178.693 49.8539 177.488 51.0532 177.488 52.5328C177.488 54.0124 178.693 55.2117 180.179 55.2117H182.406C183.893 55.2117 185.097 56.4112 185.097 57.8905C185.097 59.3701 183.893 60.5696 182.406 60.5696H180.179C178.693 60.5696 177.488 61.7689 177.488 63.2485C177.488 64.7281 178.693 65.9274 180.179 65.9274H207.149C208.635 65.9274 209.84 64.7281 209.84 63.2485C209.84 61.7689 208.635 60.5696 207.149 60.5696H204.922C203.435 60.5696 202.231 59.3701 202.231 57.8905C202.231 56.4112 203.435 55.2117 204.922 55.2117H215.258C216.744 55.2117 217.949 54.0124 217.949 52.5328Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.7527 117.474C16.7527 118.882 17.9191 120.023 19.3581 120.023H33.2898C34.7286 120.023 35.8952 121.165 35.8952 122.573C35.8952 123.981 34.7286 125.122 33.2898 125.122H29.1714C27.7324 125.122 26.566 126.264 26.566 127.672C26.566 129.08 27.7324 130.222 29.1714 130.222H57.2431C58.6822 130.222 59.8486 129.08 59.8486 127.672C59.8486 126.264 58.6822 125.122 57.2431 125.122H53.1249C51.6859 125.122 50.5193 123.981 50.5193 122.573C50.5193 121.165 51.6859 120.023 53.1249 120.023H69.019C70.458 120.023 71.6244 118.882 71.6244 117.474"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.7527 117.104C16.7527 118.533 17.9231 119.691 19.3671 119.691H69.1987C70.6427 119.691 71.8131 118.533 71.8131 117.104C71.8131 115.676 70.6427 114.518 69.1987 114.518H59.1582C57.7142 114.518 56.5436 113.36 56.5436 111.931C56.5436 110.503 57.7142 109.344 59.1582 109.344H75.1069C76.5509 109.344 77.7213 108.186 77.7213 106.758C77.7213 105.329 76.5509 104.171 75.1069 104.171H33.1529C31.7089 104.171 30.5385 105.329 30.5385 106.758C30.5385 108.186 31.7089 109.344 33.1529 109.344H33.3467C34.7904 109.344 35.961 110.503 35.961 111.931C35.961 113.36 34.7904 114.518 33.3467 114.518H19.3671C17.9231 114.518 16.7527 115.676 16.7527 117.104Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.557 2.56338L259.599 2.60149C259.707 2.70139 259.714 2.87032 259.614 2.97881C259.588 3.00676 259.557 3.02889 259.522 3.04373L193.063 31.2493C192.888 31.3235 192.686 31.2419 192.612 31.0671C192.538 30.8922 192.619 30.6902 192.794 30.6159L258.965 2.46109C259.165 2.37581 259.397 2.41583 259.557 2.56338Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M260.014 2.78434L259.522 3.16255L280.816 31.4159L281.366 31.002L260.014 2.78434Z"
                fill="#878787"
            />
            <path d="M260.627 0.52478H258.41V224.446H260.627V0.52478Z" fill="#D5931B" />
            <path d="M248.433 38.2145H246.216V224.446H248.433V38.2145Z" fill="#D5931B" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 210.63L259.934 212.084L248.233 223.711L246.771 222.258L258.471 210.63Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 222.258L258.471 223.711L246.771 212.084L248.233 210.63L259.934 222.258Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 198.991L259.934 200.444L248.233 212.072L246.771 210.619L258.471 198.991Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 210.619L258.471 212.072L246.771 200.444L248.233 198.991L259.934 210.619Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M262.29 223.892V224.446C262.29 224.752 262.042 225 261.736 225H245.108C244.802 225 244.554 224.752 244.554 224.446V223.892C244.554 223.585 244.802 223.337 245.108 223.337H261.736C262.042 223.337 262.29 223.585 262.29 223.892Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 184.026L259.934 185.479L248.233 197.107L246.771 195.653L258.471 184.026Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 195.653L258.471 197.107L246.771 185.479L248.233 184.026L259.934 195.653Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 172.386L259.934 173.84L248.233 185.468L246.771 184.014L258.471 172.386Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 184.014L258.471 185.468L246.771 173.84L248.233 172.386L259.934 184.014Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M262.29 197.287V197.841C262.29 198.147 262.042 198.396 261.736 198.396H245.108C244.802 198.396 244.554 198.147 244.554 197.841V197.287C244.554 196.981 244.802 196.733 245.108 196.733H261.736C262.042 196.733 262.29 196.981 262.29 197.287Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 157.421L259.934 158.875L248.233 170.503L246.771 169.049L258.471 157.421Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 169.049L258.471 170.502L246.771 158.875L248.233 157.421L259.934 169.049Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 145.782L259.934 147.235L248.233 158.863L246.771 157.41L258.471 145.782Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 157.41L258.471 158.863L246.771 147.235L248.233 145.782L259.934 157.41Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M262.29 170.683V171.237C262.29 171.543 262.042 171.791 261.736 171.791H245.108C244.802 171.791 244.554 171.543 244.554 171.237V170.683C244.554 170.376 244.802 170.128 245.108 170.128H261.736C262.042 170.128 262.29 170.376 262.29 170.683Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 130.817L259.934 132.27L248.233 143.898L246.771 142.444L258.471 130.817Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 142.444L258.471 143.898L246.771 132.27L248.233 130.817L259.934 142.444Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 119.177L259.934 120.631L248.233 132.259L246.771 130.805L258.471 119.177Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 130.805L258.471 132.259L246.771 120.631L248.233 119.177L259.934 130.805Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M262.29 144.078V144.632C262.29 144.938 262.042 145.187 261.736 145.187H245.108C244.802 145.187 244.554 144.938 244.554 144.632V144.078C244.554 143.772 244.802 143.524 245.108 143.524H261.736C262.042 143.524 262.29 143.772 262.29 144.078Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 104.212L259.934 105.666L248.233 117.294L246.771 115.84L258.471 104.212Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 115.84L258.471 117.293L246.771 105.666L248.233 104.212L259.934 115.84Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 92.573L259.934 94.0265L248.233 105.654L246.771 104.201L258.471 92.573Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 104.201L258.471 105.654L246.771 94.0265L248.233 92.573L259.934 104.201Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M262.29 118.028V118.582C262.29 118.888 262.042 119.136 261.736 119.136H245.108C244.802 119.136 244.554 118.888 244.554 118.582V118.028C244.554 117.722 244.802 117.474 245.108 117.474H261.736C262.042 117.474 262.29 117.722 262.29 118.028Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 77.6079L259.934 79.0614L248.233 90.689L246.771 89.2356L258.471 77.6079Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 89.2355L258.471 90.6889L246.771 79.0614L248.233 77.6079L259.934 89.2355Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 65.9685L259.934 67.422L248.233 79.0497L246.771 77.5963L258.471 65.9685Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 77.5962L258.471 79.0497L246.771 67.422L248.233 65.9685L259.934 77.5962Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M262.29 90.8692V91.4235C262.29 91.7296 262.042 91.9777 261.736 91.9777H245.108C244.802 91.9777 244.554 91.7296 244.554 91.4235V90.8692C244.554 90.5631 244.802 90.3149 245.108 90.3149H261.736C262.042 90.3149 262.29 90.5631 262.29 90.8692Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 51.5576L259.934 53.0111L248.233 64.6388L246.771 63.1853L258.471 51.5576Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 63.1852L258.471 64.6386L246.771 53.0111L248.233 51.5576L259.934 63.1852Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.471 39.9182L259.934 41.3717L248.233 52.9994L246.771 51.546L258.471 39.9182Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M259.934 51.5459L258.471 52.9994L246.771 41.3717L248.233 39.9182L259.934 51.5459Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M262.661 65.3731V66.279C262.661 66.5851 262.413 66.8332 262.107 66.8332H245.108C244.802 66.8332 244.554 66.5851 244.554 66.279V65.3731C244.554 65.067 244.802 64.8188 245.108 64.8188H262.107C262.413 64.8188 262.661 65.067 262.661 65.3731Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M306.631 38.2144V39.1203C306.631 39.4264 306.382 39.6745 306.076 39.6745L132.593 39.6745C132.287 39.6745 132.039 39.4264 132.039 39.1203V38.2144C132.039 37.9083 132.287 37.6602 132.593 37.6602L306.076 37.6602C306.382 37.6602 306.631 37.9083 306.631 38.2144Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M305.77 38.5692L304.819 39.5061L297.208 32.0109L298.16 31.074L305.77 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M296.505 30.5197L297.456 31.4566L289.846 38.9518L288.894 38.0149L296.505 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M288.745 38.9518L289.696 38.0149L282.086 30.5196L281.135 31.4565L288.745 38.9518Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M280.431 30.5197L281.383 31.4566L273.772 38.9518L272.821 38.0149L280.431 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M274.177 38.5692L273.226 39.5061L265.615 32.0109L266.567 31.074L274.177 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M264.912 30.5197L265.863 31.4566L258.253 38.9518L257.302 38.0149L264.912 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M258.658 38.5692L257.707 39.5061L250.096 32.0109L251.047 31.074L258.658 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M249.393 30.5197L250.344 31.4566L242.734 38.9518L241.782 38.0149L249.393 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M243.139 38.5692L242.187 39.5061L234.577 32.0109L235.528 31.074L243.139 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M233.873 30.5197L234.825 31.4566L227.214 38.9518L226.263 38.0149L233.873 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M226.114 38.9518L227.065 38.0149L219.455 30.5196L218.503 31.4565L226.114 38.9518Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M217.8 30.5197L218.751 31.4566L211.141 38.9518L210.189 38.0149L217.8 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M211.546 38.5692L210.595 39.5061L202.984 32.0109L203.935 31.074L211.546 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M202.281 30.5197L203.232 31.4566L195.621 38.9518L194.67 38.0149L202.281 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M196.027 38.5692L195.075 39.5061L187.465 32.0109L188.416 31.074L196.027 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M186.761 30.5197L187.713 31.4566L180.102 38.9518L179.151 38.0149L186.761 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M180.507 38.5692L179.556 39.5061L171.945 32.0109L172.897 31.074L180.507 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M171.242 30.5197L172.193 31.4566L164.583 38.9518L163.632 38.0149L171.242 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M163.482 38.9518L164.434 38.0149L156.823 30.5196L155.872 31.4565L163.482 38.9518Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M155.169 30.5197L156.12 31.4566L148.509 38.9518L147.558 38.0149L155.169 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M148.914 38.5692L147.963 39.5061L140.353 32.0109L141.304 31.074L148.914 38.5692Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M139.649 30.5197L140.601 31.4566L132.99 38.9518L132.039 38.0149L139.649 30.5197Z"
                fill="#D5931B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M298.317 31.0091V31.5634C298.317 31.8695 298.069 32.1176 297.762 32.1176L139.798 32.1176C139.492 32.1176 139.244 31.8695 139.244 31.5634V31.0091C139.244 30.703 139.492 30.4548 139.798 30.4548L297.762 30.4548C298.069 30.4548 298.317 30.703 298.317 31.0091Z"
                fill="#D5931B"
            />
            <path
                d="M92.6817 98.6114L93.3489 85.8809L231.724 93.1328L231.057 105.863L92.6817 98.6114Z"
                fill="#AFBBC2"
            />
            <path
                d="M218.333 100.861L228.087 101.372C228.297 101.383 228.477 101.219 228.488 101.009L228.651 97.9C228.662 97.6895 228.5 97.5082 228.29 97.4972L218.536 96.9861C218.327 96.9751 218.146 97.1383 218.135 97.3489L217.972 100.458C217.961 100.669 218.124 100.85 218.333 100.861Z"
                fill="#D7DADD"
            />
            <path
                d="M212.117 100.388C213.187 100.444 214.1 99.622 214.156 98.552C214.212 97.4821 213.39 96.5693 212.32 96.5133C211.25 96.4572 210.337 97.2791 210.281 98.349C210.225 99.4189 211.047 100.332 212.117 100.388Z"
                fill="white"
            />
            <path
                d="M213.24 98.1158C213.699 98.1398 214.09 97.7876 214.114 97.329C214.138 96.8705 213.786 96.4793 213.327 96.4553C212.868 96.4312 212.477 96.7835 212.453 97.242C212.429 97.7005 212.781 98.0917 213.24 98.1158Z"
                fill="#D7DADD"
            />
            <path
                d="M187.759 97.2995L181.678 96.9808C181.524 96.9727 181.391 97.0908 181.383 97.2428C181.375 97.3949 181.495 97.5262 181.649 97.5343L187.73 97.853C187.884 97.8611 188.017 97.7429 188.025 97.5909C188.033 97.4385 187.913 97.3076 187.759 97.2995Z"
                fill="#D7DADD"
            />
            <path
                d="M178.336 96.7451L172.255 96.4264C172.101 96.4183 171.968 96.5365 171.96 96.6885C171.952 96.8405 172.072 96.9719 172.226 96.9799L178.307 97.2986C178.461 97.3067 178.594 97.1886 178.602 97.0366C178.61 96.8842 178.49 96.7532 178.336 96.7451Z"
                fill="#D7DADD"
            />
            <path
                d="M168.914 96.1909L162.833 95.8722C162.679 95.8642 162.546 95.9823 162.538 96.1343C162.53 96.2863 162.65 96.4177 162.804 96.4257L168.885 96.7444C169.039 96.7525 169.172 96.6344 169.18 96.4824C169.188 96.33 169.068 96.199 168.914 96.1909Z"
                fill="#D7DADD"
            />
            <path
                d="M158.937 95.6366L152.856 95.3179C152.702 95.3098 152.569 95.4279 152.561 95.58C152.553 95.732 152.673 95.8633 152.827 95.8714L158.908 96.1901C159.063 96.1982 159.195 96.0801 159.203 95.9281C159.211 95.7756 159.091 95.6447 158.937 95.6366Z"
                fill="#D7DADD"
            />
            <path
                d="M106.849 90.4099L106.826 90.3609L106.811 90.3512C105.925 89.796 104.909 89.4203 103.813 89.2779L103.8 89.276L103.798 89.2759L103.75 89.2957L103.746 89.3007C103.438 89.6591 103.17 90.0476 102.945 90.463L102.943 90.4718L102.962 90.4938L102.964 90.4943C103.029 90.4957 103.091 90.4977 103.152 90.5005C103.979 90.5418 104.759 90.7441 105.463 91.0743C105.171 92.6774 104.232 94.0452 102.93 94.9244C102.684 94.7197 102.456 94.4958 102.246 94.2558L102.243 94.2529L102.229 94.2469L102.206 94.2671L102.207 94.2712C102.291 95.0316 102.507 95.7541 102.834 96.4168L102.836 96.4218L102.868 96.4425L102.882 96.4408L102.894 96.4345C105.126 95.2939 106.701 93.0594 106.848 90.4163L106.849 90.4131L106.849 90.4099Z"
                fill="white"
            />
            <path
                d="M101.987 95.9545C101.759 95.2488 101.634 94.4919 101.632 93.7059L101.633 93.7032L101.633 93.6944L101.626 93.6701L101.622 93.6639C101.502 93.4588 101.393 93.2452 101.296 93.0238C101.405 92.9748 101.517 92.932 101.63 92.8948L101.647 92.8893L101.679 92.8483L101.681 92.8356L101.681 92.8328C101.73 92.396 101.817 91.9726 101.938 91.5655L101.941 91.5564L101.941 91.556L101.923 91.5339L101.914 91.5355C101.569 91.5952 101.235 91.6955 100.918 91.8321C100.86 91.5557 100.821 91.2718 100.801 90.9826C101.256 90.8049 101.738 90.6909 102.237 90.6488L102.239 90.6493L102.256 90.6474L102.29 90.6224L102.298 90.6053C102.492 90.1824 102.724 89.7839 102.989 89.415L102.994 89.4104L102.997 89.3998L102.979 89.3781C101.786 89.32 100.652 89.6046 99.6567 90.1507L99.6525 90.1529L99.6248 90.201L99.6246 90.2053C99.5202 92.5088 100.441 94.6034 101.956 95.9784L101.97 95.9855L101.991 95.9654L101.99 95.9606L101.987 95.9545Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M156.394 38.8787L158.055 38.9657L156.807 62.7662L155.219 62.5467L156.394 38.8787Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M155.821 52.724L157.178 52.7952C157.677 52.8213 158.096 53.1771 158.203 53.6645L158.942 57.0306C159.073 57.6285 158.695 58.2197 158.097 58.351C158 58.3722 157.901 58.3804 157.802 58.3753L154.619 58.2104C154.007 58.1787 153.537 57.6574 153.569 57.046C153.574 56.9466 153.593 56.8483 153.624 56.7538L154.711 53.4815C154.868 53.008 155.323 52.6979 155.821 52.724Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M153.311 55.3716L159.399 55.6873C160.01 55.7183 160.48 56.2396 160.448 56.851C160.448 56.8513 160.448 56.8515 160.447 56.8517L160.273 60.1682C160.24 60.7792 159.719 61.2487 159.108 61.2177L153.02 60.902C152.409 60.8711 151.939 60.3497 151.97 59.7383C151.97 59.7381 151.97 59.7378 151.971 59.7376L152.145 56.4212C152.178 55.8101 152.7 55.3407 153.311 55.3716Z"
                fill="#A8A8A8"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M156.341 61.0767C159.704 61.2529 162.287 64.1217 162.11 67.4842C161.934 70.8468 159.065 73.4299 155.703 73.2537C152.34 73.0774 149.757 70.2087 149.933 66.8461C149.961 66.3195 150.144 65.7015 150.471 65.4075L153.597 68.8787C154.542 69.9281 156.158 70.0128 157.208 69.0679C158.257 68.1231 158.342 66.5065 157.397 65.4571L156.125 64.0443L154.887 62.67C154.884 62.6328 154.883 62.6005 154.884 62.5758C154.895 62.3545 154.948 62.3698 154.956 62.2082C154.979 61.7667 155.009 61.3066 155.019 61.0074L156.341 61.0767Z"
                fill="#D5931B"
            />
            <path
                d="M155.891 69.6559C155.899 69.503 155.782 69.3726 155.629 69.3646C155.476 69.3566 155.346 69.474 155.338 69.6268L153.655 101.73C153.647 101.883 153.765 102.013 153.918 102.021C154.071 102.029 154.201 101.912 154.209 101.759L155.891 69.6559Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M153.483 68.0438C153.578 68.1493 153.57 68.3121 153.464 68.4074C153.464 68.4077 153.464 68.408 153.463 68.4083L131.622 87.9473L129.813 100.857C129.792 101.006 129.654 101.11 129.505 101.089C129.359 101.069 129.256 100.936 129.272 100.79C129.299 100.534 129.886 96.2259 131.033 87.8652L153.117 68.0248C153.223 67.9292 153.387 67.9377 153.483 68.0438Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M157.258 68.2739C157.152 68.3689 157.143 68.5316 157.238 68.6375C157.238 68.6378 157.239 68.6381 157.239 68.6384L176.895 90.3326L177.438 103.382C177.444 103.513 177.554 103.615 177.686 103.609C177.813 103.604 177.913 103.499 177.913 103.372C177.913 103.114 177.78 98.7682 177.513 90.3334L157.624 68.2932C157.528 68.187 157.364 68.1784 157.258 68.2739Z"
                fill="#878787"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M150.884 205.601H166.403V222.158C165.267 223.683 162.648 224.446 158.545 224.446C154.443 224.446 151.889 223.683 150.884 222.158V205.601Z"
                fill="#3F5A6B"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M135.641 221.12H181.091C182.162 221.12 183.031 221.989 183.031 223.06V225H133.702V223.06C133.702 221.989 134.57 221.12 135.641 221.12Z"
                fill="#3F5A6B"
            />
            <path
                d="M226.383 124.679H221.298H206.259H112.179H94.0722H90.904C88.531 124.679 86.5895 126.222 86.5895 128.107V204.39C86.5895 206.275 88.531 207.818 90.904 207.818H94.0722H112.179H206.259H221.298H226.383C228.755 207.818 230.697 206.275 230.697 204.39V128.107C230.697 126.221 228.755 124.679 226.383 124.679Z"
                fill="#3F5A6B"
            />
            <path
                d="M89.3608 202.83V129.667C89.3608 128.443 90.3534 127.45 91.5778 127.45H225.709C226.933 127.45 227.926 128.443 227.926 129.667V202.83C227.926 204.054 226.933 205.047 225.709 205.047H91.5778C90.3534 205.047 89.3608 204.054 89.3608 202.83Z"
                fill="#E9EDEF"
            />
            <path
                d="M221.961 205.601H221.143C220.91 205.601 220.72 205.429 220.72 205.219V157.208C220.72 156.998 220.91 156.826 221.143 156.826H221.961C222.193 156.826 222.383 156.998 222.383 157.208V205.219C222.383 205.429 222.193 205.601 221.961 205.601Z"
                fill="white"
            />
            <path
                d="M218.585 205.047H176.852C176.592 205.047 176.38 204.914 176.38 204.751V193.148C176.38 192.986 176.592 192.853 176.852 192.853H218.585C218.845 192.853 219.058 192.986 219.058 193.148V204.751C219.058 204.914 218.845 205.047 218.585 205.047Z"
                fill="white"
            />
            <path
                d="M183.308 197.841C181.624 197.841 180.259 199.206 180.259 200.89C180.259 202.573 181.624 203.938 183.308 203.938C184.991 203.938 186.356 202.574 186.356 200.89C186.356 199.206 184.991 197.841 183.308 197.841Z"
                fill="#D7DADD"
            />
            <path
                d="M213.365 197.841H192.049C191.357 197.841 190.79 198.34 190.79 198.95C190.79 199.56 191.357 200.058 192.049 200.058H213.365C214.057 200.058 214.623 199.56 214.623 198.95C214.623 198.34 214.057 197.841 213.365 197.841Z"
                fill="#D7DADD"
            />
            <path
                d="M213.365 201.721H192.049C191.357 201.721 190.79 202.22 190.79 202.83C190.79 203.439 191.357 203.938 192.049 203.938H213.365C214.057 203.938 214.623 203.439 214.623 202.83C214.623 202.22 214.057 201.721 213.365 201.721Z"
                fill="#D7DADD"
            />
            <path
                d="M218.585 191.19H176.852C176.592 191.19 176.38 191.015 176.38 190.801V175.506C176.38 175.292 176.592 175.117 176.852 175.117H218.585C218.845 175.117 219.058 175.292 219.058 175.506V190.801C219.058 191.015 218.845 191.19 218.585 191.19Z"
                fill="white"
            />
            <path
                d="M183.308 180.105C181.624 180.105 180.259 181.47 180.259 183.153C180.259 184.837 181.624 186.202 183.308 186.202C184.991 186.202 186.356 184.837 186.356 183.153C186.356 181.47 184.991 180.105 183.308 180.105Z"
                fill="#D7DADD"
            />
            <path
                d="M213.365 180.105H192.049C191.357 180.105 190.79 180.604 190.79 181.214C190.79 181.823 191.357 182.322 192.049 182.322H213.365C214.057 182.322 214.623 181.823 214.623 181.214C214.623 180.604 214.057 180.105 213.365 180.105Z"
                fill="#D7DADD"
            />
            <path
                d="M213.365 183.985H192.049C191.357 183.985 190.79 184.483 190.79 185.093C190.79 185.703 191.357 186.202 192.049 186.202H213.365C214.057 186.202 214.623 185.703 214.623 185.093C214.623 184.483 214.057 183.985 213.365 183.985Z"
                fill="#D7DADD"
            />
            <path
                d="M218.585 172.899H176.852C176.592 172.899 176.38 172.724 176.38 172.51V157.215C176.38 157.001 176.592 156.826 176.852 156.826H218.585C218.845 156.826 219.058 157.001 219.058 157.215V172.51C219.058 172.724 218.845 172.899 218.585 172.899Z"
                fill="white"
            />
            <path
                d="M183.308 161.814C181.624 161.814 180.259 163.179 180.259 164.863C180.259 166.546 181.624 167.911 183.308 167.911C184.991 167.911 186.356 166.546 186.356 164.863C186.356 163.179 184.991 161.814 183.308 161.814Z"
                fill="#D7DADD"
            />
            <path
                d="M213.365 161.814H192.049C191.357 161.814 190.79 162.313 190.79 162.923C190.79 163.532 191.357 164.031 192.049 164.031H213.365C214.057 164.031 214.623 163.532 214.623 162.923C214.623 162.313 214.057 161.814 213.365 161.814Z"
                fill="#D7DADD"
            />
            <path
                d="M213.365 165.694H192.049C191.357 165.694 190.79 166.193 190.79 166.803C190.79 167.412 191.357 167.911 192.049 167.911H213.365C214.057 167.911 214.623 167.412 214.623 166.803C214.623 166.193 214.057 165.694 213.365 165.694Z"
                fill="#D7DADD"
            />
            <path
                d="M172.632 205.047H171.813C171.581 205.047 171.391 204.877 171.391 204.669V157.204C171.391 156.996 171.581 156.826 171.813 156.826H172.632C172.864 156.826 173.054 156.996 173.054 157.204V204.669C173.054 204.877 172.864 205.047 172.632 205.047Z"
                fill="white"
            />
            <path
                d="M96.4826 201.721H168.703C168.962 201.721 169.174 201.544 169.174 201.328V194.909C169.174 194.693 168.962 194.516 168.703 194.516H96.4826C96.2237 194.516 96.0119 194.693 96.0119 194.909V201.328C96.0119 201.544 96.2237 201.721 96.4826 201.721Z"
                fill="white"
            />
            <path
                d="M162.8 200.612C163.872 200.612 164.74 199.744 164.74 198.673C164.74 197.601 163.872 196.733 162.8 196.733C161.729 196.733 160.86 197.601 160.86 198.673C160.86 199.744 161.729 200.612 162.8 200.612Z"
                fill="#D7DADD"
            />
            <path
                d="M154.774 197.287H102.097C101.494 197.287 101 197.661 101 198.118C101 198.575 101.494 198.95 102.097 198.95H154.775C155.378 198.95 155.872 198.576 155.872 198.118C155.872 197.661 155.378 197.287 154.774 197.287Z"
                fill="#D7DADD"
            />
            <path
                d="M96.4826 192.299H168.703C168.962 192.299 169.174 192.122 169.174 191.906V185.486C169.174 185.27 168.962 185.093 168.703 185.093H96.4826C96.2237 185.093 96.0119 185.27 96.0119 185.486V191.906C96.0119 192.122 96.2237 192.299 96.4826 192.299Z"
                fill="white"
            />
            <path
                d="M162.8 191.19C163.872 191.19 164.74 190.322 164.74 189.25C164.74 188.179 163.872 187.31 162.8 187.31C161.729 187.31 160.86 188.179 160.86 189.25C160.86 190.322 161.729 191.19 162.8 191.19Z"
                fill="#D7DADD"
            />
            <path
                d="M154.774 187.865H102.097C101.494 187.865 101 188.239 101 188.696C101 189.153 101.494 189.527 102.097 189.527H154.775C155.378 189.527 155.872 189.153 155.872 188.696C155.872 188.238 155.378 187.865 154.774 187.865Z"
                fill="#D7DADD"
            />
            <path
                d="M96.4826 182.876H168.703C168.962 182.876 169.174 182.699 169.174 182.483V176.064C169.174 175.848 168.962 175.671 168.703 175.671H96.4826C96.2237 175.671 96.0119 175.848 96.0119 176.064V182.483C96.0119 182.699 96.2237 182.876 96.4826 182.876Z"
                fill="white"
            />
            <path
                d="M162.8 181.768C163.872 181.768 164.74 180.899 164.74 179.828C164.74 178.756 163.872 177.888 162.8 177.888C161.729 177.888 160.86 178.756 160.86 179.828C160.86 180.899 161.729 181.768 162.8 181.768Z"
                fill="#D7DADD"
            />
            <path
                d="M154.774 178.442H102.097C101.494 178.442 101 178.816 101 179.274C101 179.731 101.494 180.105 102.097 180.105H154.775C155.378 180.105 155.872 179.731 155.872 179.274C155.872 178.816 155.378 178.442 154.774 178.442Z"
                fill="#D7DADD"
            />
            <path
                d="M96.4826 173.454H168.703C168.962 173.454 169.174 173.277 169.174 173.061V166.642C169.174 166.425 168.962 166.248 168.703 166.248H96.4826C96.2237 166.248 96.0119 166.425 96.0119 166.642V173.061C96.0119 173.277 96.2237 173.454 96.4826 173.454Z"
                fill="white"
            />
            <path
                d="M162.8 172.345C163.872 172.345 164.74 171.477 164.74 170.405C164.74 169.334 163.872 168.465 162.8 168.465C161.729 168.465 160.86 169.334 160.86 170.405C160.86 171.477 161.729 172.345 162.8 172.345Z"
                fill="#D7DADD"
            />
            <path
                d="M154.774 169.02H102.097C101.494 169.02 101 169.394 101 169.851C101 170.308 101.494 170.682 102.097 170.682H154.775C155.378 170.682 155.872 170.308 155.872 169.851C155.872 169.394 155.378 169.02 154.774 169.02Z"
                fill="#D7DADD"
            />
            <path
                d="M96.4826 164.031H168.703C168.962 164.031 169.174 163.854 169.174 163.638V157.219C169.174 157.003 168.962 156.826 168.703 156.826H96.4826C96.2237 156.826 96.0119 157.003 96.0119 157.219V163.638C96.0119 163.854 96.2237 164.031 96.4826 164.031Z"
                fill="white"
            />
            <path
                d="M162.8 162.923C163.872 162.923 164.74 162.054 164.74 160.983C164.74 159.911 163.872 159.043 162.8 159.043C161.729 159.043 160.86 159.911 160.86 160.983C160.86 162.054 161.729 162.923 162.8 162.923Z"
                fill="#D7DADD"
            />
            <path
                d="M154.774 159.597H102.097C101.494 159.597 101 159.971 101 160.428C101 160.886 101.494 161.26 102.097 161.26H154.775C155.378 161.26 155.872 160.886 155.872 160.428C155.872 159.971 155.378 159.597 154.774 159.597Z"
                fill="#D7DADD"
            />
            <path
                d="M142.028 147.958H97.1078C96.5051 147.958 96.0119 148.332 96.0119 148.789C96.0119 149.247 96.5051 149.621 97.1078 149.621H142.028C142.631 149.621 143.124 149.246 143.124 148.789C143.124 148.332 142.631 147.958 142.028 147.958Z"
                fill="#D7DADD"
            />
            <path
                d="M97.5944 146.295H130.456C131.327 146.295 132.039 145.671 132.039 144.909C132.039 144.147 131.327 143.524 130.456 143.524H97.5944C96.7241 143.524 96.0119 144.147 96.0119 144.909C96.0119 145.671 96.7241 146.295 97.5944 146.295Z"
                fill="#99A8B1"
            />
            <path
                d="M158.089 125.233C157.783 125.233 157.535 125.481 157.535 125.787C157.535 126.094 157.783 126.342 158.089 126.342C158.395 126.342 158.643 126.094 158.643 125.787C158.643 125.481 158.395 125.233 158.089 125.233Z"
                fill="#2C3F4B"
            />
            <path d="M224.046 205.047H91.0235V207.264H224.046V205.047Z" fill="#3F5A6B" />
            <path
                d="M80.484 160.152H56.1137C54.8896 160.152 53.8881 161.141 53.8881 162.35V210.907V213.933C53.8881 215.142 54.8896 216.132 56.1137 216.132H62.4069H74.1909H80.484C81.7082 216.132 82.7096 215.142 82.7096 213.933V210.907V162.35C82.7096 161.141 81.7082 160.152 80.484 160.152Z"
                fill="#3F5A6B"
            />
            <path d="M56.1052 162.369V175.963V207.759V208.372H80.4926V207.759V175.963V162.369H56.1052Z" fill="white" />
            <path
                opacity="0.7"
                d="M60.2905 174.008H75.753C75.921 174.008 76.0585 173.854 76.0585 173.665V173.242C76.0585 173.054 75.921 172.9 75.753 172.9H60.2905C60.1225 172.9 59.985 173.054 59.985 173.242V173.665C59.985 173.854 60.1225 174.008 60.2905 174.008Z"
                fill="#C6CBCF"
            />
            <path
                d="M61.6 171.791H74.4435C74.7221 171.791 74.95 171.585 74.95 171.334V171.14C74.95 170.888 74.7221 170.682 74.4435 170.682H61.6C61.3214 170.682 61.0935 170.888 61.0935 171.14V171.334C61.0935 171.585 61.3214 171.791 61.6 171.791Z"
                fill="#99A8B1"
            />
            <path d="M81.0469 162.369H56.1052V164.586H81.0469V162.369Z" fill="#3F5A6B" />
            <path
                d="M57.7679 162.369H78.8298C79.7481 162.369 80.4926 163.113 80.4926 164.031V168.466H56.1052V164.031C56.1052 163.113 56.8496 162.369 57.7679 162.369Z"
                fill="#99A8B1"
            />
            <path d="M57.7679 164.863H59.985" stroke="white" stroke-width="1.3011" stroke-linecap="round" />
            <path d="M57.7679 166.526H61.0935" stroke="white" stroke-width="1.3011" stroke-linecap="round" />
            <path d="M80.4926 208.926H56.1052V176.225H80.4926V208.926Z" fill="#E2E6E8" />
            <path
                d="M78.6248 187.865H57.9732C57.8603 187.865 57.7679 187.769 57.7679 187.652V178.101C57.7679 177.984 57.8603 177.888 57.9732 177.888H78.6246C78.7375 177.888 78.8298 177.984 78.8298 178.101V187.652C78.8298 187.769 78.7375 187.865 78.6248 187.865Z"
                fill="white"
            />
            <path
                d="M61.925 181.214C60.8536 181.214 59.985 182.082 59.985 183.154C59.985 184.225 60.8536 185.093 61.925 185.093C62.9964 185.093 63.8648 184.225 63.8648 183.154C63.8648 182.082 62.9962 181.214 61.925 181.214Z"
                fill="#D7DADD"
            />
            <path
                d="M76.0719 181.214H65.5142C65.2167 181.214 64.9733 181.433 64.9733 181.701V181.834C64.9733 182.103 65.2167 182.322 65.5142 182.322H76.0719C76.3694 182.322 76.6128 182.103 76.6128 181.834V181.701C76.6128 181.433 76.3694 181.214 76.0719 181.214Z"
                fill="#D7DADD"
            />
            <path
                d="M76.0719 183.431H65.5142C65.2167 183.431 64.9733 183.65 64.9733 183.918V184.051C64.9733 184.32 65.2167 184.539 65.5142 184.539H76.0719C76.3694 184.539 76.6128 184.32 76.6128 184.051V183.918C76.6128 183.65 76.3694 183.431 76.0719 183.431Z"
                fill="#D7DADD"
            />
            <path
                d="M78.6248 198.396H57.9732C57.8603 198.396 57.7679 198.3 57.7679 198.183V188.631C57.7679 188.514 57.8603 188.419 57.9732 188.419H78.6246C78.7375 188.419 78.8298 188.514 78.8298 188.631V198.183C78.8298 198.3 78.7375 198.396 78.6248 198.396Z"
                fill="white"
            />
            <path
                d="M61.925 191.19C60.8536 191.19 59.985 192.059 59.985 193.13C59.985 194.201 60.8536 195.07 61.925 195.07C62.9964 195.07 63.8648 194.201 63.8648 193.13C63.8648 192.059 62.9962 191.19 61.925 191.19Z"
                fill="#D7DADD"
            />
            <path
                d="M76.0719 191.744H65.5142C65.2167 191.744 64.9733 191.964 64.9733 192.232V192.365C64.9733 192.634 65.2167 192.853 65.5142 192.853H76.0719C76.3694 192.853 76.6128 192.634 76.6128 192.365V192.232C76.6128 191.964 76.3694 191.744 76.0719 191.744Z"
                fill="#D7DADD"
            />
            <path
                d="M76.0719 193.961H65.5142C65.2167 193.961 64.9733 194.181 64.9733 194.449V194.582C64.9733 194.851 65.2167 195.07 65.5142 195.07H76.0719C76.3694 195.07 76.6128 194.851 76.6128 194.582V194.449C76.6128 194.181 76.3694 193.961 76.0719 193.961Z"
                fill="#D7DADD"
            />
            <path
                d="M78.8298 199.704C78.8298 199.594 78.7375 199.504 78.6246 199.504H57.9732C57.8603 199.504 57.7679 199.594 57.7679 199.704V208.372H78.8298V199.704Z"
                fill="white"
            />
            <path
                d="M61.925 202.275C60.8536 202.275 59.985 203.144 59.985 204.215C59.985 205.286 60.8536 206.155 61.925 206.155C62.9964 206.155 63.8648 205.286 63.8648 204.215C63.8648 203.144 62.9962 202.275 61.925 202.275Z"
                fill="#D7DADD"
            />
            <path
                d="M76.0719 202.83H65.5142C65.2167 202.83 64.9733 203.049 64.9733 203.317V203.45C64.9733 203.719 65.2167 203.938 65.5142 203.938H76.0719C76.3694 203.938 76.6128 203.719 76.6128 203.45V203.317C76.6128 203.049 76.3694 202.83 76.0719 202.83Z"
                fill="#D7DADD"
            />
            <path
                d="M76.0719 204.492H65.5142C65.2167 204.492 64.9733 204.712 64.9733 204.98V205.113C64.9733 205.381 65.2167 205.601 65.5142 205.601H76.0719C76.3694 205.601 76.6128 205.381 76.6128 205.113V204.98C76.6128 204.712 76.3694 204.492 76.0719 204.492Z"
                fill="#D7DADD"
            />
            <mask
                id="mask1_4548_10689"
                style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="66"
                y="164"
                width="4"
                height="4"
            >
                <path
                    d="M68.5597 164.042C69.0446 164.081 69.5121 164.225 69.9322 164.464C69.9347 164.465 69.9371 164.467 69.9393 164.468C69.9462 164.474 69.9504 164.482 69.9508 164.49C69.9449 165.664 69.3218 166.745 68.3117 167.336C68.3078 167.339 68.3028 167.341 68.2979 167.34C68.2929 167.339 68.2884 167.337 68.2855 167.333C68.1195 167.029 68.0079 166.702 67.9539 166.363V166.361L67.9555 166.356C67.9631 166.35 67.9672 166.351 67.9701 166.354C68.071 166.459 68.1787 166.556 68.2936 166.644C68.8724 166.203 69.2505 165.548 69.3437 164.826C69.011 164.687 68.6541 164.616 68.2936 164.616L68.2106 164.618C68.2049 164.617 68.2022 164.616 68.2006 164.613C68.199 164.61 68.1987 164.607 68.1999 164.604L68.2716 164.461C68.3472 164.319 68.4339 164.184 68.5311 164.056C68.5395 164.046 68.548 164.042 68.5567 164.043L68.5597 164.042ZM68.2895 164.031L68.2964 164.034L68.2993 164.041C68.2993 164.044 68.2981 164.047 68.2961 164.048C68.172 164.226 68.0663 164.417 67.9806 164.616C67.9773 164.629 67.9657 164.638 67.9524 164.638C67.708 164.668 67.4699 164.731 67.2435 164.825C67.2605 164.957 67.2871 165.088 67.3232 165.216C67.4799 165.144 67.6442 165.09 67.8128 165.055H67.817L67.8237 165.058L67.8265 165.065L67.8263 165.067C67.7746 165.264 67.7422 165.462 67.7285 165.663C67.7287 165.666 67.7287 165.668 67.7285 165.67C67.7259 165.681 67.7168 165.69 67.7052 165.693C67.6496 165.712 67.5951 165.735 67.5419 165.76C67.5953 165.86 67.655 165.957 67.7206 166.05C67.7253 166.057 67.7268 166.062 67.7268 166.068C67.7467 166.424 67.8256 166.773 67.9603 167.102L67.9618 167.105C67.9618 167.111 67.9599 167.114 67.957 167.116C67.954 167.118 67.9504 167.118 67.9473 167.116C67.1247 166.492 66.6409 165.523 66.6361 164.493V164.491L66.6398 164.478L66.6489 164.468C67.1499 164.181 67.7147 164.031 68.2895 164.031Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask1_4548_10689)">
                <path
                    d="M68.5597 164.042C69.0446 164.081 69.5121 164.225 69.9322 164.464C69.9347 164.465 69.9371 164.467 69.9393 164.468C69.9462 164.474 69.9504 164.482 69.9508 164.49C69.9449 165.664 69.3218 166.745 68.3117 167.336C68.3078 167.339 68.3028 167.341 68.2979 167.34C68.2929 167.339 68.2884 167.337 68.2855 167.333C68.1195 167.029 68.0079 166.702 67.9539 166.363V166.361L67.9555 166.356C67.9631 166.35 67.9672 166.351 67.9701 166.354C68.071 166.459 68.1787 166.556 68.2936 166.644C68.8724 166.203 69.2505 165.548 69.3437 164.826C69.011 164.687 68.6541 164.616 68.2936 164.616L68.2106 164.618C68.2049 164.617 68.2022 164.616 68.2006 164.613C68.199 164.61 68.1987 164.607 68.1999 164.604L68.2716 164.461C68.3472 164.319 68.4339 164.184 68.5311 164.056C68.5395 164.046 68.548 164.042 68.5567 164.043L68.5597 164.042ZM68.2895 164.031L68.2964 164.034L68.2993 164.041C68.2993 164.044 68.2981 164.047 68.2961 164.048C68.172 164.226 68.0663 164.417 67.9806 164.616C67.9773 164.629 67.9657 164.638 67.9524 164.638C67.708 164.668 67.4699 164.731 67.2435 164.825C67.2605 164.957 67.2871 165.088 67.3232 165.216C67.4799 165.144 67.6442 165.09 67.8128 165.055H67.817L67.8237 165.058L67.8265 165.065L67.8263 165.067C67.7746 165.264 67.7422 165.462 67.7285 165.663C67.7287 165.666 67.7287 165.668 67.7285 165.67C67.7259 165.681 67.7168 165.69 67.7052 165.693C67.6496 165.712 67.5951 165.735 67.5419 165.76C67.5953 165.86 67.655 165.957 67.7206 166.05C67.7253 166.057 67.7268 166.062 67.7268 166.068C67.7467 166.424 67.8256 166.773 67.9603 167.102L67.9618 167.105C67.9618 167.111 67.9599 167.114 67.957 167.116C67.954 167.118 67.9504 167.118 67.9473 167.116C67.1247 166.492 66.6409 165.523 66.6361 164.493V164.491L66.6398 164.478L66.6489 164.468C67.1499 164.181 67.7147 164.031 68.2895 164.031Z"
                    fill="white"
                />
                <path d="M69.9617 164.031H66.6361V167.357H69.9617V164.031Z" fill="white" />
            </g>
            <path
                d="M24.7982 201.301C24.8455 201.278 24.8929 201.112 24.9284 200.923L27.7095 182.099C27.7332 181.922 27.9107 181.768 28.0883 181.768H33.3547C33.5322 181.768 33.8281 181.768 34.0174 181.768H50.5268C50.7044 181.768 50.87 181.91 50.8819 182.099L53.6985 214.269C53.7104 214.446 53.7459 214.742 53.7577 214.92L53.9826 217.463C53.9944 217.641 53.9944 217.795 53.9589 217.795C53.9352 217.795 53.7577 217.795 53.5802 217.795H37.1655C36.9879 217.795 36.6921 217.795 36.5027 217.795H32.905C32.7275 217.795 32.4316 217.795 32.2422 217.795H21.0466C20.8691 217.795 20.7034 217.653 20.6916 217.463L19.9341 208.744C19.9223 208.566 19.8868 208.27 19.875 208.093L19.6264 205.301C19.6146 205.123 19.7211 204.886 19.8631 204.78L24.455 201.538C24.597 201.432 24.7627 201.325 24.7982 201.301Z"
                fill="#2291DA"
            />
            <path
                d="M30.0025 183.43H44.5816C44.7595 183.43 44.9137 183.574 44.9256 183.753L46.361 200.29C46.3728 200.469 46.2423 200.612 46.0644 200.612H27.2385C27.0605 200.612 26.9419 200.469 26.9656 200.29L29.6347 183.753C29.6584 183.574 29.8245 183.43 30.0025 183.43Z"
                fill="#1EC5F3"
            />
            <path
                d="M33.4822 207.17H52.9355L53.5203 213.793C53.5322 213.972 53.6993 214.127 53.8783 214.127H73.1287H75.5395C75.6588 214.127 78.9965 214.179 85.5526 214.282C85.5645 214.461 85.6003 214.759 85.6122 214.938L85.7197 216.132H28.3145L21.5834 211.585L21.5715 211.466H21.4044H19.9365L19.5307 207.874H22.6098L22.4785 206.43H19.3517L19.2085 205.141C19.2204 204.986 19.3159 204.807 19.4352 204.711L24.7904 201.283L33.4822 207.17Z"
                fill="#2A80B9"
            />
            <path
                d="M19.9282 216.132H27.7678H85.591H94.4527L94.5328 217.104C94.6082 218.019 93.9274 218.822 93.0122 218.898C92.9668 218.901 92.9212 218.903 92.8756 218.903H22.9309C21.3475 218.903 20.02 217.707 19.856 216.132H19.9282Z"
                fill="#366E92"
            />
            <path d="M23.2408 206.155L23.4038 208.372H19.198L18.9698 206.155H23.2408Z" fill="#366E92" />
            <path
                d="M29.7943 214.282L23.4258 210.589H18.7707C18.4646 210.589 18.2164 210.837 18.2164 211.143C18.2164 211.163 18.2174 211.182 18.2195 211.202L18.9217 217.853C18.9515 218.135 19.1893 218.349 19.4729 218.349H28.8359L29.7943 214.282Z"
                fill="#366E92"
            />
            <path
                d="M52.3132 181.784C52.4158 181.805 52.4808 181.976 52.5074 182.255L54.8073 216.686H52.1218L49.8219 182.255C49.7953 181.976 49.9548 181.768 50.1542 181.768C51.5251 181.765 52.2447 181.77 52.3132 181.784Z"
                fill="#366A8D"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M104.297 198.964L54.4183 198.95C53.806 198.949 53.3096 199.445 53.3094 200.058C53.3094 200.058 53.3094 200.058 53.3101 200.058L53.3144 215.563C53.3152 216.175 53.8112 216.671 54.4232 216.672L104.302 216.686C104.914 216.687 105.41 216.19 105.41 215.578C105.41 215.578 105.41 215.578 105.41 215.578L105.406 200.073C105.405 199.461 104.909 198.965 104.297 198.964Z"
                fill="#2291DA"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M105.429 211.702L53.3339 211.698L53.3377 215.575C53.3383 216.186 53.8344 216.682 54.4462 216.682L104.325 216.686C104.937 216.686 105.433 216.19 105.433 215.578C105.433 215.577 105.433 215.577 105.433 215.576L105.429 211.702Z"
                fill="#366E92"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M79.8095 207.265L57.6393 207.264C57.0283 207.264 56.5329 207.759 56.5329 208.37C56.5329 208.37 56.5329 208.371 56.5329 208.371V208.372C56.5335 208.983 57.0296 209.479 57.6413 209.479L79.8115 209.481C80.4226 209.481 80.9179 208.985 80.918 208.374C80.918 208.374 80.918 208.374 80.918 208.373V208.373C80.9173 207.761 80.4213 207.265 79.8095 207.265Z"
                fill="#366E92"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M72.0468 201.723L57.6358 201.721C57.0246 201.721 56.5291 202.217 56.529 202.828C56.529 202.828 56.529 202.828 56.529 202.829C56.5295 203.441 57.0256 203.936 57.6374 203.936L72.0484 203.938C72.6596 203.938 73.1552 203.443 73.1552 202.831C73.1552 202.831 73.1552 202.831 73.1552 202.83C73.1548 202.219 72.6587 201.723 72.0468 201.723Z"
                fill="#366E92"
            />
            <path
                d="M35.9679 188.419C36.0257 188.419 36.0835 188.441 36.1298 188.475L41.1937 193.001C41.2862 193.091 41.2977 193.238 41.2052 193.328C41.159 193.373 41.0896 193.407 41.0318 193.407C40.974 193.407 40.9162 193.385 40.87 193.351L35.8061 188.825C35.702 188.735 35.702 188.588 35.7945 188.498C35.8407 188.441 35.8985 188.419 35.9679 188.419Z"
                fill="#78DCF8"
            />
            <path
                d="M33.0444 186.202C33.1051 186.202 33.1659 186.221 33.2145 186.251L34.9283 187.512C35.0256 187.591 35.0377 187.718 34.9405 187.796C34.8919 187.835 34.8189 187.865 34.7582 187.865C34.6974 187.865 34.6366 187.845 34.588 187.816L32.8742 186.554C32.7648 186.476 32.7648 186.348 32.862 186.27C32.9107 186.231 32.9714 186.202 33.0444 186.202Z"
                fill="#78DCF8"
            />
            <path
                d="M37.9159 195.624C37.9781 195.624 38.0402 195.651 38.0899 195.691L40.3512 197.913C40.4506 198.021 40.463 198.195 40.3636 198.302C40.3139 198.355 40.2394 198.395 40.1772 198.395C40.1151 198.395 40.053 198.369 40.0033 198.329L37.742 196.106C37.6426 195.999 37.6302 195.825 37.7296 195.718C37.7793 195.651 37.8538 195.624 37.9159 195.624Z"
                fill="#78DCF8"
            />
            <path
                d="M33.55 190.636C33.6077 190.636 33.6654 190.661 33.7115 190.698L37.668 194.619C37.7603 194.719 37.7718 194.882 37.6795 194.982C37.6334 195.032 37.5642 195.07 37.5065 195.07C37.4488 195.07 37.3912 195.045 37.345 195.007L33.3885 191.087C33.2847 190.987 33.2847 190.824 33.377 190.724C33.4231 190.673 33.4923 190.636 33.55 190.636Z"
                fill="#78DCF8"
            />
            <path
                d="M83.8182 225C80.1448 225 77.167 222.022 77.167 218.349C77.167 214.676 80.1448 211.698 83.8182 211.698C87.4915 211.698 90.4693 214.676 90.4693 218.349C90.4693 222.022 87.4915 225 83.8182 225Z"
                fill="#386582"
            />
            <path
                d="M83.8182 222.229C81.6754 222.229 79.9383 220.492 79.9383 218.349C79.9383 216.206 81.6754 214.469 83.8182 214.469C85.9609 214.469 87.698 216.206 87.698 218.349C87.698 220.492 85.9609 222.229 83.8182 222.229Z"
                fill="#F1F4F8"
            />
            <path
                d="M33.9348 225C30.2615 225 27.2836 222.022 27.2836 218.349C27.2836 214.676 30.2615 211.698 33.9348 211.698C37.6081 211.698 40.5859 214.676 40.5859 218.349C40.5859 222.022 37.6081 225 33.9348 225Z"
                fill="#386582"
            />
            <path
                d="M33.9348 222.229C31.792 222.229 30.055 220.492 30.055 218.349C30.055 216.206 31.792 214.469 33.9348 214.469C36.0775 214.469 37.8146 216.206 37.8146 218.349C37.8146 220.492 36.0775 222.229 33.9348 222.229Z"
                fill="#F1F4F8"
            />
        </svg>
    );
};

export default MaintenanceIllustration;