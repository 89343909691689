import { h } from 'preact'
import PointsLoader from '../../assets/icons/general/points-loader'

require(`./styles/${process.env.APPLICATION}/style.scss`);

import { useSelector } from 'react-redux'
import React from 'react';
const Loader = ({ message = 'loading game', customClass = 'loader' }) => {
    const gameDataLoading = useSelector(state => state?.gameData?.loading)
    const isLoading = gameDataLoading
    return (
        <>
            {isLoading && (
                <div className={customClass}>
                    <PointsLoader />
                    <p>{message}</p>
                </div>
            )}
        </>
    )
}

export default Loader
