import { PLATFORM } from '../../../../common/constants/game-constants'

import getUserDetailsWeb from './get-user-details-web'
import getUserDetailsXsell from './get-user-details-xsell'

export const userDetailsByPlatformMap = {
    [PLATFORM.Desktop]: getUserDetailsWeb,
    [PLATFORM.Android]: getUserDetailsWeb,
    [PLATFORM.Xsell]: getUserDetailsXsell,
    [PLATFORM.Ios]: getUserDetailsXsell
}

/**
 * get user details by platform context
 *
 * @param {string} platform
 * @returns {(function(): function(*): Promise<void>)} get user details action creator
 */
export default function getUserDetailsFactory(platform) {
    return userDetailsByPlatformMap[platform]
}
