import registerRealityCheck from './registers/register-reality-check';
import registerUseGeolocationVerify from './registers/register-use-geolocation-verify';
import registerFetchLocation from './registers/register-fetch-location';
import registerUseGeolocationTrigger from './registers/register-use-geolocation-trigger';
import registerUseGeocomplyPlc from './registers/register-use-geocomply-plc';
import registerUseGeocomplyLicense from './registers/register-use-geocomply-license';
import registerAppPlatform from './registers/register-app-platform';
import registerGeoComplyModal from './registers/register-geocomply-modal';
import registerWindowTitle from './registers/register-window-title';
import registerGetCurrentSessionDetails from './registers/register-get-current-session-details';
import registerGetUserDetails from './registers/register-get-user-details';
import registerSendToApptentive from './registers/register-send-apptentive';
import registerBalanceData from './registers/register-balance-data';

import registerGetServerTime from './registers/register-get-server-time';
import registerUseGeocomplyFlow from './registers/register-use-geocomply-flow';

/**
 * Register all services/functions with all their platform dependencies, inside the provided DI container
 */
const loadDependencies = () => {
    registerAppPlatform();

    registerGetCurrentSessionDetails();
    registerGetUserDetails();
    registerGetServerTime();
    // xsell does nothing
    registerFetchLocation();

    // web and android share the same functionality, and xsell does nothing
    registerUseGeocomplyLicense();

    registerUseGeocomplyPlc();

    // web and android share the same functionality, and xsell does nothing
    registerUseGeolocationTrigger();

    // web and android share the same functionality
    registerUseGeolocationVerify();

    registerUseGeocomplyFlow();

    // web and android share the same functionality, and xsell does nothing
    registerRealityCheck();

    registerGeoComplyModal();

    registerWindowTitle();

    registerSendToApptentive();

    registerBalanceData();
};

export default loadDependencies;
