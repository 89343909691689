export const DD_USE_CASES = {
    GLOBAL: 'GLOBAL',
    HOMEPAGE: 'HOMEPAGE',
    NOTIFICATION: 'NOTIFICATION',
    RM_GAME: 'RM_GAME',
    RM_EXIT_SCREEN: 'RM_EXIT_SCREEN',
    GAME_LAUNCHER: 'GAME_LAUNCHER'
} as const;

export type IddUseCases = keyof typeof DD_USE_CASES;
