import { useSelector } from 'react-redux';
import { getAppPlatform } from '../helpers/app-platform';
import {
    providerSelector,
    gameNameSelector,
    gameIdSelector,
    providerRefSelector,
    brandSelector,
    rgiSelector
} from '../../selectors/gameContext';
import { useEffect } from 'preact/hooks';
import { ddRumAction } from '../helpers/datadog-wrapper';
import { ddActionGameLaunchInit } from '../datadog/common/game-launch-init';
import { DD_USE_CASES } from '../datadog/enums';
import { RGIS } from '../constants';
import { ddActionRmGameLaunchInit } from '../datadog/reward-machine/rm-game-launch-init';

export const useLogGameLaunched = () => {
    const RGI = useSelector(rgiSelector);
    const brand = useSelector(brandSelector);
    const platform = getAppPlatform();
    const gameProvider = useSelector(providerSelector);
    const gameName = useSelector(gameNameSelector);
    const gameId = useSelector(gameIdSelector);
    const providerRef = useSelector(providerRefSelector);

    useEffect(() => {
        if (
            RGI &&
            brand &&
            platform &&
            gameProvider &&
            gameName &&
            gameId &&
            providerRef &&
            RGI !== RGIS.INCENTIVE_GAMES
        ) {
            if (RGI !== RGIS.INCENTIVE_GAMES) {
                ddRumAction(
                    ddActionGameLaunchInit(
                        {
                            brand,
                            gameId,
                            gameName,
                            gameProvider,
                            platform,
                            providerRef,
                            RGI
                        },
                        DD_USE_CASES.GAME_LAUNCHER
                    )
                );
            } else {
                ddRumAction(ddActionRmGameLaunchInit(DD_USE_CASES.RM_GAME));
            }
        }
    }, [brand, gameId, gameName, gameProvider, platform, providerRef, RGI]);
};
