import { useCallback } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { useOrientation } from '../../../common/hooks/useOrientation';
import {
    ERROR_CTA_EVENTS,
    ERROR_TYPE,
    ERROR_MESSAGES
} from '../../../game-window/contants/errors';

import { inGameErrorSelector } from '../../../selectors/game-window';
import { useLogTracking } from '../../../common/hooks/use-user-tracking/useUserTracking';
import { AMPLITUDE_ERROR_EVENTS, MODULES, ZONES, getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';

export const BackToLastGameButton = ({ buttonMessage, handleDismiss, buttonStyle }) => {
    const { deviceType } = useOrientation();
    const { errorType } = useSelector(inGameErrorSelector);

    const gameLoaded = useSelector((state: unknown) => state?.['gameWindow']?.gameLoaded);
    const buttonText = gameLoaded ? buttonMessage : ERROR_MESSAGES[ERROR_TYPE.GAME_IN_PROGRESS].main.loadingMessage;
    const { logTrackingComponent } = useLogTracking();
    const handleDismissButton = useCallback(() => {
        if (gameLoaded) {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: AMPLITUDE_ERROR_EVENTS.ERROR_CLICKED,
                [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD,
                [getAmplitudeKey('MODULE')]: MODULES.GW_ERROR,
                [getAmplitudeKey('ERROR_TYPE')]: errorType,
                [getAmplitudeKey('LINK_TEXT')]: ERROR_CTA_EVENTS.DISMISS,
                [getAmplitudeKey('LINK_URL')]: undefined

            });
            handleDismiss();
        }
    }, [errorType, handleDismiss, gameLoaded, logTrackingComponent]);

    return (
        <button
            onClick={handleDismissButton}
            className={`back-to-last-game-button ${deviceType} ${buttonStyle} `}
            disabled={!gameLoaded}
        >
            {buttonText}
        </button>
    );
};
