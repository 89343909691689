const WarningIcon = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.875 22C16.875 22.6213 17.3787 23.125 18 23.125C18.6213 23.125 19.125 22.6213 19.125 22V10C19.125 9.37868 18.6213 8.875 18 8.875C17.3787 8.875 16.875 9.37868 16.875 10V22ZM16.875 26C16.875 26.6213 17.3787 27.125 18 27.125C18.6213 27.125 19.125 26.6213 19.125 26C19.125 25.3787 18.6213 24.875 18 24.875C17.3787 24.875 16.875 25.3787 16.875 26ZM1.4974 16.4974C0.667534 17.3273 0.667534 18.6727 1.4974 19.5026L16.4974 34.5026C17.3273 35.3325 18.6727 35.3325 19.5026 34.5026L34.5026 19.5026C35.3325 18.6727 35.3325 17.3273 34.5026 16.4974L19.5026 1.4974C18.6727 0.667534 17.3273 0.667534 16.4974 1.4974L1.4974 16.4974ZM32.8232 18L18 32.8232L3.17678 18L18 3.17678L32.8232 18Z"
                fill="#E44242"
                stroke="#E44242"
                stroke-width="0.25"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default WarningIcon;
