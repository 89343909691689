import platform from 'platform-detect';
import { userOS } from '../constants';

const getUserOS = () => {
    const os = userOS.WINDOWS;

    if (platform.macos) {
        return userOS.MAC;
    }
    if (platform.android) {
        return userOS.ANDROID;
    }
    //Q: linux ppl

    return os;
};

const getUserChannel = () => {
    return platform.formfactor; //https://www.npmjs.com/package/platform-detect/v/2.0.2 ; phone, tablet, desktop or tv
};

// our required returned values are desktop, tablet, ios or android. now we don't wrap for ios, so only android is required.
const getTrendingGamesChannel = () => (getUserChannel() === 'desktop' ? 'desktop' : 'android');

export { getUserOS, getUserChannel, getTrendingGamesChannel };
