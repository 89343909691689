import ExitButton from '../../../GameWindow/components/ExitButton/ExitButton';
import { h } from 'preact';
import { GenericLoadingScreenProps } from '../types';
import { DefaultLogo } from './icons/DefaultLogo';
import { Dots } from './progress/Dots';
import { ProgressBar } from './progress/ProgressBar';
import { ZONES } from '../../../../common/hooks/use-user-tracking/types';
import React, { useEffect, useState } from 'react';
import { isGameWindowDisabled } from '../../../../common/wrapper-bridge-mobile';

export const GenericLoadingScreen = ({ ready, loadingText, counter, theme }: GenericLoadingScreenProps) => {
    require(`./styles/GenericLoadingScreen.scss`);

    const [logo, setLogo] = useState(DefaultLogo)

    const secondaryLogo = (<img src={theme?.body?.[0]?.items?.[0]?.logo?.url} />);      
    
    ready && useEffect(() => {
        if (theme?.loading_theme?.toLowerCase() === 'translate in'){
            setTimeout(() => {
                setLogo(secondaryLogo)
                console.log(logo)
            }, 1500)
        }
    }, [logo, setLogo])

    const getGradient = () => {
        const toColor = theme?.body?.[0]?.items?.[0]?.to_color;
        const fromColor = theme?.body?.[0]?.items?.[0]?.from_color;
        
        return `linear-gradient(131deg, #${process.env.BRAND === 'mohegansun' ? "051725" : "61158d"} 0%, #${process.env.BRAND === 'mohegansun' ? "051725" : "005FC8"} 33%, ${fromColor} 66%, ${toColor} 100%) 0 0/300% 300%`
    }
    
    return (
        <div 
            className={`${process.env.BRAND}-loading-page__container ${ready && theme?.loading_theme?.toLowerCase()=== 'translate in' ? 'bjgo' : ''}`} 
            id="default-loader-container" 
            style={{ background: getGradient() }}
        >
            {!isGameWindowDisabled() && <ExitButton direction={'row'} size={'2'} style={{ position: 'absolute', top: 20, right: 20 }} zoneName={ZONES.IN_GAME} />}
            <div className="fanduel-logo">
                {logo}
            </div>
            {theme?.progress_bar_type === 'Dots' || !ready ? <Dots /> : <ProgressBar progress={`${counter}`} loadingText={loadingText} />}
            {theme?.body?.[0]?.items?.[0]?.message ? <p> {theme?.body?.[0]?.items?.[0]?.message}</p> : <p>&nbsp;</p>}
        </div>
    )
};
