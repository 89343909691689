import { createSelector } from 'reselect';

export const gameClientStatusSelector = (state: AppState) => state.gameClientStatus;

export const clientInfoSelector = createSelector(gameClientStatusSelector, ctx => ctx);

export type AppState = {
    gameClientStatus: {
        clientReady: boolean;
        handshake: boolean;
    };
};
