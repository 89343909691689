import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLicenseCardVisible } from '../../../redux/actions/game-window';
import { licenseCardVisibleSelector, licenseNumbersSelector, licenseRegulatorSelector } from '../../../selectors/game-window';

require(`./styles/${process.env.APPLICATION}/style.scss`);

interface LicenseCardProps {
    isPortrait: boolean;
}

export const LicenseCard = ({ isPortrait }: LicenseCardProps) => {
    const { t } = useTranslation();
    const licenseCardVisible = useSelector(licenseCardVisibleSelector);
    const dispatch = useDispatch();
    const title = `${t('gameWindow.licenseModal.title')}`;
    const closeButton = `${t('gameWindow.licenseModal.close')}`;
    const licenseNumbers = useSelector(licenseNumbersSelector);
    const regulator = useSelector(licenseRegulatorSelector);
    const licenseTemplate =
        licenseNumbers && licenseNumbers.split(', ')?.length > 1
            ? 'gameWindow.licenseModal.content_plural'
            : 'gameWindow.licenseModal.content';

    const licenseString = t(licenseTemplate, { regulator });

    const deviceType = () => {
        if (isPortrait) {
            return 'portrait';
        }
        return screen?.orientation?.angle > 0 ? 'landscape' : 'desktop';
    };

    const handleClose = useCallback(() => dispatch(setLicenseCardVisible(false)), [dispatch]);

    return (
        licenseCardVisible && (
            <div className="games-modal general license-card">
                <div className={`license-card-container ${deviceType}`}>
                    <div className="license-card-wrapper">
                        <div className={`license-card-header ${deviceType}`}>{title}</div>
                        <div className={`license-card-body ${deviceType}`}>
                            {licenseString}
                            <span className="license-number">{licenseNumbers}</span>
                        </div>
                    </div>
                    <div className="license-card-footer">
                        <div onClick={handleClose} className={`close-button ${deviceType}`}>
                            {closeButton}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};