import { types } from '../../types';

const initialState = {
    rgi: undefined,
    brand: undefined,
    gameName: undefined,
    gameId: undefined,
    provider: undefined,
    providerRef: undefined,
    ganId: undefined,
    region: undefined,
    overallStatus: undefined,
    gameUid: undefined
};

export const gameContextReducer = (state = initialState, action) => {
    if (action.type === types.SET_GAME_CONTEXT) {
        return {
            ...state,
            ...action.payload
        };
    }

    return state;
};
