import { useDispatch, useSelector } from 'react-redux';
import { showExitPopupRecentlyPlayed } from '../../../../redux/actions/exit-popup';

require(`./styles/${process.env.APPLICATION}/style.scss`);

import { useOrientation } from '../../../../common/hooks/useOrientation';
import { useCallback, useMemo } from 'preact/hooks';
import { setNextGameId, setNextGameURL } from '../../../../redux/actions/exit-popup/exit-popup-recently-played';
import { getRecentlyPlayedUrl } from '../../../../common/helpers/get-recently-played-url';

import { SHOWOFF_ELEMENTS } from '../../../../common/hooks/use-showoff/useShowoff';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import PointsLoader from '../../../../assets/icons/general/points-loader';

const RecentlyPlayedGameCard = props => {
    const dispatch = useDispatch();
    const { displayName, src, recalculateSize, updateUrl, generateGtm } = props;

    const handleClick = useCallback(() => {
        updateUrl();
        generateGtm();
        dispatch(showExitPopupRecentlyPlayed());
    }, [dispatch, updateUrl, generateGtm]);

    return (
        <div className="game-window-recently-played-card-content">
            <img
                src={src}
                onLoad={recalculateSize}
                onClick={handleClick}
                title={`${displayName}`}
                className="game-window-recently-played-card"
            />
            <span>{displayName}</span>
        </div>
    );
};

const RecentlyPlayed = ({ width, recalculateSize }) => {
    const { deviceType } = useOrientation();
    const dispatch = useDispatch();
    const { logTrackingComponent } = useLogTracking();

    const updateGameUrl = useCallback(
        item => {
            const gameUrl = getRecentlyPlayedUrl(item);
            dispatch(setNextGameId(item?.gameId));
            dispatch(setNextGameURL(gameUrl));
        },
        [dispatch]
    );

    const recentlyPlayedItems = useSelector(state => state?.recentlyPlayedReducer?.userActivityData);
    const isRecentlyPlayedError = useSelector(state => state?.recentlyPlayedReducer?.error) === 'Error';

    const recentlyPlayed = useMemo(
        () =>
            recentlyPlayedItems && recentlyPlayedItems.length > 0
                ? recentlyPlayedItems?.map(item => ({ ...item, gameId: item?.prismicCode }))
                : [],
        [recentlyPlayedItems]
    );

    const lastRecentlyPlayedItems = recentlyPlayed?.slice(1, 5);

    const title = 'Recently Played Games';
    const contentWidth = deviceType === 'portrait' ? '100%' : '420px';
    const contentHeight = deviceType === 'portrait' ? '162px' : '100%';

    const handleClick = useCallback((item, index) => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'Clicked Play Now',
            [getAmplitudeKey('LINK_URL')]: getRecentlyPlayedUrl(item),
            [getAmplitudeKey('POSITION')]: index + 1,
            [getAmplitudeKey('MODULE')]: MODULES.RECENTLY_PLAYED,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD

        });
    }, []);

    if (lastRecentlyPlayedItems?.length === 0) {
        return null;
    }
    
    return (
        !isRecentlyPlayedError && (
            <div
                className={`card-wrapper ${deviceType !== 'desktop' ? 'mobile' : ''}`}
                style={{ width: deviceType === 'portrait' ? width : '', flex: 1 }}
                data-showoff={SHOWOFF_ELEMENTS.RECENTLY_PLAYED_CARD}
                data-showoff-badge={'false'}
            >
                <div className={`card-title ${deviceType} content-row-value`}>{title} </div>
                <div className={`card-content ${deviceType} recently-played-content`} style={{ height: '100%' }}>
                    {lastRecentlyPlayedItems?.length > 0 ? (
                        <div
                            className={`content-row-value recently-played-container-${lastRecentlyPlayedItems.length}`}
                            id="game-window-recently-played-container"
                            style={{ width: '100%', height: '100%' }}
                        >
                            {lastRecentlyPlayedItems.map((item, index) => {
                                return (
                                    <RecentlyPlayedGameCard
                                        key={item?.gameLaunchCode}
                                        displayName={item?.displayName}
                                        src={item?.imagePath}
                                        recalculateSize={recalculateSize}
                                        updateUrl={() => updateGameUrl(item)}
                                        generateGtm={() => handleClick(item, index)}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div
                            className={'loader-container'}
                            style={{
                                width: contentWidth,
                                height: contentHeight
                            }}
                        >
                            <PointsLoader />
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default RecentlyPlayed;
