import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IRmCustomerPackageFailed {
    error: Record<string, unknown>;
}

export const ddActionRmCustomerPackageFailed = (
    { ...context }: IRmCustomerPackageFailed,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'RM_CUSTOMER_PACKAGE_FAILED',
    description: 'RM customer package failed from customer-data',
    context,
    use_case
});
