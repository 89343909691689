import { datadogRum } from '@datadog/browser-rum';
import connectionType from '../../../common/helpers/connectionType';
import { gameIdSelector, gameNameSelector } from '../../../selectors/gameContext';
import { logTrackingEvent } from '../../../common/helpers/user-tracking.helpers';
import { getAmplitudeKey } from '../../../common/hooks/use-user-tracking/types';
import { gameProviderNameSelector } from '../../../selectors/game-window';

export const GEOCOMPLY_PLC_ERROR = 'GEOCOMPLY_PLC_ERROR';

// Action Creators
export const plcError = error => (dispatch, getState) => {
    const gameName = gameNameSelector(getState());
    const gameProviderName = gameProviderNameSelector(getState());
    const gameId = gameIdSelector(getState());
    datadogRum.addError(
        new Error(error.message),
        Object.assign(
            {
                type: 'PLC',
                code: error.errorCode,
                connectionType: connectionType()
            },
            error
        ),
        'custom'
    );

    logTrackingEvent({
        [getAmplitudeKey('GAME_ID')]: gameId,
        [getAmplitudeKey('GAME_NAME')]: gameName,
        [getAmplitudeKey('GAME_PROVIDER')]: gameProviderName,
        [getAmplitudeKey('EVENT')]: 'Game Launch Failed',
        [getAmplitudeKey('COMPONENT')]: 'geocomply',
        [getAmplitudeKey('PAGE_NAME')]: 'game_window',
        [getAmplitudeKey('FULL_URL')]: window.location.href,
        [getAmplitudeKey('PAGE_PATH')]: window.location.pathname
    });

    dispatch({
        type: GEOCOMPLY_PLC_ERROR,
        payload: error
    });
};

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        error: action.payload,
        loading: false
    };
};
