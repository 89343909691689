export type Icon = "error" | "warning" | "info";

export type PopupFooter = {
  description: string;
  action?: () => void;
};

export type Action = {
  title: string;
  handler: PopupAction;
  handlerParams?: string
};

export const POPUP_PRIMARY_ACTIONS = {
  REDIRECT_REALITY_CHECK_URL: "REDIRECT_REALITY_CHECK_URL"
} as const;

export const POPUP_DISMISS_ACTIONS = {
  DISMISS_POPUP: "DISMISS_POPUP",
  DISMISS_REALITY_CHECK: "DISMISS_REALITY_CHECK"
} as const;

export type PopupPrimaryActions = keyof typeof POPUP_PRIMARY_ACTIONS;

export type PopupDismissActions = keyof typeof POPUP_DISMISS_ACTIONS;

export type PopupAction = PopupPrimaryActions | PopupDismissActions;

export type Buttons = {
  primaryAction?: Action;
  dismissAction?: Action;
};

export type PopupConfig = {
  icon: boolean,
  title: string;
  description: string;
  buttons?: Buttons;
  footer?: PopupFooter;
};
