export const GEOCOMPLY_LOCATION_VERIFY_FAILED = 'GEOCOMPLY_LOCATION_VERIFY_FAILED'

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        error: action.payload,
        verified: false,
        loading: false
    }
}
// Action Creators
export const verifyLocationFailed = error => async dispatch => {
    console.warn('LOCATION VERIFY FAILED', error)

    dispatch({
        type: GEOCOMPLY_LOCATION_VERIFY_FAILED,
        payload: error
    })
}
