const PromotionsIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 33 33" fill="none">
            <rect x="0.731781" y="1.35825" width="31.0448" height="31.0448" rx="15.5224" stroke="white" stroke-width="1.19403" />
            <path d="M22.2245 16.8806V24.3433H10.2842V16.8806" stroke="white" stroke-width="0.895522" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.7164 13.1493H8.79102V16.8806H23.7164V13.1493Z" stroke="white" stroke-width="0.895522" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.2539 24.3433V13.1493" stroke="white" stroke-width="0.895522" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.2542 13.1493H12.8959C12.4011 13.1493 11.9266 12.9528 11.5767 12.6029C11.2268 12.253 11.0303 11.7784 11.0303 11.2836C11.0303 10.7888 11.2268 10.3143 11.5767 9.96441C11.9266 9.61453 12.4011 9.41797 12.8959 9.41797C15.5079 9.41797 16.2542 13.1493 16.2542 13.1493Z" stroke="white" stroke-width="0.895522" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.2539 13.1493H19.6121C20.1069 13.1493 20.5815 12.9528 20.9313 12.6029C21.2812 12.253 21.4778 11.7784 21.4778 11.2836C21.4778 10.7888 21.2812 10.3143 20.9313 9.96441C20.5815 9.61453 20.1069 9.41797 19.6121 9.41797C17.0002 9.41797 16.2539 13.1493 16.2539 13.1493Z" stroke="white" stroke-width="0.895522" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default PromotionsIcon;
