import { useSelector } from 'react-redux'
import { getAWCookies, removeAWCookies } from './cookies'

const isLoggedIn = () => {
    const { authToken } = getAWCookies()
    const mfaEnabled = useSelector(state => state?.session?.mfa?.enabled)
    const isMfaRequired = useSelector(state => state?.session?.mfa?.mandatory)

    if (isMfaRequired && !mfaEnabled) {
        removeAWCookies()
        return false
    }

    return !!authToken
}

export default isLoggedIn
