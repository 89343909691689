import ResponsibleGamingIconDefault from "../../assets/icons/error-popups/ResponsibleGamingIconDefault";
import ResponsibleGamingIconPA from "../../assets/icons/error-popups/ResponsibleGamingIconPA"
import ResponsibleGamingIconNJ from "../../assets/icons/error-popups/ResponsibleGamingIconNJ"
import ResponsibleGamingIconWV from "../../assets/icons/error-popups/ResponsibleGamingIconWV"

export const getIconRegion = (region) => {
    const icons = new Map([
        ['pa', <ResponsibleGamingIconPA key={'icon'} size={32} />],
        ['nj', <ResponsibleGamingIconNJ key={'icon'} size={32} />],
        ['wv', <ResponsibleGamingIconWV key={'icon'} size={32} />],
    ])
    return icons.get(region) ?? <ResponsibleGamingIconDefault size={32} />
};
