import { h } from 'preact'

const AppleLogo = () => (
    <svg
        width="40px"
        height="49px"
        viewBox="0 0 40 49"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <title>Technologies and Extensions/Apple ID/Logos (for Left-Algined Button)/White Logo/Medium</title>
        <g id="desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="Geocomply---static---download"
                transform="translate(-390.000000, -431.000000)"
                fill="#FFFFFF"
                fill-rule="nonzero"
            >
                <g
                    id="Technologies-and-Extensions/Apple-ID/Logos-(for-Left-Algined-Button)/White-Logo/Medium"
                    transform="translate(390.000000, 431.000000)"
                >
                    <path
                        d="M20.8682363,11.6769231 C23.0347971,11.6769231 25.7506267,10.212206 27.3679185,8.25924984 C28.8326356,6.48938334 29.9006585,4.01767324 29.9006585,1.54596313 C29.9006585,1.21029879 29.8701435,0.874634456 29.8091136,0.6 C27.3984334,0.691544819 24.4995142,2.2172918 22.7601626,4.26179275 C21.3869903,5.81805467 20.1358778,8.25924984 20.1358778,10.7614749 C20.1358778,11.1276542 20.1969077,11.4938334 20.2274226,11.6158932 C20.3799973,11.6464081 20.6241168,11.6769231 20.8682363,11.6769231 Z M13.2395014,48.6 C16.1994506,48.6 17.511593,46.6165289 21.2039007,46.6165289 C24.9572383,46.6165289 25.7811416,48.5389701 29.0767551,48.5389701 C32.3113387,48.5389701 34.4778994,45.548506 36.5224004,42.6190718 C38.8110208,39.2624285 39.756984,35.966815 39.8180138,35.8142403 C39.6044093,35.7532104 33.4098765,33.2204704 33.4098765,26.1104895 C33.4098765,19.9464717 38.2922669,17.1696122 38.5669013,16.9560076 C35.3323177,12.3177368 30.4194124,12.1956771 29.0767551,12.1956771 C25.4454773,12.1956771 22.4855281,14.3927527 20.6241168,14.3927527 C18.6101308,14.3927527 15.9553311,12.3177368 12.8122923,12.3177368 C6.83136413,12.3177368 0.758891144,17.261157 0.758891144,26.5987285 C0.758891144,32.3965671 3.01699667,38.5300699 5.79385618,42.4970121 C8.17402147,45.8536554 10.2490374,48.6 13.2395014,48.6 Z"
                        id=""
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default AppleLogo
