import { RootState } from '../../store';
import { PopupConfig } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface PopupState {
    visible: boolean;
    config?: PopupConfig;
}

const initialState: PopupState[] = [];

export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        showPopup: (state, action: PayloadAction<PopupConfig>) => {
            state.push({ config: action.payload, visible: true });
        },
        hidePopup: state => {
            state.pop();
        }
    }
});

export const { hidePopup, showPopup } = popupSlice.actions;

export const selectPopupVisible = (state: RootState) => {
    const stateCopy = [...state.popup]
    return stateCopy.pop()?.visible
};
export const selectPopupConfig = (state: RootState) => {
    const stateCopy = [...state.popup]
    return stateCopy.pop()?.config
};

const popupReducer = popupSlice.reducer;
export default popupReducer;
