import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { LOADING_STATES, LoadingState, LoadingTextMap } from '../types';
import { types } from '../../../../redux/types';
import { loadingDataSelector, loadingPercentSelector, showGameWindowSelector } from '../../../../selectors/game-window';

export const useLoadingStates = () => {
    const dispatch = useDispatch();

    const geocomplyState = useSelector(state => state?.['geocomply']);
    const gameDataLoading = useSelector(state => state?.['gameData']?.loading);
    const loadingPercent = useSelector(loadingPercentSelector);
    const { gameLoaded, gameWindowUILoading, gameWindowUI, gameWindowUIError } = useSelector(state => state?.['gameWindow']);
    const showGameWindow = useSelector(showGameWindowSelector);
    const customLoading = useSelector(loadingDataSelector);

    const [counter, setCounter] = useState(0);
    const [loadingState, setLoadingState] = useState<LoadingState>(LOADING_STATES.VERIFYING_LOCATION);

    const intervalRef = useRef([]);
    const loadingGameTickerRef = useRef([]);
    const verifyingLocationTickerRef = useRef([]);

    const loadingText = useMemo(() => {
        const text: LoadingTextMap = {
            [LOADING_STATES.VERIFYING_LOCATION]: 'Verifying location...',
            [LOADING_STATES.FETCHING_GAME]: 'Fetching game...',
            [LOADING_STATES.LOADING_GAME]: 'Loading game...'
        };

        return text[loadingState];
    }, [loadingState]);

    useEffect(() => {
        if (gameWindowUI && !gameWindowUILoading && !gameWindowUIError) {
            if (customLoading?.id && !customLoading?.['data']) {
                dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
            } else {
                dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: true });
            }

            // force hide loading screen when game window is off
            if (!showGameWindow) {
                dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
            }
        }
    }, [dispatch, showGameWindow, customLoading, gameWindowUILoading, gameWindowUI, gameWindowUIError]);

    // fallback tickers | 120s for verifying location | 25s for loading game
    useEffect(() => {
        verifyingLocationTickerRef.current.forEach(ticker => {
            clearTimeout(ticker);
        });

        if (loadingState === LOADING_STATES.VERIFYING_LOCATION) {
            verifyingLocationTickerRef.current.push(
                setTimeout(() => {
                    dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
                }, 120000)
            );
        }

        loadingGameTickerRef.current.forEach(ticker => {
            clearTimeout(ticker);
        });

        if (loadingState === LOADING_STATES.LOADING_GAME && !gameLoaded) {
            loadingGameTickerRef.current.push(
                setTimeout(() => {
                    dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
                }, 25000)
            );
        }
    }, [dispatch, gameLoaded, loadingPercent, loadingState]);

    // update loading state -> displayed message
    useEffect(() => {
        if (geocomplyState.location?.loading) {
            setLoadingState(LOADING_STATES.VERIFYING_LOCATION);
        }

        if (gameDataLoading) {
            setLoadingState(LOADING_STATES.FETCHING_GAME);
        }

        if (geocomplyState.location?.verified && !gameDataLoading) {
            setLoadingState(LOADING_STATES.LOADING_GAME);
        }

        if (geocomplyState.licence?.error || geocomplyState?.location?.error) {
            dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
        }
    }, [
        dispatch,
        gameDataLoading,
        geocomplyState.licence?.error,
        geocomplyState.location?.error,
        geocomplyState.location?.loading,
        geocomplyState.location?.verified
    ]);

    // update loading counter
    useEffect(() => {
        if (loadingPercent && loadingPercent > counter) {
            setCounter(loadingPercent);
        }

        if (counter >= 99) {
            setCounter(99);
            intervalRef.current.forEach(interval => {
                clearInterval(interval);
            });
        }
    }, [counter, loadingPercent]);

    // dismiss loading screen when game is loaded
    useEffect(() => {
        if (gameLoaded) {
            dispatch({ type: types.SET_LOADING_SCREEN_VISIBILITY, payload: false });
        }
    }, [dispatch, gameLoaded, loadingPercent]);

    // increment counter
    useEffect(() => {
        intervalRef.current.push(
            setInterval(() => {
                setCounter(prev => prev + 1);
            }, 1000)
        );

        return () => {
            intervalRef.current.forEach(interval => {
                clearInterval(interval);
            });
        };
    }, []);

    return { counter, loadingText };
};
