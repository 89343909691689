import round from 'lodash/round'
import moment from 'moment'
import { fetchLicense } from './fetch-license'
import { fetchNewLicense } from './fetch-new-license'

let updateLicenseTimerId = null

export const scheduleFetchLicense = () => async (dispatch, getState) => {
    const expiry_date = getState().geocomply?.license.expiry_date
    const expires = moment.utc(expiry_date)

    const expiresDateTime = moment.utc(expires)
    const dateTimeNow = moment.utc()

    if (dateTimeNow.isBefore(expiresDateTime)) {
        // schedule to get new license after it expires and is in grace interval/ we have 5 minutes to get a new licence
        const scheduleIn = expiresDateTime.add(1, 'minutes') - dateTimeNow

        const timeInHours = scheduleIn > 3600000

        console.warn(
            `SCHEDULED LICENCE RENEW AT ${expiresDateTime.toDate()} - IN ${round(
                scheduleIn / 1000 / 60 / (timeInHours ? 60 : 1),
                1
            )} ${timeInHours ? 'hours' : 'minutes'}`
        )

        clearTimeout(updateLicenseTimerId)
        updateLicenseTimerId = setTimeout(() => {
            clearTimeout(updateLicenseTimerId)
            dispatch(fetchNewLicense())
        }, scheduleIn)
    } else {
        // license expired, lets get a new license
        console.warn('LICENSE EXPIRED, during schedule')
        dispatch(fetchLicense())
    }
}
