import { types } from '../../types';

export const initialState = {
    displayModal: false,
    nextGameUrl: undefined,
    nextGameId: undefined
};

const exitPopupRecentlyPlayed = (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW_EXIT_POPUP_RECENTLY_PLAYED:
            return {
                ...state,
                displayModal: true
            };
        case types.HIDE_EXIT_POPUP_RECENTLY_PLAYED:
            return {
                ...state,
                displayModal: false
            };
        case types.SET_NEXT_GAME_URL:
            return {
                ...state,
                nextGameUrl: action.payload
            };
        case types.SET_NEXT_GAME_ID:
            return {
                ...state,
                nextGameId: action.payload
            };
        default: {
            return state;
        }
    }
};

export default exitPopupRecentlyPlayed;
