import { datadogRum } from '@datadog/browser-rum';

import awAxios from '../../../../common/axios/AWAxios';
import { commonHeadersAW } from '../../../../common/commonHeaders';
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper';
import { fetchEnd, fetchStart } from '../../../fetch/fetch';
import config from '../../../../../config/config';
import { scheduleIpCheck } from './schedule-ip-check';
import { licenseClear } from '../geo-license';
import connectionType from '../../../../common/helpers/connectionType';
import { ddRumAction } from '../../../../common/helpers/datadog-wrapper';
import { ddActionIpChanged } from '../../../../common/datadog/ip/ip-changed';
import { DD_USE_CASES } from '../../../../common/datadog/enums';

export const fetchConnectionInfo = () => async (dispatch, getState) => {
    console.info('fetchConnectionInfo()');
    console.warn('CHECK IP');
    const requestUid = getRequestUid(FetchType.ConnectionInfo);

    dispatch(fetchStart(requestUid));

    try {
        const response = await awAxios.get(config.geoComply.endPoints.connectionInfo, { headers: commonHeadersAW });
        console.info('fetchConnectionInfo()', response?.data);

        dispatch(fetchEnd(requestUid));

        handleResponse(dispatch, getState, response);
    } catch (err) {
        datadogRum.addError(
            new Error(err),
            Object.assign(
                {
                    type: 'FETCH_CONNECTION_INFO',
                    connectionType: connectionType()
                },
                err
            ),
        );
        console.warn('GET CONNECTION INFO FAILED');

        dispatch(licenseClear());
    }
};

function handleResponse(dispatch, getState, response) {
    const licenseIp = getState().geocomply.location?.details?.ip_address;
    if (response?.data?.ip_address !== licenseIp) {
        //send custom action details to datadog
        ddRumAction(
            ddActionIpChanged(
                {
                    new_ip: response?.data?.ip_address,
                    license_ip: licenseIp,
                    details: getState().geocomply?.location?.details,
                    connectionType: connectionType()
                },
                DD_USE_CASES.GLOBAL
            )
        );
        //will trigger fetch license
        dispatch(licenseClear());
    } else if (!navigator.connection) {
        //schedule new IP check
        dispatch(scheduleIpCheck());
    }
}
