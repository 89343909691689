import { useSelector } from 'react-redux';
import { selectPopupConfig, selectPopupVisible } from '../../redux/reducers/popup/popup';
import { PopupActionContainer } from './PopupActionContainer';
import { PopupTitle } from './PopupTitle';
import { PopupBody } from './PopupBody';
import FanduelGeneralErrorIcon from '../../assets/icons/error-popups/FanduelGeneralErrorIcon';
import GeneralErrorIcon from '../../assets/icons/error-popups/GeneralErrorIcon';
import { useOrientation } from '../../common/hooks/useOrientation';
import { getBrand } from '../../common/helpers/brand';

require(`./styles/${process.env.APPLICATION}/style.scss`)


export const getErrorIcon = () => {
    const brand = getBrand()
    return brand === 'fanduel' ? <FanduelGeneralErrorIcon size={53} /> : <GeneralErrorIcon size={48} />;

};

export const Popup = () => {
    const visible = useSelector(selectPopupVisible);
    const config = useSelector(selectPopupConfig);
    const { deviceType } = useOrientation();

    const displayPopup = visible && config

    return displayPopup && (
        <div className="games-modal general generic-popup" >
            <div className={`generic-popup-container ${deviceType}`}>
                <div className={`generic-popup-main-window`}>
                    <div className="error-icon">{config.icon && getErrorIcon()}</div>
                    <div className="generic-popup-wrapper ">
                        <div className="generic-popup-title">
                            {config.title && <PopupTitle title={config.title} />}
                        </div>
                        {config.description && <PopupBody description={config.description} />}
                    </div>
                    <div className={`generic-popup-actions ${deviceType}`}>
                        {config.buttons && (
                            <PopupActionContainer
                                dismissAction={config.buttons.dismissAction}
                                primaryAction={config.buttons.primaryAction}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
