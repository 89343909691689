import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IRmCustomerPackageRequest {
    requestData: Record<string, unknown>;
}

export const ddActionRmCustomerPackageRequest = (
    { ...context }: IRmCustomerPackageRequest,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'RM_CUSTOMER_PACKAGE_REQUEST',
    description: 'RM customer package requested from customer-data',
    context,
    use_case
});
