import { h } from 'preact';

export const MohegansunLogo = () => {
    return (
        <div id="logo-container">
            <svg width="144" height="134" viewBox="0 0 144 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M84.3687 43.3629L82.0301 38.234C82.0301 38.0009 81.5624 37.7677 81.5624 37.5346H81.3285L80.3931 37.0683L78.7561 36.6021C78.4691 36.7026 78.2227 36.8934 78.054 37.1457C77.8853 37.398 77.8034 37.6981 77.8206 38.0009C77.8206 38.9334 78.2883 38.9334 78.2883 39.8659C78.2883 40.7985 78.0545 40.5653 78.0545 41.4979V46.6268C78.0545 47.5593 78.2883 47.0931 78.2883 48.0256H79.6915L81.3285 46.86L82.0301 46.3937L84.1349 43.8292C84.2195 43.7346 84.2839 43.6238 84.3241 43.5035C84.3643 43.3833 84.3795 43.2561 84.3687 43.1298"
                    fill="#FFD851"
                />
                <path
                    d="M55.8389 30.0743V31.0068H56.0727V31.4731H59.1129C59.1436 31.4731 59.1741 31.4671 59.2024 31.4554C59.2308 31.4436 59.2566 31.4265 59.2783 31.4048C59.3 31.3832 59.3172 31.3575 59.329 31.3292C59.3407 31.3009 59.3468 31.2706 59.3468 31.24C59.5807 31.24 59.5807 31.0068 59.5807 30.7737H59.8145V30.3074C59.8145 30.0743 60.0484 30.0743 60.0484 29.8412V29.3749H59.8145C59.8145 29.1418 59.8145 28.9086 59.5807 28.9086C59.5807 28.8468 59.556 28.7875 59.5122 28.7438C59.4683 28.7001 59.4088 28.6755 59.3468 28.6755L58.8791 28.2092H56.5405L56.0727 28.6755C56.0727 28.7373 56.0481 28.7966 56.0042 28.8403C55.9604 28.8841 55.9009 28.9086 55.8389 28.9086V30.0743Z"
                    fill="white"
                />
                <path
                    d="M82.2646 30.0744V30.5406V31.0069C82.2646 31.0375 82.2706 31.0678 82.2824 31.0961C82.2941 31.1244 82.3114 31.1501 82.3331 31.1718C82.3548 31.1934 82.3806 31.2106 82.409 31.2223C82.4373 31.234 82.4677 31.24 82.4985 31.24L82.9662 31.7063H85.7725V31.24C85.7725 31.0069 86.0064 31.0069 86.0064 30.7738C86.1417 30.6542 86.2255 30.4872 86.2402 30.3075V29.8412C86.2255 29.6616 86.1417 29.4945 86.0064 29.375C86.0064 29.1418 85.7725 29.1418 85.7725 28.9087H83.4339C83.4339 29.1418 83.2 29.1418 82.9662 29.1418H82.0307C82.0307 29.375 81.7969 29.375 81.7969 29.6081V30.0744"
                    fill="white"
                />
                <path
                    d="M55.8389 55.4862C55.8389 55.7194 55.8389 55.9525 56.0727 55.9525V56.4188C56.3066 56.6519 56.3066 56.6519 56.3066 56.885H56.5405L57.0082 57.3513H59.1129C59.1129 57.1182 59.3468 57.1182 59.3468 56.885H60.0484C60.0484 56.6519 60.0484 56.6519 60.2823 56.4188V55.4862H60.5161C60.3808 55.3666 60.297 55.1996 60.2823 55.0199C60.0484 54.7868 60.0484 54.7868 60.0484 54.5537H59.8145L59.3468 54.0874H57.0082L56.5405 54.5537C56.5405 54.6155 56.5158 54.6748 56.472 54.7185C56.4281 54.7622 56.3686 54.7868 56.3066 54.7868C56.3066 55.0199 56.0727 55.0199 56.0727 55.2531V55.7194H55.8389"
                    fill="white"
                />
                <path
                    d="M82.0312 55.7194V56.1856C82.0312 56.4188 82.2651 56.4188 82.2651 56.6519C82.2799 56.8316 82.3637 56.9986 82.499 57.1182C82.561 57.1182 82.6205 57.1427 82.6643 57.1864C82.7082 57.2302 82.7328 57.2895 82.7328 57.3513H84.8376C84.8996 57.3513 84.9591 57.3267 85.003 57.283C85.0468 57.2393 85.0715 57.18 85.0715 57.1182H86.2408V55.7194V55.2531H86.0069C86.0069 55.0199 86.0069 55.0199 85.7731 54.7868V54.3205H84.8376C84.8376 54.2587 84.813 54.1994 84.7691 54.1557C84.7252 54.112 84.6658 54.0874 84.6037 54.0874H82.9667C82.9667 54.3205 82.7328 54.3205 82.7328 54.5537C82.6708 54.5537 82.6113 54.5782 82.5675 54.622C82.5236 54.6657 82.499 54.725 82.499 54.7868C82.2651 54.7868 82.2651 55.0199 82.2651 55.2531C82.1298 55.3727 82.046 55.5397 82.0312 55.7194Z"
                    fill="white"
                />
                <path
                    d="M65.894 36.136C66.8295 35.9029 66.8295 36.136 67.7649 36.3691H72.9099C73.6115 36.3691 73.6115 36.8354 74.5469 36.8354H76.8856V34.0378C76.6517 33.5715 76.6517 33.3384 76.4178 33.1052L75.2485 32.1727L74.5469 31.4733L72.9099 30.3076C71.5802 30.254 70.2485 30.3321 68.9342 30.5408H68.2326L66.5956 32.8721C66.5956 33.1052 66.3618 33.3384 66.3618 33.5715L65.6602 35.4366C65.6602 35.9029 65.894 36.136 66.1279 35.9029"
                    fill="#FFD851"
                />
                <path
                    d="M76.6516 49.8908C75.7162 49.6577 75.7162 50.124 74.7807 50.124C73.8453 50.124 74.0791 49.6577 73.1437 49.6577H69.6358C68.9342 49.6577 68.9342 50.124 67.9987 49.8908H66.3617C65.6601 49.8908 65.4262 49.6577 65.1924 50.124V50.8234L66.3617 52.4553C66.4255 52.8042 66.5877 53.1277 66.8294 53.3879L68.9342 55.0198H70.8051L75.2485 52.9216L75.95 52.4553L76.4178 51.7559V49.8908"
                    fill="#FFD851"
                />
                <path
                    d="M64.7243 46.394V44.5289C64.7243 43.8295 64.4905 43.8295 64.4905 42.8969V41.265C64.4905 40.3325 64.2566 40.3325 64.2566 39.3999C64.2566 38.4674 63.7889 38.7005 64.0228 37.768C64.2566 36.8354 64.2566 37.0686 63.555 36.8354H62.6196C62.3857 36.8354 61.918 37.0686 61.918 37.3017L60.7487 38.4674C60.7487 38.7005 60.2809 38.9337 60.2809 39.1668L59.1116 40.7987C59.0651 42.1242 59.1434 43.4511 59.3455 44.762C59.3455 44.9952 59.3455 45.4614 59.5794 45.4614L61.918 47.0934H62.6196L64.7243 47.7928V46.394Z"
                    fill="#FFD851"
                />
                <path
                    d="M73.6122 16.0863C73.9548 15.1842 74.1903 14.2453 74.3138 13.2887C74.3138 12.3561 73.8461 11.4236 73.8461 10.7242C73.8461 10.0248 74.0799 8.85911 74.0799 8.15971L73.3784 5.82836C73.3357 5.10857 73.1778 4.40022 72.9106 3.73015C72.6768 3.26388 72.2091 2.79761 72.2091 2.33135C72.2091 1.86508 72.2091 1.16567 72.2091 0.932537L71.7413 0.466266H71.5075C71.5075 0.404435 71.4828 0.34514 71.439 0.301419C71.3951 0.257698 71.3356 0.233135 71.2736 0.233135C71.2116 0.233135 71.1521 0.257698 71.1082 0.301419C71.0644 0.34514 71.0397 0.404435 71.0397 0.466266H70.8059C70.8166 0.59257 70.8014 0.719736 70.7612 0.839988C70.721 0.960241 70.6566 1.07106 70.572 1.16567V2.09821C70.2857 2.42121 70.1204 2.83312 70.1043 3.26388C70.1043 3.2945 70.0982 3.32482 70.0865 3.3531C70.0747 3.38139 70.0575 3.40709 70.0358 3.42873C70.0141 3.45038 69.9883 3.46756 69.9599 3.47927C69.9315 3.49099 69.9011 3.49702 69.8704 3.49702V3.96328C69.6366 4.66269 69.6366 5.36209 69.4027 6.0615C69.2093 6.82277 69.1305 7.60847 69.1688 8.39284L68.4673 10.9573C68.4673 11.6567 68.2334 12.5893 68.2334 13.5218C68.2334 14.4543 68.7011 15.3869 68.7011 16.3194C68.6627 17.1808 68.7414 18.0435 68.935 18.8839C69.1688 19.5833 68.935 20.5158 69.1688 21.2152C69.4027 21.9146 69.8704 22.3809 70.1043 23.0803C70.0666 23.6346 70.1463 24.1906 70.3382 24.7123C70.3382 25.1785 70.572 25.6448 70.572 26.1111L71.2736 26.5773C71.2736 26.3442 71.7413 26.3442 71.9752 25.8779C72.068 25.4671 72.2259 25.0736 72.4429 24.7123C72.4053 24.158 72.4849 23.6019 72.6768 23.0803C72.6768 22.3809 73.3784 21.9146 73.6122 21.2152C73.6564 20.4199 73.8139 19.635 74.0799 18.8839C74.0799 17.9514 73.6122 17.0188 73.6122 16.3194V16.0863ZM71.5075 0.233135V0V0.233135Z"
                    fill="#0073A5"
                />
                <path
                    d="M55.1364 42.4304H54.6686L53.7332 41.9641H52.5639C52.0962 41.9641 51.8623 41.4979 51.1607 41.2647L49.2898 40.7985L46.9512 40.3322L44.3787 39.3997L42.9755 39.8659H42.0401C41.3319 40.0228 40.5916 39.9408 39.9353 39.6328H38.9999C38.0644 39.6328 37.3628 40.3322 36.4274 40.3322H34.0887L32.6856 40.7985H31.2824L28.7099 41.4979H28.2422V41.9641H28.9438L29.6454 42.4304H31.0485L32.2178 43.1298L34.0887 43.5961L36.4274 44.2955H44.3787C45.2625 44.172 46.1269 43.937 46.9512 43.5961L49.0559 43.1298C49.7575 42.8967 50.693 43.3629 51.3946 43.1298H51.6284L53.2655 42.4304H55.1364Z"
                    fill="#BC2B30"
                />
                <path
                    d="M74.781 42.8967C74.781 42.6636 74.5471 42.4304 74.5471 42.1973C74.5003 41.7016 74.3403 41.2231 74.0794 40.7985H72.4423L71.273 39.3997H69.636C69.4021 39.3997 69.1683 39.3997 69.1683 39.6328L68.7006 40.3322C68.5765 40.3322 68.4575 40.3813 68.3698 40.4688C68.2821 40.5562 68.2328 40.6748 68.2328 40.7985L67.5312 41.731C67.5312 41.9641 67.7651 43.5961 67.7651 43.8292C67.7651 44.0624 67.7651 44.2955 67.999 44.2955H68.2328C68.4258 44.5557 68.6711 44.773 68.9529 44.9335C69.2347 45.0941 69.547 45.1944 69.8699 45.228H70.5715C70.6153 45.2777 70.6693 45.3174 70.7298 45.3446C70.7903 45.3718 70.8559 45.3859 70.9222 45.3859C70.9886 45.3859 71.0542 45.3718 71.1147 45.3446C71.1752 45.3174 71.2291 45.2777 71.273 45.228H71.9746L72.9101 44.7618L73.3778 44.2955L74.0794 43.5961H74.3132C74.4486 43.4765 74.5323 43.3095 74.5471 43.1298C74.781 42.8967 74.781 42.8967 74.781 42.6636"
                    fill="#BC2B30"
                />
                <path
                    d="M68.4667 26.8103C68.5852 25.9596 68.5852 25.0966 68.4667 24.2458C68.4234 23.3744 68.2662 22.5122 67.999 21.6813C67.9073 20.794 67.6703 19.9277 67.2974 19.1169C67.0262 18.3242 66.714 17.546 66.362 16.7855L65.8942 16.0861C65.6604 15.853 65.6604 15.3867 65.4265 15.3867C65.1927 15.3867 64.9588 15.3867 64.7249 15.6198C64.4911 15.853 64.4911 16.0861 64.2572 16.3193H63.5556C63.5556 16.5524 63.0879 16.7855 63.0879 17.0187V17.9512C63.0879 18.1843 63.5556 18.4175 63.5556 18.6506C63.5556 18.8837 64.2572 19.8163 64.4911 20.5157C64.7249 21.2151 64.4911 22.3808 64.7249 23.0802C64.9588 23.7796 65.6604 24.0127 65.8942 24.7121C66.019 25.2645 66.1751 25.8093 66.362 26.344V26.8103C66.327 27.1389 66.4098 27.4693 66.5958 27.7428H67.7651C67.999 27.7428 67.999 27.5097 68.2329 27.2766H68.4667V26.8103ZM68.4667 26.344V26.8103V26.344Z"
                    fill="#0073A5"
                />
                <path
                    d="M78.9903 67.3759C78.9903 67.1428 79.2242 66.6765 79.2242 66.4433C78.8174 65.79 78.5026 65.084 78.2887 64.3451C78.1566 63.7631 77.9188 63.21 77.5871 62.7132C77.1147 62.147 76.7214 61.5196 76.4178 60.8481C76.4178 60.3819 76.1839 60.1487 76.1839 59.4493V58.5168H75.9501C75.9501 58.3931 75.9008 58.2745 75.8131 58.1871C75.7254 58.0996 75.6064 58.0505 75.4824 58.0505H74.7808C74.7808 57.9887 74.7561 57.9294 74.7123 57.8857C74.6684 57.8419 74.6089 57.8174 74.5469 57.8174H74.0792C73.8453 58.0505 73.6115 58.0505 73.6115 58.2836C73.5617 58.3274 73.5218 58.3812 73.4945 58.4415C73.4672 58.5018 73.4531 58.5672 73.4531 58.6333C73.4531 58.6995 73.4672 58.7649 73.4945 58.8252C73.5218 58.8855 73.5617 58.9393 73.6115 58.9831C73.3776 59.9156 73.8453 60.615 73.8453 61.5475C73.8895 62.3428 74.047 63.1278 74.3131 63.8789L75.4824 66.4433C75.6062 67.3244 75.842 68.1861 76.1839 69.0078H76.6517L77.3533 69.4741H78.2887C78.3507 69.4741 78.4102 69.4495 78.4541 69.4058C78.4979 69.3621 78.5226 69.3028 78.5226 69.241C78.5846 69.241 78.6441 69.2164 78.6879 69.1727C78.7318 69.129 78.7564 69.0697 78.7564 69.0078H78.9903L79.458 68.3084C79.458 68.0753 78.9903 67.609 78.9903 67.3759Z"
                    fill="#006E49"
                />
                <path
                    d="M56.7731 38.7004C56.7731 38.4672 56.7731 38.4672 56.5393 38.4672C56.5393 38.2341 56.5393 38.2341 56.3054 38.2341H53.7329L51.862 37.0684L49.7572 36.369C49.0556 36.1359 48.5879 35.4365 48.1202 34.9702H45.7816C45.6727 35.115 45.5314 35.2325 45.3691 35.3134C45.2067 35.3944 45.0277 35.4365 44.8461 35.4365L44.1445 35.9028C44.332 36.3168 44.6646 36.6484 45.08 36.8353L45.5477 37.3016L47.8863 38.2341C48.503 38.7586 49.2186 39.1549 49.9911 39.3998H52.5636C53.3592 39.7958 54.2516 39.9576 55.1361 39.866H55.6038C55.8377 39.866 56.0715 39.866 56.0715 40.0992H56.3054C56.5393 40.0992 56.5393 39.866 56.5393 39.6329H56.7731C57.007 39.3998 56.7731 39.3998 56.7731 39.1666V38.7004Z"
                    fill="#BC2B30"
                />
                <path
                    d="M56.5397 45.9276L55.3704 45.2282C54.7396 45.0671 54.0711 45.1504 53.4995 45.4614H52.7979L50.2254 45.9276L47.8868 46.8602L45.0804 47.3264C44.8466 47.5596 44.8466 48.0258 44.6127 48.0258L43.9111 48.7252C43.9111 48.9584 44.3789 49.1915 44.3789 49.4246C44.3681 49.5509 44.3833 49.6781 44.4235 49.7983C44.4637 49.9186 44.5281 50.0294 44.6127 50.124C44.6127 50.3572 44.3789 50.8234 44.6127 51.0566H45.3143C45.782 51.2897 46.0159 50.8234 46.2498 50.8234H46.9513L49.0561 50.124H49.29L52.3302 48.4921L54.4349 47.5596L56.7736 47.0933H57.0074V46.3939C56.7736 46.3939 56.7736 46.1608 56.5397 45.9276Z"
                    fill="#BC2B30"
                />
                <path
                    d="M79.2246 17.252C79.2246 17.0188 79.2245 17.0188 78.9907 16.5526C78.7568 16.0863 79.4584 15.62 79.4584 15.3869C79.4584 15.1538 78.9907 14.9206 78.9907 14.6875H77.1198L76.4182 16.7857H76.1843C76.1843 17.0188 75.9505 17.252 75.7166 17.4851C75.3099 18.1385 74.9951 18.8445 74.7812 19.5833C74.5473 20.5159 74.5473 21.6815 74.3135 22.3809C74.2767 23.0884 74.3557 23.797 74.5473 24.4792C74.4976 24.5229 74.4577 24.5767 74.4304 24.637C74.4031 24.6973 74.389 24.7627 74.389 24.8289C74.389 24.895 74.4031 24.9604 74.4304 25.0207C74.4577 25.081 74.4976 25.1348 74.5473 25.1786H74.3135C74.3135 25.4117 74.5473 25.4117 74.5473 25.6448H75.015C75.015 25.878 75.015 25.878 75.2489 25.878H75.7166C75.8519 25.7584 75.9357 25.5913 75.9505 25.4117H76.1843C76.1991 25.232 76.2829 25.065 76.4182 24.9454C76.6521 24.246 76.6521 23.3135 76.8859 22.6141C77.0999 21.8753 77.4146 21.1692 77.8214 20.5159L78.523 18.4177C78.6481 17.6121 78.8842 16.8275 79.2246 16.0863V15.1538V17.252Z"
                    fill="#0073A5"
                />
                <path
                    d="M100.039 48.9583V48.492C99.8048 48.492 99.8048 48.0257 99.5709 47.7926L96.9984 46.86H93.9582C93.0534 46.5185 92.1115 46.2838 91.1519 46.1606H88.1116L87.1762 46.86H86.7085C86.4746 47.0932 86.4746 47.0932 86.4746 47.3263H86.9423C86.9423 47.5595 87.1762 47.5595 87.4101 47.5595L88.5794 48.0257H90.4503L92.555 49.1914L94.8937 49.8908L96.9984 50.8233H99.8048C100.039 50.8233 100.039 50.8233 100.039 50.5902C100.039 50.3571 100.273 50.3571 100.273 50.1239C100.283 49.9976 100.268 49.8705 100.228 49.7502C100.188 49.63 100.123 49.5191 100.039 49.4245"
                    fill="#823673"
                />
                <path
                    d="M74.0795 72.2718C74.0795 71.3392 73.6117 70.6398 73.6117 69.7073V67.1428C73.3779 66.4434 73.3779 65.5109 73.144 64.8115C72.9102 64.1121 72.9102 63.4127 72.6763 62.9464L71.9747 61.3145C71.9279 60.8188 71.7679 60.3403 71.507 59.9157C71.507 59.8538 71.4823 59.7945 71.4385 59.7508C71.3946 59.7071 71.3351 59.6825 71.2731 59.6825C71.2731 59.6207 71.2485 59.5614 71.2046 59.5177C71.1608 59.474 71.1013 59.4494 71.0393 59.4494C70.9772 59.4494 70.9177 59.474 70.8739 59.5177C70.83 59.5614 70.8054 59.6207 70.8054 59.6825H70.5715C70.3377 59.9157 70.1038 60.1488 70.1038 60.3819V61.7807C69.8699 62.247 69.8699 62.7133 69.6361 63.1796C69.4252 63.7774 69.3454 64.4134 69.4022 65.0446C69.0619 65.7859 68.8258 66.5704 68.7006 67.376C68.3277 68.1868 68.0907 69.0531 67.999 69.9404C68.0709 70.5801 68.2282 71.2074 68.4668 71.8055C68.2329 72.5049 68.2329 73.2043 67.999 73.6706V76.2351L68.7006 78.3333C68.9345 79.499 68.9345 80.4315 69.1683 81.364C69.1319 81.7669 69.2132 82.1717 69.4022 82.5297C69.4022 82.7628 69.4022 82.7628 69.6361 82.996L70.1038 84.1616C70.1884 84.2563 70.2528 84.3671 70.293 84.4873C70.3332 84.6076 70.3484 84.7347 70.3377 84.861C70.3377 84.9229 70.3623 84.9822 70.4062 85.0259C70.45 85.0696 70.5095 85.0942 70.5715 85.0942C70.6022 85.0942 70.6326 85.1002 70.661 85.1119C70.6894 85.1236 70.7152 85.1408 70.7369 85.1625C70.7586 85.1841 70.7758 85.2098 70.7876 85.2381C70.7993 85.2664 70.8054 85.2967 70.8054 85.3273H71.0393V84.861C71.0393 84.6279 71.2731 84.1616 71.2731 83.9285C71.2731 83.6954 71.7408 83.4622 71.7408 83.2291C71.8255 83.1345 71.8898 83.0237 71.93 82.9034C71.9703 82.7832 71.9854 82.656 71.9747 82.5297L72.6763 81.364C72.9102 80.4315 72.9102 79.499 73.144 78.5664V78.1001C73.144 77.867 73.3779 77.6339 73.3779 77.4007V75.0694C73.5709 74.15 73.6495 73.2104 73.6117 72.2718H74.0795ZM70.5715 58.9831H71.2731C71.0392 58.9831 71.0393 58.9831 71.0393 58.75L70.5715 58.9831Z"
                    fill="#006E49"
                />
                <path
                    d="M114.772 42.4305H110.562C109.861 42.1974 109.393 41.7311 108.691 41.7311L106.353 40.7986H97.6996L95.8287 41.2649H93.4901L91.6192 41.7311L89.9822 42.6637C89.5145 42.8968 89.0467 42.6637 88.579 42.6637L87.8774 43.1299C87.8154 43.1299 87.7559 43.1545 87.7121 43.1982C87.6682 43.2419 87.6436 43.3012 87.6436 43.3631C87.6436 43.5962 87.6436 43.8293 87.8774 43.8293H88.3451L89.2806 44.2956L90.4499 44.7619H91.8531L93.4901 45.2281L95.8287 45.6944C96.6822 45.5763 97.5478 45.5763 98.4012 45.6944H101.208C102.049 45.9 102.916 45.9786 103.78 45.9275L106.353 45.4613H108.691L110.562 44.995L111.731 44.2956H112.199C112.667 44.2956 113.135 44.5287 113.602 44.0625H114.304C114.538 44.0625 114.772 44.0625 114.772 43.8293"
                    fill="#823673"
                />
                <path
                    d="M68.2334 58.0502H67.9995C67.5318 57.8171 67.5318 57.5839 67.2979 57.1177H66.3625C66.3625 57.3508 66.1286 57.3508 66.1286 57.5839V58.0502L65.6609 59.2159V59.6822C65.427 60.3816 65.427 61.081 65.1932 61.7804C64.9593 62.4798 64.4916 62.946 64.2577 63.6455L63.5561 65.7437V67.3756C63.3223 67.3756 63.3223 67.6087 63.3223 67.8419L63.79 68.5413V69.2407L64.4916 69.7069H65.8948C66.0808 69.4334 66.1636 69.103 66.1286 68.7744C66.4711 67.9746 66.8616 67.1961 67.2979 66.4431C67.5318 65.5105 67.5318 64.578 67.7657 63.8786C68.0117 63.1222 68.1687 62.3398 68.2334 61.5472C68.2334 60.8478 68.4672 60.1484 68.4672 59.6822V59.2159C68.7011 58.9828 68.7011 58.9828 68.7011 58.7496H68.4672L68.2334 58.0502Z"
                    fill="#006E49"
                />
                <path
                    d="M100.039 35.2035L99.5709 34.7373H98.8693C98.6355 34.5041 98.4016 34.7373 98.1678 34.7373C97.9339 34.7373 97.4662 34.271 97.2323 34.271H96.2969C95.828 34.9137 95.1762 35.4011 94.426 35.6698L92.555 36.3692L90.2164 37.0686C89.9726 37.0486 89.7287 37.1053 89.5189 37.2308C89.3091 37.3563 89.1441 37.5442 89.0471 37.768H88.8132C88.7512 37.768 88.6917 37.7926 88.6479 37.8363C88.604 37.88 88.5794 37.9393 88.5794 38.0012H86.4746V38.4674H86.7085C86.7085 38.5292 86.7331 38.5886 86.777 38.6323C86.8208 38.676 86.8803 38.7006 86.9423 38.7006C86.9423 38.9337 87.1762 38.9337 87.4101 38.9337H87.6439C87.7059 38.9337 87.7654 38.9582 87.8093 39.002C87.8531 39.0457 87.8778 39.105 87.8778 39.1668H88.1117C88.1117 39.105 88.1363 39.0457 88.1801 39.002C88.224 38.9582 88.2835 38.9337 88.3455 38.9337H89.0471C88.8132 38.9337 88.8132 38.9337 88.8132 38.7006C88.8132 38.7312 88.8193 38.7615 88.831 38.7898C88.8428 38.8181 88.86 38.8438 88.8817 38.8654C88.9035 38.8871 88.9292 38.9042 88.9576 38.9159C88.986 38.9277 89.0164 38.9337 89.0471 38.9337H91.6196L94.426 38.2343L97.2323 37.3017L99.5709 36.1361L100.039 35.4367C100.273 35.4367 100.273 35.2035 100.273 34.9704V34.271L100.039 35.2035Z"
                    fill="#823673"
                />
                <path
                    d="M12.7354 99.9537L12.4222 99.6431C12.3265 99.5879 12.2206 99.5521 12.1108 99.5378C12.0009 99.5235 11.8893 99.531 11.7825 99.5599C11.6756 99.5889 11.5756 99.6386 11.4883 99.7062C11.401 99.7738 11.3281 99.8579 11.2739 99.9537L6.78511 106.062L2.40071 99.8502C2.24169 99.6825 2.02571 99.5792 1.7944 99.56C1.5631 99.5409 1.33285 99.6074 1.14803 99.7467H0.93925C0.842367 99.9071 0.771888 100.082 0.730469 100.264V112.689C0.756254 112.918 0.86651 113.129 1.03999 113.282C1.21346 113.435 1.43788 113.519 1.66998 113.517C1.88371 113.495 2.0832 113.4 2.23518 113.249C2.38715 113.099 2.48243 112.901 2.50511 112.689V103.06L6.05438 107.926C6.13617 108.07 6.25935 108.186 6.40814 108.26C6.55693 108.334 6.72454 108.362 6.8895 108.34C7.0193 108.325 7.1436 108.279 7.25239 108.207C7.36118 108.135 7.45142 108.039 7.51584 107.926L11.1695 102.956V112.792C11.1695 113.012 11.2575 113.223 11.4141 113.378C11.5707 113.533 11.7831 113.621 12.0046 113.621C12.2184 113.598 12.4179 113.504 12.5698 113.353C12.7218 113.202 12.8171 113.004 12.8398 112.792V100.368C12.842 100.223 12.806 100.08 12.7354 99.9537Z"
                    fill="white"
                />
                <path
                    d="M22.442 99.3325C21.5145 99.3458 20.5989 99.542 19.7486 99.9096C18.8982 100.277 18.1301 100.809 17.4889 101.474C16.8478 102.139 16.3463 102.923 16.0138 103.782C15.6813 104.641 15.5244 105.557 15.5522 106.477C15.4323 107.437 15.5196 108.413 15.8084 109.338C16.0972 110.263 16.5809 111.116 17.2274 111.842C17.8739 112.567 18.6684 113.148 19.5584 113.546C20.4484 113.944 21.4135 114.15 22.3898 114.15C23.366 114.15 24.3311 113.944 25.2211 113.546C26.1111 113.148 26.9057 112.567 27.5522 111.842C28.1987 111.116 28.6824 110.263 28.9712 109.338C29.26 108.413 29.3473 107.437 29.2273 106.477C29.2694 105.562 29.1257 104.648 28.8049 103.79C28.4841 102.932 27.9926 102.146 27.3597 101.48C26.7268 100.813 25.9654 100.28 25.1206 99.911C24.2758 99.5422 23.3649 99.3454 22.442 99.3325ZM22.442 111.86C21.7428 111.847 21.0531 111.697 20.4124 111.419C19.7716 111.141 19.1924 110.741 18.7077 110.241C18.223 109.741 17.8423 109.151 17.5875 108.505C17.3326 107.859 17.2086 107.17 17.2224 106.477C17.1945 105.775 17.3082 105.074 17.5567 104.416C17.8052 103.758 18.1836 103.156 18.6696 102.645C19.1557 102.134 19.7398 101.724 20.3875 101.44C21.0353 101.156 21.7337 101.002 22.442 100.989C25.2605 100.989 27.4527 103.474 27.5571 106.477C27.5855 107.86 27.0655 109.199 26.109 110.205C25.1524 111.212 23.8357 111.807 22.442 111.86Z"
                    fill="white"
                />
                <path
                    d="M40.3978 99.5395C40.1763 99.5395 39.9639 99.6268 39.8073 99.7821C39.6507 99.9375 39.5627 100.148 39.5627 100.368V105.752H33.4036V100.368C33.4215 100.255 33.4122 100.14 33.3765 100.031C33.3409 99.9225 33.2799 99.8238 33.1984 99.743C33.117 99.6623 33.0175 99.6018 32.9081 99.5664C32.7986 99.531 32.6823 99.5218 32.5685 99.5395C32.347 99.5395 32.1346 99.6268 31.978 99.7821C31.8214 99.9375 31.7334 100.148 31.7334 100.368V112.792C31.7561 113.004 31.8514 113.202 32.0033 113.353C32.1553 113.504 32.3548 113.598 32.5685 113.621C32.79 113.621 33.0024 113.533 33.159 113.378C33.3157 113.223 33.4036 113.012 33.4036 112.792V107.408H39.5627V112.792C39.5854 113.004 39.6806 113.202 39.8326 113.353C39.9846 113.504 40.1841 113.598 40.3978 113.621C40.6299 113.622 40.8543 113.538 41.0278 113.385C41.2013 113.232 41.3115 113.021 41.3373 112.792V100.368C41.3383 100.25 41.3137 100.133 41.2654 100.025C41.2171 99.9171 41.146 99.8207 41.0571 99.7422C40.9681 99.6638 40.8632 99.6051 40.7495 99.5702C40.6358 99.5352 40.5159 99.5248 40.3978 99.5395Z"
                    fill="white"
                />
                <path
                    d="M53.133 111.86H47.0784V107.408H52.2978C52.5193 107.408 52.7318 107.321 52.8884 107.166C53.045 107.01 53.133 106.8 53.133 106.58C53.1508 106.467 53.1415 106.352 53.1059 106.243C53.0702 106.135 53.0092 106.036 52.9278 105.955C52.8464 105.875 52.7469 105.814 52.6374 105.779C52.5279 105.743 52.4116 105.734 52.2978 105.752H47.0784V101.196H53.133C53.3545 101.196 53.5669 101.109 53.7235 100.954C53.8801 100.798 53.9681 100.587 53.9681 100.368C53.9859 100.255 53.9766 100.14 53.941 100.031C53.9053 99.9225 53.8443 99.8238 53.7629 99.743C53.6815 99.6623 53.582 99.6018 53.4725 99.5664C53.363 99.531 53.2467 99.5218 53.133 99.5395H46.2432C46.1251 99.5248 46.0052 99.5352 45.8915 99.5702C45.7778 99.6051 45.673 99.6638 45.584 99.7422C45.495 99.8207 45.424 99.9171 45.3757 100.025C45.3273 100.133 45.3028 100.25 45.3037 100.368V112.792C45.3264 113.004 45.4217 113.202 45.5737 113.353C45.7256 113.504 45.9251 113.598 46.1388 113.621H53.133C53.3545 113.621 53.5669 113.533 53.7235 113.378C53.8801 113.223 53.9681 113.012 53.9681 112.792C53.983 112.675 53.9724 112.556 53.9372 112.443C53.9019 112.331 53.8428 112.227 53.7637 112.138C53.6846 112.05 53.5874 111.98 53.4786 111.932C53.3699 111.884 53.252 111.859 53.133 111.86Z"
                    fill="white"
                />
                <path
                    d="M67.6444 105.855H63.4687C63.2195 105.855 62.9806 105.954 62.8044 106.128C62.6282 106.303 62.5292 106.54 62.5292 106.787C62.555 107.016 62.6652 107.227 62.8387 107.38C63.0122 107.533 63.2366 107.617 63.4687 107.616H66.7048V110.929C65.87 111.483 64.9011 111.806 63.8982 111.864C62.8954 111.922 61.8952 111.713 61.001 111.259C60.1069 110.805 59.3512 110.122 58.8124 109.282C58.2735 108.441 57.9709 107.472 57.936 106.477C57.9633 105.012 58.5693 103.616 59.6235 102.59C60.6777 101.564 62.096 100.989 63.5731 100.989C64.8648 100.988 66.1178 101.426 67.1224 102.232C67.2905 102.364 67.5036 102.427 67.7175 102.408C67.9313 102.389 68.1294 102.288 68.2707 102.128C68.3872 101.94 68.4586 101.727 68.4795 101.507C68.4956 101.384 68.4744 101.259 68.4186 101.149C68.3628 101.038 68.275 100.947 68.1663 100.886C66.8543 99.8714 65.2365 99.3244 63.5731 99.3326C61.6628 99.3326 59.8306 100.085 58.4798 101.425C57.129 102.765 56.3701 104.582 56.3701 106.477C56.3701 108.371 57.129 110.189 58.4798 111.528C59.8306 112.868 61.6628 113.621 63.5731 113.621C65.2365 113.629 66.8543 113.082 68.1663 112.068C68.3475 111.908 68.4597 111.686 68.4795 111.446V106.787C68.4809 106.557 68.3964 106.334 68.2422 106.162C68.088 105.99 67.875 105.881 67.6444 105.855Z"
                    fill="white"
                />
                <path
                    d="M82.571 112.482L77.9778 100.057C77.9005 99.9027 77.7816 99.7725 77.6341 99.6811C77.4867 99.5897 77.3165 99.5407 77.1427 99.5395C76.9661 99.5269 76.7901 99.5702 76.64 99.6633C76.4899 99.7563 76.3735 99.8943 76.3076 100.057L71.61 112.482C71.5602 112.589 71.5343 112.707 71.5342 112.825C71.534 112.944 71.5595 113.061 71.609 113.169C71.6585 113.277 71.7308 113.373 71.821 113.451C71.9112 113.529 72.0173 113.587 72.1319 113.621H72.4451C72.604 113.612 72.7571 113.559 72.8866 113.467C73.016 113.376 73.1164 113.249 73.1758 113.103L74.3241 110.204H79.8568L81.0051 113.103C81.0711 113.266 81.1875 113.404 81.3376 113.497C81.4877 113.59 81.6637 113.633 81.8403 113.621H82.1534C82.3461 113.498 82.4928 113.316 82.571 113.103C82.626 113.008 82.6549 112.901 82.6549 112.792C82.6549 112.683 82.626 112.576 82.571 112.482ZM79.2305 108.444H74.9505L77.1427 102.749L79.2305 108.444Z"
                    fill="white"
                />
                <path
                    d="M94.7853 99.6433C94.5532 99.6419 94.3287 99.7257 94.1553 99.8786C93.9818 100.032 93.8715 100.243 93.8457 100.472V110.101L86.434 99.9539C86.3717 99.8513 86.2878 99.7634 86.188 99.696C86.0881 99.6287 85.9748 99.5837 85.8557 99.564C85.7367 99.5443 85.6147 99.5505 85.4983 99.582C85.3819 99.6136 85.2737 99.6698 85.1813 99.7468C85.076 99.8572 84.9941 99.9873 84.9403 100.13C84.8865 100.272 84.862 100.423 84.8681 100.575V112.792C84.8939 113.021 85.0041 113.232 85.1776 113.385C85.3511 113.538 85.5755 113.622 85.8076 113.621C86.0214 113.598 86.2208 113.504 86.3728 113.353C86.5248 113.202 86.6201 113.004 86.6428 112.792V103.06L93.9501 113.207H94.0545C94.146 113.308 94.2589 113.389 94.3851 113.442C94.5114 113.496 94.648 113.522 94.7853 113.517C94.999 113.495 95.1985 113.4 95.3504 113.25C95.5024 113.099 95.5977 112.901 95.6204 112.689V100.472C95.5977 100.26 95.5024 100.062 95.3504 99.911C95.1985 99.7603 94.999 99.6658 94.7853 99.6433Z"
                    fill="white"
                />
                <path
                    d="M112.531 106.684C111.435 105.977 110.18 105.551 108.878 105.441L106.163 104.509C105.928 104.365 105.73 104.168 105.585 103.935C105.44 103.701 105.352 103.437 105.328 103.163C105.348 102.849 105.444 102.544 105.607 102.273C105.771 102.003 105.997 101.776 106.268 101.61C107.017 101.139 107.886 100.888 108.773 100.886C110.031 100.986 111.23 101.454 112.218 102.232C112.301 102.308 112.401 102.364 112.51 102.395C112.619 102.426 112.733 102.432 112.844 102.412C112.956 102.392 113.061 102.346 113.151 102.279C113.242 102.212 113.316 102.125 113.366 102.025C113.466 101.839 113.5 101.626 113.462 101.419C113.424 101.212 113.317 101.024 113.158 100.886C111.874 99.9662 110.354 99.4276 108.773 99.3325C107.54 99.279 106.324 99.6442 105.328 100.368C104.847 100.645 104.447 101.04 104.165 101.516C103.883 101.991 103.729 102.531 103.719 103.082C103.708 103.634 103.841 104.179 104.105 104.665C104.369 105.15 104.754 105.56 105.224 105.855C106.281 106.466 107.454 106.854 108.669 106.994C109.703 107.142 110.697 107.495 111.592 108.03C111.869 108.188 112.1 108.414 112.265 108.685C112.429 108.957 112.521 109.266 112.531 109.583C112.518 109.925 112.414 110.258 112.231 110.549C112.048 110.839 111.792 111.077 111.487 111.239C110.687 111.77 109.734 112.024 108.773 111.964C107.262 111.847 105.814 111.309 104.598 110.411C104.522 110.33 104.428 110.268 104.324 110.229C104.22 110.19 104.109 110.174 103.998 110.184C103.887 110.194 103.78 110.229 103.685 110.287C103.59 110.344 103.509 110.422 103.449 110.515C103.337 110.689 103.286 110.895 103.305 111.102C103.324 111.308 103.411 111.502 103.554 111.653C105.038 112.843 106.867 113.532 108.773 113.621C110.113 113.623 111.422 113.226 112.531 112.482C113.018 112.161 113.422 111.731 113.712 111.228C114.002 110.725 114.17 110.161 114.202 109.583C114.187 109 114.027 108.43 113.735 107.924C113.444 107.418 113.03 106.992 112.531 106.684Z"
                    fill="white"
                />
                <path
                    d="M127.668 99.5395C127.554 99.5218 127.438 99.531 127.329 99.5664C127.219 99.6018 127.12 99.6623 127.038 99.743C126.957 99.8238 126.896 99.9225 126.86 100.031C126.825 100.14 126.815 100.255 126.833 100.368V107.822C126.677 108.795 126.175 109.681 125.42 110.321C124.664 110.96 123.703 111.311 122.71 111.311C121.716 111.311 120.755 110.96 120 110.321C119.244 109.681 118.743 108.795 118.586 107.822V100.368C118.586 100.259 118.565 100.151 118.523 100.051C118.481 99.9503 118.419 99.859 118.342 99.7821C118.264 99.7052 118.172 99.6442 118.071 99.6026C117.969 99.5609 117.861 99.5395 117.751 99.5395C117.633 99.5248 117.513 99.5352 117.399 99.5702C117.286 99.6051 117.181 99.6638 117.092 99.7422C117.003 99.8207 116.932 99.9171 116.883 100.025C116.835 100.133 116.811 100.25 116.812 100.368V107.822C116.812 109.36 117.427 110.835 118.524 111.922C119.62 113.01 121.107 113.621 122.657 113.621C124.208 113.621 125.695 113.01 126.791 111.922C127.887 110.835 128.503 109.36 128.503 107.822V100.368C128.503 100.148 128.415 99.9375 128.259 99.7821C128.102 99.6268 127.89 99.5395 127.668 99.5395Z"
                    fill="white"
                />
                <path
                    d="M142.074 99.6433C141.861 99.6658 141.661 99.7603 141.509 99.911C141.357 100.062 141.262 100.26 141.239 100.472V110.101L133.827 99.9539C133.765 99.8513 133.681 99.7634 133.582 99.696C133.482 99.6287 133.368 99.5837 133.249 99.564C133.13 99.5443 133.008 99.5505 132.892 99.582C132.775 99.6136 132.667 99.6698 132.575 99.7468C132.438 99.8312 132.333 99.9582 132.276 100.108C132.219 100.258 132.214 100.422 132.262 100.575V112.792C132.284 113.004 132.38 113.202 132.532 113.353C132.684 113.504 132.883 113.598 133.097 113.621C133.318 113.621 133.531 113.533 133.687 113.378C133.844 113.223 133.932 113.012 133.932 112.792V103.06L141.344 113.207H141.448C141.518 113.306 141.612 113.387 141.721 113.441C141.831 113.495 141.952 113.522 142.074 113.517C142.306 113.519 142.531 113.435 142.704 113.282C142.878 113.129 142.988 112.918 143.014 112.689V100.472C142.988 100.243 142.878 100.032 142.704 99.8786C142.531 99.7257 142.306 99.6419 142.074 99.6433Z"
                    fill="white"
                />
                <path
                    d="M13.4649 129.772C12.3341 130.736 10.8844 131.252 9.39371 131.222C8.46765 131.338 7.5272 131.258 6.63491 130.986C5.74262 130.714 4.91893 130.257 4.21859 129.644C3.51826 129.032 2.95733 128.279 2.57311 127.436C2.18889 126.592 1.99017 125.677 1.99017 124.751C1.99017 123.825 2.18889 122.91 2.57311 122.066C2.95733 121.222 3.51826 120.469 4.21859 119.857C4.91893 119.245 5.74262 118.788 6.63491 118.516C7.5272 118.244 8.46765 118.163 9.39371 118.28C10.9071 118.302 12.3723 118.811 13.5693 119.729C13.7664 119.856 14.0044 119.906 14.2363 119.867C14.4682 119.829 14.6772 119.706 14.822 119.522C14.9225 119.453 15.0038 119.36 15.0585 119.251C15.1132 119.143 15.1396 119.022 15.1352 118.901C15.0607 118.628 14.9169 118.378 14.7176 118.176C13.1748 117.047 11.3112 116.431 9.39371 116.416C8.21149 116.285 7.01474 116.403 5.88164 116.762C4.74853 117.121 3.70458 117.714 2.81801 118.5C1.93143 119.287 1.22217 120.25 0.736585 121.327C0.250997 122.404 0 123.571 0 124.751C0 125.931 0.250997 127.097 0.736585 128.174C1.22217 129.251 1.93143 130.215 2.81801 131.001C3.70458 131.788 4.74853 132.38 5.88164 132.739C7.01474 133.098 8.21149 133.216 9.39371 133.085C11.3136 133.085 13.1817 132.468 14.7176 131.325C14.9169 131.123 15.0607 130.874 15.1352 130.601C15.1396 130.479 15.1132 130.359 15.0585 130.25C15.0038 130.142 14.9225 130.049 14.822 129.979C14.7629 129.859 14.6753 129.755 14.5667 129.675C14.458 129.596 14.3316 129.544 14.1982 129.523C14.0648 129.503 13.9284 129.515 13.8007 129.558C13.673 129.602 13.5578 129.675 13.4649 129.772Z"
                    fill="white"
                />
                <path
                    d="M30.3764 131.843L24.8437 117.244C24.7821 117.07 24.6698 116.919 24.5212 116.808C24.3725 116.697 24.1941 116.633 24.0086 116.623C23.8084 116.626 23.6134 116.687 23.4469 116.797C23.2803 116.907 23.1491 117.062 23.0691 117.244L17.5364 131.843C17.432 132.05 17.5364 132.257 17.6408 132.568C17.7245 132.752 17.873 132.899 18.0584 132.982H18.4759C18.6656 132.988 18.8516 132.93 19.0034 132.817C19.1552 132.704 19.2637 132.543 19.311 132.361L20.6681 129.047H27.3491L28.6018 132.464C28.6912 132.639 28.8246 132.788 28.9892 132.897C29.1539 133.006 29.344 133.071 29.5413 133.085H29.8545C29.9837 133.056 30.1039 132.996 30.2052 132.911C30.3065 132.826 30.3859 132.718 30.4369 132.597C30.488 132.476 30.5091 132.344 30.4985 132.213C30.488 132.082 30.4462 131.955 30.3764 131.843ZM26.6184 127.184H21.3988L24.0086 120.247L26.6184 127.184Z"
                    fill="white"
                />
                <path
                    d="M42.6944 125.01C41.3732 124.262 39.9207 123.77 38.4144 123.56C37.2712 123.393 36.1719 123.006 35.1783 122.421C34.9014 122.263 34.6699 122.037 34.5055 121.765C34.3411 121.494 34.2493 121.185 34.2387 120.868C34.2368 120.495 34.3317 120.127 34.5146 119.801C34.6974 119.474 34.9619 119.2 35.2827 119.004C36.1874 118.438 37.2406 118.15 38.31 118.176C39.7934 118.277 41.2119 118.818 42.3812 119.729C42.5783 119.856 42.8162 119.906 43.0482 119.867C43.2801 119.829 43.4891 119.706 43.6339 119.522C43.7298 119.31 43.751 119.072 43.6942 118.847C43.6374 118.622 43.5059 118.422 43.3207 118.28C41.898 117.137 40.1396 116.483 38.31 116.416C36.8686 116.383 35.4503 116.78 34.2387 117.555C33.7068 117.935 33.2701 118.432 32.9624 119.006C32.6547 119.581 32.4842 120.218 32.4641 120.868C32.4722 121.478 32.64 122.076 32.9511 122.602C33.2622 123.128 33.7059 123.566 34.2387 123.871C35.4148 124.607 36.7285 125.1 38.1012 125.32C39.3523 125.446 40.5621 125.834 41.6505 126.459C41.984 126.649 42.2639 126.92 42.4645 127.245C42.6652 127.571 42.7801 127.942 42.7988 128.323C42.767 128.741 42.6372 129.146 42.4197 129.505C42.2022 129.865 41.903 130.169 41.5461 130.393C40.5735 130.991 39.4542 131.314 38.31 131.325C36.492 131.263 34.7545 130.566 33.4036 129.358C33.2188 129.219 32.9885 129.152 32.7572 129.171C32.5259 129.191 32.31 129.294 32.1509 129.462C32.0682 129.546 32.0046 129.648 31.9646 129.759C31.9246 129.87 31.909 129.989 31.9189 130.106C31.9288 130.224 31.9639 130.338 32.0219 130.441C32.08 130.544 32.1595 130.634 32.2553 130.704C33.9323 132.173 36.0737 133.015 38.31 133.085C39.8493 133.131 41.3587 132.657 42.59 131.739C43.1789 131.398 43.6691 130.911 44.0131 130.327C44.3571 129.743 44.5434 129.08 44.5539 128.404C44.5644 127.727 44.3987 127.059 44.073 126.465C43.7473 125.87 43.2724 125.369 42.6944 125.01Z"
                    fill="white"
                />
                <path
                    d="M48.9572 116.623C48.708 116.623 48.469 116.721 48.2928 116.896C48.1166 117.071 48.0176 117.308 48.0176 117.555V132.154C48.0176 132.401 48.1166 132.638 48.2928 132.812C48.469 132.987 48.708 133.085 48.9572 133.085C49.2064 133.085 49.4454 132.987 49.6216 132.812C49.7978 132.638 49.8968 132.401 49.8968 132.154V117.555C49.8968 117.308 49.7978 117.071 49.6216 116.896C49.4454 116.721 49.2064 116.623 48.9572 116.623Z"
                    fill="white"
                />
                <path
                    d="M66.2862 116.727C66.037 116.727 65.798 116.825 65.6218 117C65.4457 117.174 65.3467 117.411 65.3467 117.658V129.255L56.4735 117.037C56.4009 116.932 56.3073 116.842 56.1984 116.774C56.0894 116.706 55.9675 116.661 55.8403 116.641C55.713 116.622 55.5831 116.629 55.4586 116.661C55.3341 116.694 55.2176 116.751 55.1164 116.83C54.9708 116.932 54.8619 117.077 54.8055 117.245C54.7492 117.413 54.7484 117.594 54.8033 117.762V132.154C54.8033 132.276 54.8276 132.397 54.8748 132.51C54.922 132.623 54.9912 132.726 55.0784 132.813C55.1657 132.899 55.2692 132.968 55.3832 133.015C55.4972 133.061 55.6194 133.085 55.7428 133.085C55.8661 133.085 55.9883 133.061 56.1023 133.015C56.2163 132.968 56.3199 132.899 56.4071 132.813C56.4943 132.726 56.5635 132.623 56.6108 132.51C56.658 132.397 56.6823 132.276 56.6823 132.154V120.557L65.4511 132.568C65.4577 132.648 65.4951 132.722 65.5555 132.775C65.647 132.876 65.7598 132.957 65.8861 133.011C66.0123 133.064 66.1489 133.09 66.2862 133.085C66.5354 133.085 66.7743 132.987 66.9505 132.813C67.1267 132.638 67.2257 132.401 67.2257 132.154V117.658C67.2257 117.411 67.1267 117.174 66.9505 117C66.7743 116.825 66.5354 116.727 66.2862 116.727Z"
                    fill="white"
                />
                <path
                    d="M78.2906 116.416C77.2212 116.443 76.1676 116.679 75.19 117.11C74.2125 117.54 73.3301 118.158 72.5933 118.927C71.8565 119.697 71.2798 120.602 70.8961 121.593C70.5123 122.583 70.3291 123.639 70.3569 124.699C70.228 125.815 70.3384 126.946 70.681 128.017C71.0235 129.088 71.5903 130.075 72.3443 130.913C73.0984 131.752 74.0226 132.422 75.0563 132.882C76.0901 133.341 77.21 133.579 78.3428 133.579C79.4755 133.579 80.5955 133.341 81.6292 132.882C82.6629 132.422 83.5871 131.752 84.3411 130.913C85.0952 130.075 85.662 129.088 86.0045 128.017C86.3471 126.946 86.4575 125.815 86.3286 124.699C86.3563 123.63 86.1697 122.566 85.7795 121.569C85.3892 120.572 84.8032 119.662 84.0554 118.891C83.3076 118.121 82.4129 117.505 81.4233 117.08C80.4337 116.655 79.3688 116.43 78.2906 116.416ZM78.2906 131.222C76.6198 131.141 75.0482 130.412 73.9153 129.191C72.7823 127.971 72.1791 126.357 72.2359 124.699C72.1937 123.88 72.3184 123.061 72.6025 122.29C72.8865 121.52 73.3241 120.814 73.8892 120.215C74.4542 119.616 75.1352 119.136 75.8914 118.803C76.6476 118.471 77.4635 118.293 78.2906 118.28C79.8789 118.48 81.3392 119.247 82.3977 120.438C83.4562 121.63 84.0402 123.163 84.0402 124.751C84.0402 126.339 83.4562 127.872 82.3977 129.064C81.3392 130.255 79.8789 131.022 78.2906 131.222Z"
                    fill="white"
                />
                <path
                    d="M89.6704 130.083C89.2725 130.082 88.8893 130.231 88.5985 130.501C88.3076 130.77 88.131 131.139 88.1045 131.533C88.1045 131.944 88.2695 132.339 88.5631 132.631C88.8568 132.922 89.2551 133.086 89.6704 133.086C90.0673 133.059 90.4393 132.884 90.7107 132.596C90.9822 132.307 91.1327 131.927 91.1319 131.533C91.1319 131.148 90.9779 130.779 90.7038 130.508C90.4297 130.236 90.058 130.083 89.6704 130.083Z"
                    fill="white"
                />
                <path
                    d="M105.642 129.772C104.511 130.736 103.061 131.252 101.571 131.222C100.645 131.338 99.7042 131.258 98.8119 130.986C97.9196 130.714 97.0959 130.257 96.3955 129.645C95.6952 129.032 95.1343 128.279 94.7501 127.436C94.3659 126.592 94.1671 125.677 94.1671 124.751C94.1671 123.825 94.3659 122.91 94.7501 122.066C95.1343 121.222 95.6952 120.469 96.3955 119.857C97.0959 119.245 97.9196 118.788 98.8119 118.516C99.7042 118.244 100.645 118.163 101.571 118.28C103.084 118.302 104.549 118.811 105.746 119.729C105.943 119.857 106.181 119.906 106.413 119.867C106.645 119.829 106.854 119.706 106.999 119.522C107.208 119.419 107.208 119.108 107.208 118.901C107.212 118.765 107.186 118.629 107.132 118.504C107.078 118.379 106.997 118.267 106.895 118.176C105.352 117.047 103.488 116.431 101.571 116.416C100.443 116.389 99.3208 116.585 98.2707 116.994C97.2206 117.403 96.2636 118.017 95.4562 118.798C94.6488 119.58 94.0072 120.513 93.5693 121.545C93.1313 122.576 92.9059 123.684 92.9063 124.803C92.961 127.036 93.9013 129.158 95.5233 130.709C97.1453 132.26 99.3181 133.113 101.571 133.086C103.488 133.071 105.352 132.455 106.895 131.325C106.997 131.235 107.078 131.123 107.132 130.998C107.186 130.872 107.212 130.737 107.208 130.601C107.208 130.394 107.208 130.083 106.999 129.979C106.926 129.874 106.833 129.784 106.724 129.716C106.615 129.648 106.493 129.603 106.366 129.584C106.238 129.564 106.109 129.571 105.984 129.603C105.86 129.636 105.743 129.693 105.642 129.772Z"
                    fill="white"
                />
                <path
                    d="M117.855 116.416C116.786 116.443 115.732 116.679 114.754 117.11C113.777 117.54 112.895 118.158 112.158 118.927C111.421 119.697 110.844 120.602 110.461 121.593C110.077 122.583 109.894 123.639 109.921 124.699C109.792 125.815 109.903 126.946 110.245 128.017C110.588 129.088 111.155 130.075 111.909 130.913C112.663 131.752 113.587 132.422 114.621 132.882C115.654 133.341 116.774 133.579 117.907 133.579C119.04 133.579 120.16 133.341 121.194 132.882C122.227 132.422 123.152 131.752 123.906 130.913C124.66 130.075 125.226 129.088 125.569 128.017C125.912 126.946 126.022 125.815 125.893 124.699C125.921 123.63 125.734 122.566 125.344 121.569C124.954 120.572 124.368 119.662 123.62 118.891C122.872 118.121 121.977 117.505 120.988 117.08C119.998 116.655 118.933 116.43 117.855 116.416ZM117.855 131.222C116.184 131.141 114.613 130.412 113.48 129.191C112.347 127.971 111.744 126.357 111.8 124.699C111.772 123.883 111.907 123.07 112.196 122.306C112.486 121.541 112.924 120.841 113.486 120.245C114.049 119.649 114.724 119.168 115.473 118.831C116.223 118.494 117.032 118.307 117.855 118.28C119.443 118.48 120.904 119.247 121.962 120.438C123.021 121.63 123.605 123.163 123.605 124.751C123.605 126.339 123.021 127.872 121.962 129.064C120.904 130.255 119.443 131.022 117.855 131.222Z"
                    fill="white"
                />
                <path
                    d="M142.805 117.141L142.492 116.83C142.295 116.703 142.057 116.654 141.825 116.692C141.593 116.73 141.384 116.854 141.239 117.037L135.915 124.389L130.591 117.037C130.529 116.935 130.445 116.847 130.345 116.78C130.245 116.712 130.132 116.667 130.013 116.648C129.894 116.628 129.772 116.634 129.655 116.666C129.539 116.697 129.431 116.753 129.338 116.83H129.13C128.921 116.934 128.921 117.141 128.921 117.452V132.154C128.921 132.401 129.02 132.638 129.196 132.813C129.372 132.987 129.611 133.086 129.86 133.086C130.11 133.086 130.349 132.987 130.525 132.813C130.701 132.638 130.8 132.401 130.8 132.154V120.454L135.08 126.459C135.294 126.699 135.593 126.847 135.915 126.873C136.077 126.873 136.237 126.836 136.382 126.764C136.527 126.692 136.653 126.588 136.75 126.459L141.135 120.454V132.154C141.12 132.271 141.13 132.39 141.166 132.503C141.201 132.615 141.26 132.719 141.339 132.808C141.418 132.896 141.515 132.966 141.624 133.014C141.733 133.062 141.851 133.087 141.97 133.086C142.093 133.086 142.215 133.062 142.329 133.015C142.443 132.968 142.547 132.899 142.634 132.813C142.721 132.726 142.791 132.623 142.838 132.51C142.885 132.397 142.909 132.276 142.909 132.154V117.659C142.926 117.48 142.889 117.3 142.805 117.141Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
