import { h } from 'preact';
import { useDispatch, useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { showGeneralError } from '../../../redux/actions/app-error';
import { setRegionInvalid, setRegionValid } from '../../../redux/actions/region';
import connectionType from '../../helpers/connectionType';
import { RGIS } from '../../constants';
import { providerRefSelector, providerSelector, regionSelector, rgiSelector } from '../../../selectors/gameContext';

// validate query params; checking if region is present NOT if it's a valid one
export const useQueryParameterValidationGAN = () => {
    const providerRef = useSelector(providerRefSelector);
    const provider = useSelector(providerSelector);
    const region = useSelector(regionSelector);
    const dispatch = useDispatch();
    const RGI = useSelector(rgiSelector);

    if (region && region !== 'undefined') {
        dispatch(setRegionValid());
    } else {
        dispatch(setRegionInvalid());
        dispatch(showGeneralError());
        datadogRum.addError(
            new Error('General error: no region'),
            {
                errorTag: 'QUERY_PARAM_VALIDATION',
                connectionType: connectionType()
            },
        );
        return false;
    }

    if (RGI !== RGIS.INCENTIVE_GAMES && !(provider && providerRef)) {
        dispatch(showGeneralError());
        datadogRum.addError(
            new Error('General error: no provider'),
            {
                errorTag: 'QUERY_PARAM_VALIDATION',
                connectionType: connectionType()
            },
        );
        //todo use this after creating a session error handler
        // dispatch({
        //     type: types.CREATE_SESSION_FAILED,
        //     payload: { name: 'GAN_ID_MISSING' }
        // })

        return false;
    }

    return true;
};
