import { types } from '../../types'

export const initialState = {
    loading: true,
    data: null,
    features: [],
    error: null,
    preferences: {}
}

const customerDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CUSTOMER_PACKAGE_REQUEST:
        case types.CUSTOMER_FEATURES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case types.CUSTOMER_PACKAGE_RECEIVED:
            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        case types.CUSTOMER_PACKAGE_FAILED:
        case types.CUSTOMER_FEATURES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case types.CUSTOMER_FEATURES_RECEIVED:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        default: {
            return state
        }
    }
}

export default customerDataReducer
