import remove from 'lodash/remove'

export const FETCH_ERROR = 'FETCH_ERROR'

// Action Creators
export const fetchError = uid => ({
    type: FETCH_ERROR,
    payload: uid
})

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        requests: remove(state.requests, item => item.key !== action.payload.key)
    }
}
