import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';
import { IRmMessageReceivedContext } from './types';

interface IRmTrackingActions extends IRmMessageReceivedContext {
    event:
        | 'entryUpdate'
        | 'rm_game_spin'
        | 'rm_game_spin_won'
        | 'rm_prize_claimed'
        | 'rm_error'
        | 'modal_viewed'
        | 'cta_clicked';
}

export const ddActionRmTrackingActions = (
    { event, ...context }: IRmTrackingActions,
    use_case: IddUseCases
): IddRumAction => {
    const eventMessages = {
        entryUpdate: 'User spin data changed',
        rm_game_spin: 'User used a spin',
        rm_game_spin_won: 'User had a lucky spin',
        rm_prize_claimed: 'User completed a set of collectibles',
        rm_error: 'User received an in-game error',
        modal_viewed: 'User clicked info-button in game',
        cta_clicked: 'User clicked cta'
    };
    const message = eventMessages[event] ?? 'Undefined event';

    return {
        name: `RM_TRACKING_ACTIONS_${event}`,
        description: `RM IG event: ${message}`,
        context,
        use_case
    };
};
