export const FETCH_START = 'FETCH_START'

// Action Creators
export const fetchStart = uid => ({
    type: FETCH_START,
    payload: uid
})

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        requests: [...state.requests, action.payload]
    }
}
