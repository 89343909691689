import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';
import { IRmMessageReceivedContext } from './types';

export const ddActionRmMessageReceivedDefault = (
    { ...context }: IRmMessageReceivedContext,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'RM_MESSAGE_RECEIVED_DEFAULT',
    description: 'RM message received default',
    context,
    use_case
});
