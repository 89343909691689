import GameLauncherIncentiveGames from './IncentiveGames/Launcher';
import { RGIS } from '../../common/constants';
import { useMemo } from 'preact/hooks';
import GameClient from './GameClient/GameClient';

type LauncherMapper = {
    [rgi in keyof typeof RGIS[number]]: any;
};

export const GameLauncher = ({ rgi, ...props }) => {
    const launcherMapper: LauncherMapper = useMemo(
        () => ({
            [RGIS.GAN]: GameClient,
            [RGIS.INCENTIVE_GAMES]: GameLauncherIncentiveGames,
            [RGIS.FGP]: GameClient
        }),
        []
    );

    const Launcher = useMemo(() => (Object.keys(launcherMapper).includes(rgi) ? launcherMapper[rgi] : GameClient), [
        launcherMapper,
        rgi
    ]);

    return <Launcher {...props} />;
};
