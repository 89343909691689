import { types } from '../../types'
import fetchRealityCheck from '../../../common/helpers/fetchRealityCheck'

const getRealityCheck = (userId) => async dispatch => {
    const realityCheckData = await fetchRealityCheck(userId)

    dispatch({
        type: types.REALITY_CHECK_SUCCESS,
        payload: realityCheckData
    })
}

export default getRealityCheck
