export const GEOCOMPLY_PLC_LOCATION_RECEIVED = 'GEOCOMPLY_PLC_LOCATION_RECEIVED'

// Action Creators
export const locationReceived = geopacket => dispatch => {
    console.warn('LOCATION RECEIVED')
    dispatch({
        type: GEOCOMPLY_PLC_LOCATION_RECEIVED,
        payload: geopacket
    })
}

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        geoPacket: action.payload,
        validated: false,
        loading: false
    }
}
