import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setGameTimeAction } from '../../redux/actions/game-window';
import { useCallback, useEffect } from 'preact/hooks';
import { TIMERS_TIMESTAMPS } from '../constants';

dayjs.extend(utc);

const { GAME_MARK, GAME_MEASURE } = TIMERS_TIMESTAMPS;

export const useGameTime = tickerInitialized => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const gameTimeFormat = t('timerFormat.gameTime');

    const updateGameTime = useCallback(() => {
        if (window?.performance?.getEntriesByName?.(GAME_MARK)?.length > 0) {
            window?.performance?.clearMeasures?.(GAME_MEASURE);
            window?.performance?.measure?.(GAME_MEASURE, GAME_MARK);
            const timeElapsed = window?.performance?.getEntriesByName?.(GAME_MEASURE)?.[0]?.duration || 0;
            dispatch(setGameTimeAction(dayjs.utc(timeElapsed).format(gameTimeFormat)));
        }
    }, [dispatch, gameTimeFormat]);

    useEffect(() => {
        if (tickerInitialized) {
            const interval = setInterval(updateGameTime, 1000);

            return () => {
                clearInterval(interval);
                window?.performance?.clearMeasures?.(GAME_MEASURE);
            };
        }
        window?.performance?.mark(GAME_MARK);
    }, [tickerInitialized, updateGameTime]);
};
