import Cookie from 'js-cookie';

import { AW_COOKIE, GAN_COOKIE, GEOCOMPLY_COOKIES, PX_COOKIE, SESSION_COOKIE, WRAPPER_COOKIES } from '../constants.js';

import { getQueryParameter } from './queryparams';
import config from '../../../config/config';

export const getDomain = () => config.domain;

/**
 * Util for removing a specific cookie
 *
 * @param {string} name Cookie name
 * @param {string} path Cookie path
 * @param {string} domain Cookie domain
 */
export const removeCookie = (name, path, domain) => {
    const domainAttr = typeof domain === 'string' ? domain : getDomain();
    const pathAttr = typeof path === 'string' ? path : '/';

    Cookie.remove(name, { path: pathAttr, domain: domainAttr });
};

export const getAWCookies = () => {
    const authToken = Cookie.get(AW_COOKIE.AUTH_TOKEN) || null;
    const currency = Cookie.get(AW_COOKIE.CURRENCY) || null;
    const loginToken = Cookie.get(AW_COOKIE.LOGIN_TOKEN) || null;

    return {
        authToken,
        currency,
        loginToken
    };
};

export const getPXCookies = () => {
    const authorizationToken = Cookie.get(PX_COOKIE.PX_AUTHORIZATION_TOKEN) || null;

    return {
        authorizationToken
    };
};

export const getWrapperCookies = () => ({
    isQuickLaunch: Cookie.get(WRAPPER_COOKIES.IS_QUICK_LAUNCH) || false,
    isNewQuickLaunchFlow: Cookie.get(WRAPPER_COOKIES.IS_USING_NEW_QUICK_LAUNCH) || false,
    androidAppVersion: Cookie.get(WRAPPER_COOKIES.ANDROID_APP_VERSION) || false
});

export const setAWCookies = (authToken, loginToken) => {
    const cookieDomain = getDomain();

    Cookie.set(AW_COOKIE.AUTH_TOKEN, authToken, { domain: cookieDomain });
    Cookie.set(AW_COOKIE.LOGIN_TOKEN, loginToken, { domain: cookieDomain });
};

export const getXsellGeocomplyCookie = () => Cookie.get(GEOCOMPLY_COOKIES.XSELL_GEOCOMPLY_TOKEN) ?? '';

//method used to hide header and footer in AW pages
export const setWrapperAppConfig = () => {
    const cookieDomain = getDomain();

    const cookieToSend = {
        showHeader: false,
        showFooter: false,
        depositFooterShow: false
    };

    Cookie.set(WRAPPER_COOKIES.WRAPPER_APP_CONFIG, cookieToSend, { domain: cookieDomain });
};

export const removeWrapperAppConfig = () => {
    removeCookie(WRAPPER_COOKIES.WRAPPER_APP_CONFIG);
};

export const removeAWCookies = () => {
    removeCookie(AW_COOKIE.AUTH_TOKEN);
    removeCookie(AW_COOKIE.LOGIN_TOKEN);
    removeCookie(SESSION_COOKIE.START_TIME);
};

//TODO: maybe there needs to be an expiration date on these cookies...
// or maybe not. Depends on how the refresh mechanism wants to be implemented
export const setGANCookies = (apiToken, playerToken) => {
    const cookieDomain = getDomain();
    Cookie.set(GAN_COOKIE.API_TOKEN, apiToken, { domain: cookieDomain });
    Cookie.set(GAN_COOKIE.PLAYER_TOKEN, playerToken, { domain: cookieDomain });
};

export const getGANCookies = () => {
    const ganApiToken = Cookie.get(GAN_COOKIE.API_TOKEN) || null;
    const ganPlayerUid = Cookie.get(GAN_COOKIE.PLAYER_TOKEN) || null;
    return { ganApiToken, ganPlayerUid };
};

const setRegionCookie = queryParam => {
    const cookieDomain = getDomain();
    Cookie.set(AW_COOKIE.REGION, queryParam, { domain: cookieDomain });
};

export const setSessionStartTimeCookie = startTime => {
    const cookieDomain = getDomain();
    Cookie.set(SESSION_COOKIE.START_TIME, startTime, { domain: cookieDomain });
};

// getRegion returns the region in lowercase
// because AW_COOKIE.REGION is set in casino-web by the state-selector and we can't be certain that state-selector's on
// we need to make sure that if there is no AW_COOKIE.REGION but there is a qp, we set it URL/plugin
export const getRegion = () => {
    let returnString = '';
    const queryParam = getQueryParameter('region');
    if (queryParam) {
        returnString = queryParam.toLowerCase();
        if (!Cookie.get(AW_COOKIE.REGION)) {
            setRegionCookie(queryParam);
        }
    } else {
        returnString = Cookie.get(AW_COOKIE.REGION)?.toLowerCase() || Cookie.get('X-Sportsbook-Region')?.toLowerCase();
    }

    return returnString ? returnString : config.defaultRegion;
};

export const getSessionId = () => {
    return Cookie.get(AW_COOKIE.SESSION_ID) || null;
};

export const getUserId = () => {
    return Cookie.get(AW_COOKIE.USER_ID) || null;
};

export const getGeocomplyIpCookie = () => {
    return Cookie.get(GEOCOMPLY_COOKIES.IP_ADDRESS) || null;
};

/**
 * Util for getting a specific cookie value
 * @param cookieName
 * @returns {string}
 */
export const getCookieValue = (cookieName, decode = true) => {
    let cookieValue = '';

    if (typeof document !== 'undefined') {
        const cookie = (document.cookie || '')
            .split(';')
            .map(item => item.trim())
            .find(item => cookieName === item.split('=')[0]);

        if (cookie) {
            const cValue = cookie.split('=')[1] || '';
            cookieValue = decode ? decodeURIComponent(cValue) : cValue;
        }
    }

    return cookieValue;
};
