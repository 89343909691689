const DashboardIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 33 33" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3288 16.2537C1.3288 24.4967 8.01111 31.1791 16.2542 31.1791C24.4972 31.1791 31.1795 24.4967 31.1795 16.2537C31.1795 8.01062 24.4972 1.32831 16.2542 1.32831C8.01111 1.32831 1.3288 8.01062 1.3288 16.2537ZM16.2542 0.134277C7.35167 0.134277 0.134766 7.35118 0.134766 16.2537C0.134766 25.1562 7.35167 32.3731 16.2542 32.3731C25.1567 32.3731 32.3736 25.1562 32.3736 16.2537C32.3736 7.35118 25.1567 0.134277 16.2542 0.134277Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.68622 8.49243C9.02677 8.49243 8.49219 9.02702 8.49219 9.68646V14.6031C8.49219 15.2625 9.02677 15.7971 9.68622 15.7971H14.6028C15.2623 15.7971 15.7968 15.2625 15.7968 14.6031V9.68646C15.7968 9.02702 15.2623 8.49243 14.6028 8.49243H9.68622ZM10.0034 9.40544C9.67366 9.40544 9.40637 9.67274 9.40637 10.0025V14.2869C9.40637 14.6166 9.67366 14.8839 10.0034 14.8839H14.2878C14.6176 14.8839 14.8849 14.6166 14.8849 14.2869V10.0025C14.8849 9.67274 14.6176 9.40544 14.2878 9.40544H10.0034Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.68622 16.7101C9.02677 16.7101 8.49219 17.2447 8.49219 17.9041V22.8207C8.49219 23.4802 9.02677 24.0147 9.68622 24.0147H14.6028C15.2623 24.0147 15.7968 23.4802 15.7968 22.8207V17.9041C15.7968 17.2447 15.2623 16.7101 14.6028 16.7101H9.68622ZM10.0034 17.6231C9.67366 17.6231 9.40637 17.8904 9.40637 18.2202V22.5046C9.40637 22.8343 9.67366 23.1016 10.0034 23.1016H14.2878C14.6176 23.1016 14.8849 22.8343 14.8849 22.5046V18.2202C14.8849 17.8904 14.6176 17.6231 14.2878 17.6231H10.0034Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.904 16.7102C17.2445 16.7102 16.71 17.2448 16.71 17.9042V22.8208C16.71 23.4803 17.2445 24.0149 17.904 24.0149H22.8206C23.48 24.0149 24.0146 23.4803 24.0146 22.8208V17.9042C24.0146 17.2448 23.48 16.7102 22.8206 16.7102H17.904ZM18.2212 17.6232C17.8914 17.6232 17.6241 17.8905 17.6241 18.2202V22.5047C17.6241 22.8344 17.8914 23.1017 18.2212 23.1017H22.5056C22.8353 23.1017 23.1026 22.8344 23.1026 22.5047V18.2202C23.1026 17.8905 22.8353 17.6232 22.5056 17.6232H18.2212Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.904 8.49243C17.2445 8.49243 16.71 9.02702 16.71 9.68646V14.6031C16.71 15.2625 17.2445 15.7971 17.904 15.7971H22.8206C23.48 15.7971 24.0146 15.2625 24.0146 14.6031V9.68646C24.0146 9.02702 23.48 8.49243 22.8206 8.49243H17.904ZM18.2212 9.40544C17.8914 9.40544 17.6241 9.67274 17.6241 10.0025V14.2869C17.6241 14.6166 17.8914 14.8839 18.2212 14.8839H22.5056C22.8353 14.8839 23.1026 14.6166 23.1026 14.2869V10.0025C23.1026 9.67274 22.8353 9.40544 22.5056 9.40544H18.2212Z" fill="white" />
        </svg>
    );
};

export default DashboardIcon;
