import axiosAWInstance from '../../../../common/axios/AWAxios'
import { commonHeadersAW } from '../../../../common/commonHeaders'
import { types } from '../../../types'
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper'
import { fetchEnd, fetchError, fetchStart } from '../../../fetch/fetch'
import config from '../../../../../config/config'
import { HEADERS, MESSAGES } from '../../../../common/constants'
import { getRegion, setSessionStartTimeCookie } from '../../../../common/helpers/cookies'

let hasTriggeredReload = false

export const getCurrentSessionDetailsWeb = () => async dispatch => {
    const requestUid = getRequestUid(FetchType.UserSession)
    const region = getRegion();

    dispatch(fetchStart(requestUid))
    dispatch({ type: types.GET_CURRENT_SESSION_DETAILS_REQUEST })
    await axiosAWInstance
        .get(config.accountAndWalletApi.endpoints.CURRENT_SESSION, {
            headers: { ...commonHeadersAW, [HEADERS.X_PRODUCT_REGION]: region }
        })
        .then(response => {
            const sessionStartTime = response?.data?.created_date
            const mfa = response?.data?.mfa
            const serverTime = response?.headers?.date

            setSessionStartTimeCookie(sessionStartTime)

            dispatch({
                type: types.GET_CURRENT_SESSION_DETAILS_RECEIVED,
                payload: { sessionStartTime, serverTime, mfa }
            })
            dispatch(fetchEnd(requestUid))

            //todo since only the following part is different than android, we could further granulate and handle only the response differently
            if (!hasTriggeredReload) {
                window?.opener?.postMessage?.({ type: MESSAGES.RELOAD }, config.casinoWebUrl)
                hasTriggeredReload = true
            }
        })
        .catch(error => {
            dispatch({
                type: types.GET_CURRENT_SESSION_DETAILS_FAILED,
                payload: { name: error.name }
            })
            dispatch(fetchError(requestUid))
        })
}

export default getCurrentSessionDetailsWeb
