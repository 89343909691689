export const getIconSize = (deviceType, isTitle = false) => {
    const sizes = {
        title: {
            portrait: '32.5',
            landscape: '35.5',
            desktop: '49.34'
        },
        nav: {
            portrait: '27',
            landscape: '27',
            desktop: '32.25'
        }
    };
    return isTitle ? sizes.title[deviceType] : sizes.nav[deviceType];
};
