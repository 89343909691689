import { useEffect, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { locationVerified } from '../../../redux/geo-comply/location/actions/location-verified';
import { getGeocomplyIpCookie, getSessionId, getUserId } from '../../helpers/cookies';
import { rgiSelector } from '../../../selectors/gameContext';
import { RGIS } from '../../constants';
import { experimentsSelector } from '../../../selectors/amplitude-experiments';

const useGeolocationVerifyIos = () => {
    const dispatch = useDispatch();
    const geoPacketValidated = useSelector(state => state?.plc?.validated);
    const [geocomplyIpAddress, setGeocomplyIpAddress] = useState(getGeocomplyIpCookie());
    const [sessionId, setSessionId] = useState(Number(getSessionId()));
    const [userId, setUserId] = useState(getUserId());
    const rgi = useSelector(rgiSelector);
    const ampltiudeExperiments = useSelector(experimentsSelector);

    useEffect(() => {
        const interval = setInterval(() => {
            setGeocomplyIpAddress(getGeocomplyIpCookie());
            setSessionId(Number(getSessionId()));
            setUserId(getUserId());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!geoPacketValidated && rgi === RGIS.INCENTIVE_GAMES) {
            dispatch(
                locationVerified({
                    tokenDetails: {
                        result: true,
                        verified: true,
                        ip_address: geocomplyIpAddress ? geocomplyIpAddress : '10.123.0.55', // TODO: remove hardcoded ip,
                        session_id: sessionId,
                        user_id: userId,
                    },
                    geocomplyEnabled: ampltiudeExperiments?.geocomplyEnabled === 'on'
                })
            );
        }
    }, [dispatch, geoPacketValidated, geocomplyIpAddress, sessionId, userId, rgi]);
};

export default useGeolocationVerifyIos;
