import { Dispatch } from 'redux';
import { GAME_PROVIDER } from '../../../../common/constants/game-constants';
import { setGameLoadComplete } from '../../../../redux/actions/game-window';

// Handler used when the provider doesn`t send a specific "startedLoading" event.

export const handleLoadingScreen = (dispatch: Dispatch, provider?: string) => {
    if (!provider) {
        return;
    }

    switch (provider) {
        case GAME_PROVIDER.IGT:
            break;
        case GAME_PROVIDER.NYX:
            break;
        case GAME_PROVIDER.EVERI:
            dispatch(setGameLoadComplete(false));
            break;
        default:
            break;
    }
};
