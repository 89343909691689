import { DI_SERVICE } from '../constants'
import { register } from '../utils'

import RealityCheck from '../../components/modal/reality-check'

const registerRealityCheck = () => register(
    DI_SERVICE.realityCheck,
    [DI_SERVICE.APP_PLATFORM]
)(RealityCheck)

export default registerRealityCheck
