const configuration = process.env.NODE_CONFIG && JSON.parse(process.env.NODE_CONFIG);
const env = process.env.NODE_CONFIG_ENV;
const applicationId = process.env.APPLICATION;

const config = configuration || {}
console.log(`Application "${applicationId}" loaded with configuration for "${env}" environment`);

if (env === 'devstack') {
    config.casinoWebUrl = `https://${process.env.DEVSTACK_NAME}.${config.devstackDomain}`;
}

export default config;
