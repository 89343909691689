import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IRmSendPlayerData {
    customerData: Record<string, unknown>;
    gameURL: string;
}

export const ddActionRmSendPlayerData = ({ ...context }: IRmSendPlayerData, use_case: IddUseCases): IddRumAction => ({
    name: 'RM_SEND_PLAYER_DATA',
    description: 'RM send player data to IG',
    context,
    use_case
});
