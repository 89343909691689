// method to decide if the element was shown
import {
    FEATURE_PROGRESS,
    FeatureProgressType,
    IShowoffItemFormatted,
    ShowoffElementType,
    ShowoffStructure,
    StepType
} from './showoff.types';

const decideToShow = (
    feature: string,
    show: boolean,
    featuresMap: Map<string, string[]>,
    highestOrder: { order: number },
    dashboardItem: { type: ShowoffElementType; show: boolean },
    order?: number
): { notice?: boolean; show?: boolean } => {
    if (!show || (dashboardItem && !dashboardItem.show)) {
        return {};
    }

    if (featuresMap.get(FEATURE_PROGRESS.FULL).find(item => item === feature)) {
        if (order && order > highestOrder.order) {
            highestOrder.order = order;
        }

        return {};
    } else if (featuresMap.get(FEATURE_PROGRESS.NOTICED).find(item => item === feature)) {
        return { notice: true };
    }

    return { show: true };
};

// going on depth on the tree and creating steps for every marked feature
const createTree = (
    base: ShowoffStructure,
    featuresMap: Map<FeatureProgressType, ShowoffElementType[]>,
    toShow: IShowoffItemFormatted[],
    steps: StepType[],
    highestOrder: { order: number },
    dashboardItems: { type: ShowoffElementType; show: boolean }[]
) => {
    const items = Object.values(base);

    items.forEach((item, index) => {
        steps.push({
            attr: item.attributeName,
            focusType: item.focusType,
            bypassCondition: item?.bypassCondition,
            dismissType: item.dismissType
        });

        const res = {
            name: item.attributeName,
            ...decideToShow(
                item.attributeName,
                item.feature,
                featuresMap,
                highestOrder,
                dashboardItems?.find(dashItem => dashItem.type === item.attributeName, item.order)
            ),
            order: item.order,
            dismissType: item.dismissType
        };

        item.children && createTree(item.children, featuresMap, toShow, steps, highestOrder, dashboardItems);

        const addToEndOrStart = index > 0 ? 'push' : 'unshift';

        if (res.show) {
            toShow?.[addToEndOrStart]({ ...res, steps: [...steps], order: res.order });
        } else if (res.notice) {
            toShow?.[addToEndOrStart]({ ...res, steps: [...steps] });
        }

        steps.pop();
    });
};

export default createTree;
