import { useCallback, useEffect, useMemo } from 'react';
import { useGameClientSelectors } from './useGameClientSelectors';
import sendTrendingGames from '../../../../redux/actions/trending-games/trending-games';
import { useDispatch } from 'react-redux';

export const useGameClientInitializer = (provider: string): GCInitResult => {
    const dispatch = useDispatch();

    const { gameContext, gameUrl, shouldRespondToHandshake } = useGameClientSelectors();

    const {
        rgi,
        region,
        brand,
        gameName,
        gameUid,
        providerRef,
        ganId,
        userId,
        xsellGeocomplyToken,
        directLaunchParams,
        amplitudeExperiments,
        nativeId,
        gameId,
        jackpotMode,
    } = gameContext;

    const initializeGameParams = useMemo(
        () => ({
            rgi,
            brand,
            gameName,
            provider,
            providerRef,
            ganId,
            region,
            gameUid,
            userId,
            jackpotMode,
            ...(xsellGeocomplyToken && { geocomplyToken: xsellGeocomplyToken }),
            ...(directLaunchParams && { directLaunchParams })
        }),
        [
            rgi,
            brand,
            gameName,
            provider,
            providerRef,
            ganId,
            region,
            gameUid,
            userId,
            xsellGeocomplyToken,
            directLaunchParams,
            jackpotMode
        ]
    );

    const shouldRenderClientIframe = !!(
        rgi &&
        brand &&
        gameName &&
        provider &&
        providerRef &&
        ganId &&
        region &&
        gameUid &&
        userId
    );

    const performHandshake = useCallback(() => {
        if (!shouldRespondToHandshake) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log('Performing handshake from Launcher-WEB.');

        const gameClientFrame = document.querySelector<HTMLIFrameElement>('#casino-game-client');

        gameClientFrame?.contentWindow.postMessage(
            {
                type: 'GL/INITIALIZE_HANDSHAKE_PARAMS',
                payload: {
                    initializeGameParams,
                    amplitudeEvents: { geocomplyEnabled: amplitudeExperiments?.geocomplyEnabled }
                }
            },
            '*'
        );
    }, [initializeGameParams, shouldRespondToHandshake]);

    useEffect(() => performHandshake(), [performHandshake]);

    useEffect(() => {
        if (gameId && userId) {
            dispatch(sendTrendingGames());
        }
    }, [dispatch, gameId, userId]);

    return {
        shouldRenderClientIframe,
        gameUrl,
        gameName,
        nativeId
    };
};

type GCInitResult = {
    shouldRenderClientIframe: boolean;
    gameUrl: URL;
    gameName: string;
    nativeId: string;
};
