import { PLATFORM } from '../../common/constants/game-constants'

import WindowTitleWeb from './window-title-web'
import WindowTitleXsell from './window-title-xsell'

const windowTitleByPlatformMap = {
    [PLATFORM.Desktop]: WindowTitleWeb,
    [PLATFORM.Android]: WindowTitleWeb,
    [PLATFORM.Xsell]: WindowTitleXsell,
    [PLATFORM.Ios]: WindowTitleWeb,
}

/**
 * windowTitle factory by platform context
 *
 * @param {string} platform
 * @returns {function} current windowTitle component
 */
export default function windowTitleFactory(platform) {
    return windowTitleByPlatformMap[platform]
}
