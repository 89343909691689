import { useEffect, useLayoutEffect } from 'preact/hooks';

import { useGeocomplyHelpers } from './use-geocomply-helpers';

const useGeocomplyFlowWeb = () => {
    const { triggerGeocomply, wrapperResponseHandler } = useGeocomplyHelpers();

    useEffect(() => {
        triggerGeocomply();
    }, [triggerGeocomply]);

    useLayoutEffect(() => {
        window.addEventListener('geolocationResult', wrapperResponseHandler);

        return () => window.removeEventListener('geolocationResult', wrapperResponseHandler);
    }, [wrapperResponseHandler]);
};

export default useGeocomplyFlowWeb;
