export const GEOCOMPLY_LICENSE_FETCH_FAILED = 'GEOCOMPLY_LICENSE_FETCH_FAILED'

// Reducer
export const reducer = (state, action) => {
    return {
        ...state,
        error: action.payload,
        loading: false
    }
}

// Action Creators
export const fetchLicenseFailed = error => dispatch => {
    console.warn('LICENSE FETCHING FAILED')
    dispatch({
        type: GEOCOMPLY_LICENSE_FETCH_FAILED,
        payload: { name: error.name }
    })
}
