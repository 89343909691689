import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useMemo } from 'preact/hooks';
import { useSelector } from 'react-redux';
import config from '../../../../config/config';
import connectionType from '../../helpers/connectionType';
import { getAWCookies, getRegion } from '../../helpers/cookies';
import { isRunningInsideWrapper, wrapperLocateUser } from '../../wrapper-bridge-mobile';
import { useGeocomplyCallback } from './use-geocomply-callback';
import { rgiSelector } from '../../../selectors/gameContext';
import { RGIS } from '../../constants';

 export const getEnvironment = () => {
    const env = config.env;
    const geoComplyEnv = config.geocomplyEnv;

    if (env === 'prod') {
        return { type: 'prod' };
    } else if (geoComplyEnv === 'qa') {
        return { type: 'qa' };
    } else if (typeof geoComplyEnv === 'object') {
        return geoComplyEnv;
    }

    return { type: 'cert' };
};

export const useGeocomplyHelpers = () => {
    const userId = useSelector(state => state?.session?.userId);
    const sessionId = useSelector(state => state?.session?.sessionId);
    const { authToken } = getAWCookies();
    const rgi = useSelector(rgiSelector);

    const country = useMemo(() => config?.geolocation?.country || (process.env.COUNTRY === 'usa' ? 'US' : 'CA'), []);

    const appName = 'game-launcher';
    const appVersion = process.env.BUILD_VERSION ?? '1.0.0';

    const { geolocationCallback, handleGeolocationFailure } = useGeocomplyCallback();

    const setConfig = useCallback(
        (session, geoInstance) => {
            const environment = getEnvironment();
            localStorage.removeItem('location token');

            geoInstance
                ?.setConfig({
                    appDomain: {
                        product: 'casino',
                        region: getRegion().toUpperCase()
                    },
                    environment,
                    clientAuthToken: window.atob(config.clientID),
                    installerKey: config.geoComply.plc.installerKey,
                    appName,
                    appVersion,
                    country,
                    session
                })
                .catch(error => {
                    datadogRum.addError(
                        new Error('Error setting geocomply config'),
                        Object.assign(
                            {
                                type: 'GEOCOMPLY_CONFIG',
                                connectionType: connectionType()
                            },
                            error
                        ),
                    );
                });
        },
        [appVersion, country]
    );

    const wrapperResponseHandler = useCallback(
        e => {
            if (e.detail) {
                geolocationCallback(e.detail);
            } else {
                handleGeolocationFailure({ error: 'Failed to decode wrapper event', errorCode: 1 });
            }
        },
        [geolocationCallback, handleGeolocationFailure]
    );

    const triggerGeocomply = useCallback(() => {
        if (authToken && sessionId && userId && rgi === RGIS.INCENTIVE_GAMES) {
            if (isRunningInsideWrapper()) {
                wrapperLocateUser(userId, authToken, sessionId, window.atob(config.clientID));
            } else {
                import('@fanduel/aw-geolocation-react').then(({ Geolocation }) => {
                    Geolocation?.init(appName, appVersion);
                    Geolocation?.setCallback(geolocationCallback);
                    setConfig(
                        {
                            userAuthToken: authToken,
                            sessionId,
                            userId
                        },
                        Geolocation
                    );
                    Geolocation?.locateUser('login');
                });
            }
        }
    }, [appVersion, authToken, geolocationCallback, rgi, sessionId, setConfig, userId]);

    return { triggerGeocomply, wrapperResponseHandler };
};
