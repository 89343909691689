import { useSelector } from 'react-redux';
import { useEffect } from 'preact/hooks';

export const useJackpotWinTrigger = ({ setQueue, addToQueue }) => {
    const wonJackpotsList = useSelector(state => state?.['siteWideJackpot']?.jackpotWin);
    const wonJackpot = wonJackpotsList[0];

    const clearQueue = () => {
        setQueue(prev => {
            const jackpotIndex = prev.findIndex(item => item.type === 'jackpot_win');

            if (jackpotIndex !== -1) {
                const current = [...prev];

                current.splice(jackpotIndex, 1);

                return current;
            }

            return prev;
        });
    };

    useEffect(() => {
        if (wonJackpot) {
            clearQueue(); // in case of multiple jackpot wins
            addToQueue({ type: 'jackpot_win', data: wonJackpot });
        } else {
            clearQueue();
        }
    }, [wonJackpot]);
}
