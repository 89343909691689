import { h } from 'preact';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checked from '../../../../assets/icons/Map/checked';
import Pin from '../../../../assets/icons/Map/pin';

require(`./styles/${process.env.APPLICATION}/style.scss`);

interface MessageNoteProps {
    type: 'verified' | 'pending';
}

export const MessageNote = ({ type }: MessageNoteProps) => {
    const { t } = useTranslation();
    const messageString = `geocomply.locationNote.${type}`;
    const message = `${t(messageString)}`;

    return (
        <div className={`location-note ${type}`}>
            {type === 'verified' ? <Checked /> : <Pin />}
            <span>{message}</span>
        </div>
    );
};

export const LocationNote = () => {
    const verified = useSelector(state => state?.['geocomply']?.location?.verified);
    const isRetrying = useSelector(state => state?.['geocomply']?.location?.retrying);

    return verified === null || isRetrying ? (
        <MessageNote type="pending" />
    ) : verified === true ? (
        <MessageNote type="verified" />
    ) : null;
};

export default LocationNote;
