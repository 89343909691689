export type Undef<T> = T | undefined;

const AMPLITUDE_GLOBAL_PROPERTY = {
    FULL_URL: ['Full URL', 'fullUrl'], //web
    HOSTNAME: ['Hostname', 'hostname'], //web
    PAGE_NAME: ['Page Name', 'pageName'], //web
    PAGE_PATH: ['Page Path', 'pagePath'], //web/react
    PAGE_TITLE: ['Page Title', 'pageTitle'], //web
    LOGIN_STATUS: ['Login Status', 'login_status'],
    PRODUCT: ['Product', 'product']
} as const;

const AMPLITUDE_USER_PROPERTY = {
    JURISDICTION: ['Jurisdiction', 'jurisdiction'],
    NATIONAL_JURISDICTION: ['National Jurisdiction','nationalJurisdiction'],
    SITE_PLATFORM: ['Site Platform', 'sitePlatform'],
    SITE_VERSION: ['Site Version', 'siteVersion'],
    USER_LOCATION: ['User Location', 'user_location'],
    ACCOUNT_ID: ['Account ID', 'acc_id'],
    DEVICE: ['Device', 'device'],
    LAUNCHED_FROM: ['Launched From', 'launchedFrom'],

} as const;

const AMPLITUDE_REST_PROPERTIES = {
    BRAND: ['Brand', 'brand'],
    ANDROID_DISTRIBUTION_METHOD: ['Android Distribution Method', 'androidVersion'], //native
    BALANCE: ['Balance', 'balance'],
    STATE_SELECTED: ['State Selected', 'state'],
    EVENT: ['Event', 'event'],
    MODULE: ['Module', 'module'],
    GAME_NAME: ['Game Name', 'gameName'],
    POSITION: ['Position', 'position'],
    GAME_ID: ['Game ID', 'gameId'],
    IS_FAVORITE: ['Is Favorite', 'isFavorite'],
    IS_JACKPOT: ['Is Jackpot', 'isJackpot'],
    COMPONENT: ['Component', 'component'],
    GAME_PROVIDER: ['Game Provider', 'gameProvider'],
    APP_NAME: ['App Name', 'appName'], //native
    APP_ID: ['App ID', 'appId'], //native
    APP_VERSION: ['App Version', 'appVersion'],
    ORIENTATION: ['Orientation', 'orientation'],
    ZONE_NAME: ['Zone Name', 'zoneName'],
    LINK_URL: ['Link URL', 'linkUrl'],
    LINK_TEXT: ['Link Text', 'linkText'],
    TAG: ['Tag', 'tag'],
    BANNER_NAME: ['Banner Name', 'bannerName'],
    BANNER_TYPE: ['Banner Type', 'bannerType'],
    BANNER_ID: ['Banner ID', 'bannerId'],
    ERROR_TYPE: ['Error Type', 'errorType'],
    LAUNCHED_FROM: ['Launched From', 'launched`from']
} as const;

export const ZONES = {
    DASHBOARD: 'dashboard',
    IN_GAME: 'in_game',
    GAME_HELP: 'game_help'
} as const;

export const MODULES = {
    GW: 'game_window',
    GW_ERROR: 'game_window_error_modal',
    IN_GAME: 'in_game',
    IN_GAME_HEADER: 'in_game_header',
    IN_GAME_FOOTER: 'in_game_footer',
    RECENTLY_PLAYED: 'in_game_recently_played'
} as const;

export const AMPLITUDE_ERROR_EVENTS = {
    ERROR_VIEWED: 'In Game Error Modal Viewed',
    ERROR_CLICKED: 'In Game Error CTA Clicked'
}


export const USER_TRACKING_ALL_PROPS = { ...AMPLITUDE_USER_PROPERTY, ...AMPLITUDE_GLOBAL_PROPERTY, ...AMPLITUDE_REST_PROPERTIES } as const;

export const getAmplitudeKey = (key: keyof typeof USER_TRACKING_ALL_PROPS) => USER_TRACKING_ALL_PROPS[key][0]

export type AmplKeys =
    | typeof AMPLITUDE_USER_PROPERTY[keyof typeof AMPLITUDE_USER_PROPERTY][0]
    | typeof AMPLITUDE_GLOBAL_PROPERTY[keyof typeof AMPLITUDE_GLOBAL_PROPERTY][0]
    | typeof AMPLITUDE_REST_PROPERTIES[keyof typeof AMPLITUDE_REST_PROPERTIES][0];

export type GTMKeys =
    | typeof AMPLITUDE_USER_PROPERTY[keyof typeof AMPLITUDE_USER_PROPERTY][1]
    | typeof AMPLITUDE_GLOBAL_PROPERTY[keyof typeof AMPLITUDE_GLOBAL_PROPERTY][1]
    | typeof AMPLITUDE_REST_PROPERTIES[keyof typeof AMPLITUDE_REST_PROPERTIES][1];

export type OnEventType = (event: string, eventName?: string, eventData?: { [key: string]: string }) => void;
