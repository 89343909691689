import { PLATFORM } from '../../../common/constants/game-constants';
import { isRunningInsideV3Wrapper } from '../../../common/wrapper-bridge-mobile';

import RealityCheckWeb from './reality-check-web';
import RealityCheckXsell from './reality-check-xsell';

const realityCheckByPlatformMap = {
    [PLATFORM.Desktop]: RealityCheckWeb,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    [PLATFORM.Android]: !isRunningInsideV3Wrapper() ? RealityCheckWeb : () => {},
    [PLATFORM.Xsell]: RealityCheckXsell,
    [PLATFORM.Ios]: RealityCheckXsell
};

/**
 * RealityCheck factory by platform context
 *
 * @param {string} platform
 * @returns {function} current RealityCheck component
 */
export default function realityCheckFactory(platform) {
    return realityCheckByPlatformMap[platform];
}
