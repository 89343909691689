import { h } from 'preact';

const ArrowLeft = props => {
    return (
        <svg width="13px" height="24px" fill="none" {...props}>
            <path
                d="M13.729 24.02a1.324 1.324 0 0 0 0-1.87l-9.652-9.652 9.652-9.651A1.324 1.324 0 0 0 11.857.975L1.269 11.564a1.33 1.33 0 0 0 0 1.874l10.588 10.588a1.33 1.33 0 0 0 1.872-.005Z"
                fill="#fff"
            />
        </svg>
    );
};

export { ArrowLeft };
