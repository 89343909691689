import { types } from '../../types';
import { datadogRum } from '@datadog/browser-rum';
import { FabricServicesAxios, getFabricEndpoint, getFabricURL } from '../../../common/axios/FabricServicesAxios';
import { RECENTLY_GAMES_OPERATION, AGGREGATION_HEADERS } from '../../../common/constants';
import { FetchType, getRequestUid } from '../../../common/helpers/fetch-helper';
import { getRegion } from '../../../common/helpers/cookies';
import { fetchEnd, fetchError, fetchStart } from '../../fetch/fetch';
import connectionType from '../../../common/helpers/connectionType';
import { getBrand } from '../../../common/helpers/brand';

export const getRecentlyPlayed = deviceType => async dispatch => {
    const requestUid = getRequestUid(FetchType.GetRecentlyPlayed);
    const recentlyGamesEndpoint = getFabricEndpoint('userActivity', 'recentlyPlayedGames');
    const fabricURL = getFabricURL();
    const baseURL = process.env.NODE_CONFIG_ENV === 'local-development' ? `${fabricURL}:4000` : fabricURL;
    const product = process.env.PRODUCT;
    const operation = RECENTLY_GAMES_OPERATION.GET;
    const region = getRegion();
    const brand = getBrand();
    const platform = deviceType === 'desktop' ? 'desktop' : 'mobile';
    const apiUrl = `${recentlyGamesEndpoint}/${operation}?includeGameDetails=true&product=${product}&gameIdentifierType=id&platform=${platform}`;

    try {
        dispatch(fetchStart(requestUid));
        dispatch({ type: types.GET_USER_ACTIVITY_REQUEST });

        const response = await FabricServicesAxios().get(apiUrl, {
            baseURL,
            headers: {
                [AGGREGATION_HEADERS.X_BRAND]: brand,
                [AGGREGATION_HEADERS.X_REGION_CODE]: region
            },
            withCredentials: true
        });

        const recentlyPlayedData = response?.data;

        dispatch({
            type: types.GET_USER_ACTIVITY_RECEIVED,
            payload: recentlyPlayedData
        });

        datadogRum.addAction('GET_RECENTLY_PLAYED_RECEIVED');

        dispatch(fetchEnd(requestUid));
    } catch (error) {
        console.log('Error get recently played', error);
        dispatch(fetchError(requestUid));
        dispatch({
            type: types.GET_USER_ACTIVITY_FAILED,
            payload: error?.name
        });

        datadogRum.addError(
            new Error(error),
            Object.assign(
                {
                    type: 'GET_RECENTLY_PLAYED_FAILED',
                    connectionType: connectionType()
                },
                error
            ),
        );
    }
};

export default getRecentlyPlayed;
