import ExitPopup from '../../../modal/exit-popup/exit-popup';
import { LicenseCard } from '../../../modal/license-card/LicenseCard';
import ExitPopupRecentlyPlayed from '../../../modal/exit-popup/exit-popup-recently-played';
import { GameWindowErrorPopup } from '../../../modal/game-window-error/GameWindowErrorPopup';
import { cloneElement, h } from 'preact';
import { useContext, useMemo, useState } from 'preact/hooks';
import { GameWindowContext } from '../../GameWindow';
import Modal from '../../../modal/modal';
import { isIosWrapper } from '../../../../common/wrapper-bridge-mobile';
import { Popup } from '../../../Popup/Popup';
import { useJackpotWinTrigger } from './helpers/useJackpotWinTrigger';
import { usePopupTrigger } from './helpers/usePopupTrigger';
import { IGameWindowContext, JackpotWinType, MessageTypes, queueOrder } from './types';
import { useProviderErrorsTrigger } from './helpers/useProviderErrorsTrigger';
import { JackpotWinningAnimation, WonJackpotData } from '../JackpotWinningAnimation/JackpotWinningAnimation';

const queueMapper = queueOrder.reduce((acc, type, index) => {
    return { ...acc, [type]: index };
}, {});

const formatQueue = (queue: { type: MessageTypes; data: null | JackpotWinType }[]): JSX.Element[] => {
    return queue.map((message, index) => {
        if (index >= 2) {
            return null;
        }

        switch (message.type) {
            case 'jackpot_win':
                return cloneElement(<JackpotWinningAnimation wonJackpot={message.data as unknown as WonJackpotData} />);
            case 'exit_popup':
                return cloneElement(<ExitPopup />);
            case 'exit_popup_recently_played':
                return cloneElement(<ExitPopupRecentlyPlayed />);
            case 'swj_error': // TBI
            case 'provider_error':
            case 'reality_check':
                break; // standalone components
        }
    });
};

export const DisplayQueue = () => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    const { isPortrait, dismissErrorMessage, exitGame, disableGameWindow, chooseDialog } = useContext(
        GameWindowContext
    ) as IGameWindowContext;

    const [queue, setQueue] = useState([]);
    const displayQueue: JSX.Element[] = useMemo(() => formatQueue(queue), [queue]);

    const queueAssigner = (prevQueue, message: { type: MessageTypes; data: null | JackpotWinType }) => {
        if (prevQueue.length === 0) {
            return [message];
        }

        if (message.type === 'reality_check') {
            const tempQueue = [...prevQueue];
            const currentMessagePriority = queueMapper[message.type];
            const convertedQueueToPriority = tempQueue.map(({ type }) => queueMapper[type]);

            const position = convertedQueueToPriority.findLastIndex(priority => {
                return priority <= currentMessagePriority;
            });

            tempQueue.splice(position + 1, 0, message);

            return tempQueue;
        }

        return [...prevQueue, message];
    };

    const addToQueue = message => {
        setQueue(prev => queueAssigner(prev, message));
    };

    usePopupTrigger({ setQueue, addToQueue });
    useJackpotWinTrigger({ setQueue, addToQueue });
    useProviderErrorsTrigger({ setQueue, addToQueue });

    return (
        <div id="display-queue">
            {!disableGameWindow && (
                <GameWindowErrorPopup
                    handlers={{ dismissErrorMessage, exitGame, chooseDialog }}
                    isPortrait={isPortrait}
                />
            )}
            {displayQueue.map(elem => elem)}
            <Modal />
            <LicenseCard isPortrait={isPortrait} />
            {!isIosWrapper() && <Popup />}
        </div>
    );
};
