import { h } from 'preact';
import Helmet from 'preact-helmet';
import { useTranslation } from 'react-i18next';
import { renderGtmScript, renderGtmNoScript } from './renderGtm';
import { useSelector } from 'react-redux';
import { currentTimeSelector, gameTimeSelector, sessionTimeSelector } from '../../selectors/game-window';
import { gameNameSelector } from '../../selectors/gameContext';

const WindowTitleWeb = () => {
    const { t } = useTranslation();

    const sessionTime = useSelector(sessionTimeSelector);
    const gameTime = useSelector(gameTimeSelector);
    const currentTime = useSelector(currentTimeSelector);

    const gameName = useSelector(gameNameSelector);

    const gameNameString = gameName ? `${gameName.toUpperCase()} - ` : '';

    const sessionTimeString = `${t('global.sessionTime')}: ${sessionTime}`;
    const gameTimeString = `${t('global.gameTime')}: ${gameTime}`;
    const currentTimeString = `${t('global.currentTime')}: ${currentTime}`;

    const script = [
        { innerHTML: renderGtmScript() },

    ].map(scriptElem => ({
        type: 'text/javascript',
        ...scriptElem
    }));

    return (
        <Helmet
            title={`${gameNameString}${sessionTimeString} | ${gameTimeString} | ${currentTimeString}`}
            link={[{ rel: 'shortcut icon', href: `/assets/favicon/${process.env.BRAND}/favicon.ico` }]}
            script={script}
            noscript={[{ innerHTML: renderGtmNoScript() }]}
        />
    );
};

export default WindowTitleWeb;
