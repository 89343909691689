export const OptInButton = ({ isSpinInProgress, userOptedIn, showTCPopup, handleShowPopup }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    return (
        <button
            disabled={isSpinInProgress}
            className={`swj-opt-in-button-${!userOptedIn ? 'out' : 'in'} ${showTCPopup ? 'show-popup' : 'hide-popup'} ${
                isSpinInProgress ? 'disabled' : 'enabled'
            }`}
            onClick={handleShowPopup}
        >
            {!showTCPopup ? `Opt ${!userOptedIn ? 'in' : 'out'}` : <div className="tc-exit-icon" />}
        </button>
    );
}
