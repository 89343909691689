import { SocialProofConsumer } from "./social-proof-consumer";
import {
  WinnersFeedOptions,
  WinnersFeedParams,
  WinnersFeedResponse,
} from "./types";

/**
 * Enables the consumption of the winners feed though a polling mechanism.
 */
export class WinnersFeed extends SocialProofConsumer<WinnersFeedResponse> {
  private numberOfUsers: number;
  protected readonly endpoint = "/jackpot/api/v1/winners";
  constructor({
    host,
    region,
    jackpotIds,
    interval,
    numberOfUsers,
    callback,
    error,
  }: WinnersFeedOptions) {
    super({ host, region, jackpotIds, interval, callback, error });
    this.numberOfUsers = numberOfUsers || 5; // Defaults to 5
  }

  protected getParams(): WinnersFeedParams {
    return {
      jackpotIds: this.jackpotIds.join(","),
      numberOfUsers: `${this.numberOfUsers}`,
    };
  }
}
