import thunk from 'redux-thunk';

import { rootReducer } from './reducers/rootReducers';
// import { throttle } from 'lodash'
import { hydrateStore, saveStateMiddleware } from './localStorage';
import { configureStore } from '@reduxjs/toolkit';

const middleware = [thunk, saveStateMiddleware];

const store = configureStore({
    reducer: {
        ...rootReducer
    },
    middleware,
    preloadedState: hydrateStore()
});

// We'll subscribe to state changes, saving the store's state to the browser's
// local storage. We'll throttle this to prevent excessive work.
// store.subscribe(throttle(() => saveStateMiddleware(store.getState()), 1000))

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
