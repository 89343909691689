import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';
import { IRmMessageReceivedContext } from './types';

interface IRmMessageReceivedLoginAction extends IRmMessageReceivedContext {
    ios?: boolean;
}

export const ddActionRmMessageReceivedLoginAction = (
    { ios, ...context }: IRmMessageReceivedLoginAction,
    use_case: IddUseCases
): IddRumAction => ({
    name: `RM_MESSAGE_RECEIVED_LOGIN_ACTION${ios ? '_IOS' : ''}`,
    description: 'RM user session expired',
    context,
    use_case
});
