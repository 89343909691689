export const formatNumber = (num, hideTrailingZeros, decimals = 2) => {
    if (num === undefined || num === null) {
        return undefined
    }

    const isWholeNumber = num % 1 === 0
    const fractionDigits = hideTrailingZeros && isWholeNumber ? 0 : decimals

    const localeNumber = num.toLocaleString(undefined, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    })

    return hideTrailingZeros && fractionDigits ? localeNumber.replace(/0+$/, '') : localeNumber
}
