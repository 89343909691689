import { ADD_FUNDS_ICON_SIZES } from '../../common/constants';
import { AddFundsButton } from '../GameWindow/components/AddFundsButton/AddFundsButton';

interface AddFundsProps {
    showDepositButton: boolean,
    depositLink: string,
    deviceType: string,
    wrapperClassName: string
}

export const AddFunds = ({ showDepositButton, depositLink, deviceType, wrapperClassName }: AddFundsProps) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    return (
        !!showDepositButton &&
            <div className={wrapperClassName}>
                <div className="add-funds-label">ADD FUNDS</div>
                <AddFundsButton
                    size={ADD_FUNDS_ICON_SIZES.FOOTER}
                    deviceType={deviceType}
                    depositLink={depositLink}
                    clickedLocation="footer"
                    sendDDEvent
                />
            </div>
    );
};
