import { getTokens } from "./helpers";

export const composeSubscriptionMessage = (userId: string, host: string) => {
  const { authToken, loginToken } = getTokens();

  return JSON.stringify({
    id: crypto.randomUUID(),
    type: "start",
    payload: {
      data: JSON.stringify({
        query: `subscription MySubscription { addedMessage(userId: "${userId}") { createdTs messageId payload priority subtype ttl type userId }}`,
      }),
      extensions: {
        authorization: {
          Authorization: `X-Auth-Token=${authToken};X-Login-Token=${loginToken}`,
          host,
        },
      },
    },
  });
};

export const composeAcknowledgementMessage = ({
  userId,
  messageId,
  createdTs,
}: {
  userId: string;
  messageId: string;
  createdTs: number;
}) => {
  return {
    query:
      "mutation AcknowledgeMutation { " +
      "acknowledgeMessage(input: " +
      "{" +
      `createdTs: ${createdTs}, ` +
      `messageId: "${messageId}", ` +
      `userId: "${userId}"` +
      "})" +
      " { " +
      "createdTs " +
      "messageId " +
      "userId " +
      "}}",
  };
};

export const composeGetUndeliveredMessages = (userId: string) => {
  return {
    query: `query MyQuery { getUndeliveredMessages(userId: \"${userId}\") { messages { createdTs isPending messageId payload priority subtype ttl type userId } }}`,
  };
};
