import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import axios from 'axios';

import { getFabricEndpoint, getFabricURL } from '../../../../common/axios/FabricServicesAxios';
import { FetchType, getRequestUid } from '../../../../common/helpers/fetch-helper';
import { types } from '../../../types';
import { fetchEnd, fetchError, fetchStart } from '../../../fetch/fetch';
import { TIMERS_TIMESTAMPS } from '../../../../common/constants';

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);

const { SERVER_MARK } = TIMERS_TIMESTAMPS;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const getServerTimeWeb = (timezone = dayjs.tz.guess(), initializeTicker = () => {}) => async dispatch => {
    const requestUid = getRequestUid(FetchType.ServerTime);
    const fabricURL = getFabricURL();
    const endpoint = getFabricEndpoint('aggregation', 'serverTime');
    const baseURL = process.env.NODE_CONFIG_ENV === 'local-development' ? `${fabricURL}:4000` : fabricURL;

    dispatch(fetchStart(requestUid));
    dispatch({ type: types.GET_CURRENT_SERVER_TIME_REQUEST });

    await axios
        .create()
        .get(`${endpoint}?timezone=${timezone}`, {
            baseURL
        })
        .then(res => {
            dispatch({ type: types.GET_CURRENT_SERVER_TIME_RECEIVED, payload: res?.data });
            dispatch(fetchEnd(requestUid));
        })
        .catch(error => {
            dispatch({ type: types.GET_CURRENT_SERVER_TIME_FAILED, payload: { name: error.name } });
            dispatch(fetchError(requestUid));
        })
        .finally(() => {
            window?.performance?.mark?.(SERVER_MARK);
            initializeTicker();
        });
};

export default getServerTimeWeb;
