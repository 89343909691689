import { ACTION_TYPE, DISMISS_TYPE, FOCUS_TYPE, SHOWOFF_ELEMENTS, ShowoffStructure } from './showoff.types';

const {
    MENU_BUTTON,
    DASHBOARD_BUTTON,
    GAME_HELP_BUTTON,
    TIMERS,
    MY_ACCOUNT_CARD,
    RECENTLY_PLAYED_CARD,
    EXIT_BUTTON
} = SHOWOFF_ELEMENTS;

const structure: ShowoffStructure = {
    MENU_BUTTON: {
        step: true,
        attributeName: MENU_BUTTON,
        focusType: FOCUS_TYPE.OVERLAY,
        dismissType: DISMISS_TYPE.ACTION,
        bypassCondition: elem => {
            return !document.querySelector(`[data-showoff="${elem?.getAttribute('data-showoff')}"]`);
        },
        children: {
            DASHBOARD_BUTTON: {
                step: true,
                attributeName: DASHBOARD_BUTTON,
                focusType: FOCUS_TYPE.NOTHING,
                dismissType: DISMISS_TYPE.ACTION,
                bypassCondition: elem => {
                    return elem?.className?.includes('highlight');
                },
                children: {
                    MY_ACCOUNT_CARD: {
                        feature: true,
                        focusType: FOCUS_TYPE.NOTHING,
                        dismissType: DISMISS_TYPE.TIMEOUT,
                        attributeName: MY_ACCOUNT_CARD,
                        order: 1,
                        triggerAction: ACTION_TYPE.SWIPE_TO
                    },
                    RECENTLY_PLAYED_CARD: {
                        feature: true,
                        focusType: FOCUS_TYPE.NOTHING,
                        dismissType: DISMISS_TYPE.TIMEOUT,
                        attributeName: RECENTLY_PLAYED_CARD,
                        order: 0,
                        triggerAction: ACTION_TYPE.SWIPE_TO
                    }
                }
            },
            GAME_HELP_BUTTON: {
                step: true,
                focusType: FOCUS_TYPE.NOTHING,
                attributeName: GAME_HELP_BUTTON,
                bypassCondition: elem => {
                    return elem.className.includes('highlight');
                }
            }
        }
    },
    TIMERS: {
        focusType: FOCUS_TYPE.OVERLAY,
        attributeName: TIMERS
    },
    EXIT_BUTTON: {
        focusType: FOCUS_TYPE.BOX,
        attributeName: EXIT_BUTTON
    }
};

export default structure;
