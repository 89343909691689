import { PLATFORM } from '../../constants/game-constants'
import VoidService from '../../helpers/void-service'
import useGeocomplyLicenseAndroid from './use-geocomply-license-android'

export const useGeocomplyLicenseByPlatformMap = {
    [PLATFORM.Desktop]: VoidService,
    [PLATFORM.Android]: useGeocomplyLicenseAndroid,
    [PLATFORM.Xsell]: VoidService,
    [PLATFORM.Ios]: VoidService
}

/**
 * useGeocomplyLicense factory by platform context
 *
 * @param {string} platform
 * @returns {function} current useGeocomplyLicense hook
 */
export default function useGeocomplyLicenseFactory(platform) {
    return useGeocomplyLicenseByPlatformMap[platform]
}
