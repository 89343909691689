import { useSelector } from 'react-redux';
import { useEffect } from 'preact/hooks';
import { useOrientation } from './useOrientation';

import { regionSelector } from '../../selectors/gameContext';
import { getBrand } from '../helpers/brand';
import { getAndroidAppVersion, getAndroidDistribution, getDevice, launchedFrom, gtmPushToDatalayer } from '../helpers/user-tracking.helpers';
import { useGtmPush } from './useGtmPush';

const useGtm = () => {
    const acc_id = useSelector(state => state?.userContextReducer?.userContextData?.accountSettings?.accountID);
    const login_status = useSelector(state => state?.userContextReducer?.userContextData?.accountSettings?.loggedIn);
    const locale = useSelector(state => state?.userContextReducer?.userContextData?.accountSettings?.localeCode);
    const country = useSelector(state => state?.userContextReducer?.userContextData?.accountSettings?.countryCode);
    const region = useSelector(regionSelector);

    const { deviceType } = useOrientation();
    const { gtmPush } = useGtmPush();

    useEffect(() => {
        const pageLoadObject = {
            acc_id,
            device: getDevice(),
            lauchedFrom: launchedFrom(),
            androidVersion: getAndroidDistribution(),
            appVersion: getAndroidAppVersion(),
            login_status: login_status ? 'logged in' : 'logged out',
            event: 'screen_load',
            brand: getBrand(),
            jurisdiction: region?.toUpperCase(),
            orientation: deviceType,
        };

        const payload = {
            device: getDevice(),
            lauchedFrom: launchedFrom(),
            gaEventCategory: 'games',
            gaEventLabel: undefined,
            module: 'game_window',
            event: 'game_window_opened',
            gaEventAction: 'game_window_opened'
        };

        if (acc_id) {
            gtmPushToDatalayer(pageLoadObject);
            gtmPush(payload);
        }
    }, [acc_id, country, deviceType, gtmPush, locale, login_status, region]);
};

export default useGtm;
