import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';
import { IRmWebRedirectContext } from './types';

export const ddActionRmWebRedirectPageOther = (
    { ...context }: IRmWebRedirectContext,
    use_case: IddUseCases
): IddRumAction => ({
    name: 'RM_WEB_REDIRECT_PAGE_OTHER',
    description: 'RM redirect: unhandled scenario',
    context,
    use_case
});
