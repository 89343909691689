import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IGetUserDetailsReceived {
    userId: number;
    sessionId: string;
}

export const ddActionGetUserDetailsReceived = (
    { ...context }: IGetUserDetailsReceived,
    use_case: IddUseCases
): IddRumAction => ({
    name: '[GET_USER_DETAILS]: SUCCESS',
    description: 'User details received',
    context,
    use_case
});
